const colors = [
  '#FF6F61',
  '#3498DB',
  '#E74C3C',
  '#2ECC71',
  '#F39C12',
  '#9B59B6',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
  '#9B59B6',
  '#E74C3C',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
  '#9B59B6',
  '#E74C3C',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
  '#9B59B6',
  '#E74C3C',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
  '#9B59B6',
  '#E74C3C',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
  '#9B59B6',
  '#E74C3C',
  '#1ABC9C',
  '#E67E22',
  '#34495E',
  '#27AE60',
  '#F1C40F',
  '#8E44AD',
  '#16A085',
  '#D35400',
  '#95A5A6',
  '#C0392B',
  '#2C3E50',
  '#2ECC71',
  '#3498DB',
  '#F39C12',
];

export const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};
