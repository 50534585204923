import {
  Ri24HoursFill,
  Ri24HoursLine,
  Ri4kFill,
  Ri4kLine,
  RiAB,
  RiAccountBoxFill,
  RiAccountBoxLine,
  RiAccountCircleFill,
  RiAccountCircleLine,
  RiAccountPinBoxFill,
  RiAccountPinBoxLine,
  RiAccountPinCircleFill,
  RiAccountPinCircleLine,
  RiAddBoxFill,
  RiAddBoxLine,
  RiAddCircleFill,
  RiAddCircleLine,
  RiAddFill,
  RiAddLine,
  RiAdminFill,
  RiAdminLine,
  RiAdvertisementFill,
  RiAdvertisementLine,
  RiAirplayFill,
  RiAirplayLine,
  RiAlarmFill,
  RiAlarmLine,
  RiAlarmWarningFill,
  RiAlarmWarningLine,
  RiAlbumFill,
  RiAlbumLine,
  RiAlertFill,
  RiAlertLine,
  RiAliensFill,
  RiAliensLine,
  RiAlignBottom,
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiAlignTop,
  RiAlignVertically,
  RiAlipayFill,
  RiAlipayLine,
  RiAmazonFill,
  RiAmazonLine,
  RiAnchorFill,
  RiAnchorLine,
  RiAncientGateFill,
  RiAncientGateLine,
  RiAncientPavilionFill,
  RiAncientPavilionLine,
  RiAndroidFill,
  RiAndroidLine,
  RiAngularjsFill,
  RiAngularjsLine,
  RiAnticlockwise2Fill,
  RiAnticlockwise2Line,
  RiAnticlockwiseFill,
  RiAnticlockwiseLine,
  RiAppStoreFill,
  RiAppStoreLine,
  RiAppleFill,
  RiAppleLine,
  RiApps2Fill,
  RiApps2Line,
  RiAppsFill,
  RiAppsLine,
  RiArchiveDrawerFill,
  RiArchiveDrawerLine,
  RiArchiveFill,
  RiArchiveLine,
  RiArrowDownCircleFill,
  RiArrowDownCircleLine,
  RiArrowDownFill,
  RiArrowDownLine,
  RiArrowDownSFill,
  RiArrowDownSLine,
  RiArrowDropDownFill,
  RiArrowDropDownLine,
  RiArrowDropLeftFill,
  RiArrowDropLeftLine,
  RiArrowDropRightFill,
  RiArrowDropRightLine,
  RiArrowDropUpFill,
  RiArrowDropUpLine,
  RiArrowGoBackFill,
  RiArrowGoBackLine,
  RiArrowGoForwardFill,
  RiArrowGoForwardLine,
  RiArrowLeftCircleFill,
  RiArrowLeftCircleLine,
  RiArrowLeftDownFill,
  RiArrowLeftDownLine,
  RiArrowLeftFill,
  RiArrowLeftLine,
  RiArrowLeftRightFill,
  RiArrowLeftRightLine,
  RiArrowLeftSFill,
  RiArrowLeftSLine,
  RiArrowLeftUpFill,
  RiArrowLeftUpLine,
  RiArrowRightCircleFill,
  RiArrowRightCircleLine,
  RiArrowRightDownFill,
  RiArrowRightDownLine,
  RiArrowRightFill,
  RiArrowRightLine,
  RiArrowRightSFill,
  RiArrowRightSLine,
  RiArrowRightUpFill,
  RiArrowRightUpLine,
  RiArrowUpCircleFill,
  RiArrowUpCircleLine,
  RiArrowUpDownFill,
  RiArrowUpDownLine,
  RiArrowUpFill,
  RiArrowUpLine,
  RiArrowUpSFill,
  RiArrowUpSLine,
  RiArtboard2Fill,
  RiArtboard2Line,
  RiArtboardFill,
  RiArtboardLine,
  RiArticleFill,
  RiArticleLine,
  RiAspectRatioFill,
  RiAspectRatioLine,
  RiAsterisk,
  RiAtFill,
  RiAtLine,
  RiAttachment2,
  RiAttachmentFill,
  RiAttachmentLine,
  RiAuctionFill,
  RiAuctionLine,
  RiAwardFill,
  RiAwardLine,
  RiBaiduFill,
  RiBaiduLine,
  RiBallPenFill,
  RiBallPenLine,
  RiBankCard2Fill,
  RiBankCard2Line,
  RiBankCardFill,
  RiBankCardLine,
  RiBankFill,
  RiBankLine,
  RiBarChart2Fill,
  RiBarChart2Line,
  RiBarChartBoxFill,
  RiBarChartBoxLine,
  RiBarChartFill,
  RiBarChartGroupedFill,
  RiBarChartGroupedLine,
  RiBarChartHorizontalFill,
  RiBarChartHorizontalLine,
  RiBarChartLine,
  RiBarcodeBoxFill,
  RiBarcodeBoxLine,
  RiBarcodeFill,
  RiBarcodeLine,
  RiBarricadeFill,
  RiBarricadeLine,
  RiBaseStationFill,
  RiBaseStationLine,
  RiBasketballFill,
  RiBasketballLine,
  RiBattery2ChargeFill,
  RiBattery2ChargeLine,
  RiBattery2Fill,
  RiBattery2Line,
  RiBatteryChargeFill,
  RiBatteryChargeLine,
  RiBatteryFill,
  RiBatteryLine,
  RiBatteryLowFill,
  RiBatteryLowLine,
  RiBatterySaverFill,
  RiBatterySaverLine,
  RiBatteryShareFill,
  RiBatteryShareLine,
  RiBearSmileFill,
  RiBearSmileLine,
  RiBehanceFill,
  RiBehanceLine,
  RiBellFill,
  RiBellLine,
  RiBikeFill,
  RiBikeLine,
  RiBilibiliFill,
  RiBilibiliLine,
  RiBillFill,
  RiBillLine,
  RiBilliardsFill,
  RiBilliardsLine,
  RiBitCoinFill,
  RiBitCoinLine,
  RiBlazeFill,
  RiBlazeLine,
  RiBluetoothConnectFill,
  RiBluetoothConnectLine,
  RiBluetoothFill,
  RiBluetoothLine,
  RiBlurOffFill,
  RiBlurOffLine,
  RiBodyScanFill,
  RiBodyScanLine,
  RiBold,
  RiBook2Fill,
  RiBook2Line,
  RiBook3Fill,
  RiBook3Line,
  RiBookFill,
  RiBookLine,
  RiBookOpenFill,
  RiBookOpenLine,
  RiBookReadFill,
  RiBookReadLine,
  RiBookletFill,
  RiBookletLine,
  RiBookmark2Fill,
  RiBookmark2Line,
  RiBookmark3Fill,
  RiBookmark3Line,
  RiBookmarkFill,
  RiBookmarkLine,
  RiBoxingFill,
  RiBoxingLine,
  RiBracesFill,
  RiBracesLine,
  RiBracketsFill,
  RiBracketsLine,
  RiBriefcase2Fill,
  RiBriefcase2Line,
  RiBriefcase3Fill,
  RiBriefcase3Line,
  RiBriefcase4Fill,
  RiBriefcase4Line,
  RiBriefcase5Fill,
  RiBriefcase5Line,
  RiBriefcaseFill,
  RiBriefcaseLine,
  RiBringForward,
  RiBringToFront,
  RiBroadcastFill,
  RiBroadcastLine,
  RiBrush2Fill,
  RiBrush2Line,
  RiBrush3Fill,
  RiBrush3Line,
  RiBrush4Fill,
  RiBrush4Line,
  RiBrushFill,
  RiBrushLine,
  RiBubbleChartFill,
  RiBubbleChartLine,
  RiBug2Fill,
  RiBug2Line,
  RiBugFill,
  RiBugLine,
  RiBuilding2Fill,
  RiBuilding2Line,
  RiBuilding3Fill,
  RiBuilding3Line,
  RiBuilding4Fill,
  RiBuilding4Line,
  RiBuildingFill,
  RiBuildingLine,
  RiBus2Fill,
  RiBus2Line,
  RiBusFill,
  RiBusLine,
  RiBusWifiFill,
  RiBusWifiLine,
  RiCactusFill,
  RiCactusLine,
  RiCake2Fill,
  RiCake2Line,
  RiCake3Fill,
  RiCake3Line,
  RiCakeFill,
  RiCakeLine,
  RiCalculatorFill,
  RiCalculatorLine,
  RiCalendar2Fill,
  RiCalendar2Line,
  RiCalendarCheckFill,
  RiCalendarCheckLine,
  RiCalendarEventFill,
  RiCalendarEventLine,
  RiCalendarFill,
  RiCalendarLine,
  RiCalendarTodoFill,
  RiCalendarTodoLine,
  RiCamera2Fill,
  RiCamera2Line,
  RiCamera3Fill,
  RiCamera3Line,
  RiCameraFill,
  RiCameraLensFill,
  RiCameraLensLine,
  RiCameraLine,
  RiCameraOffFill,
  RiCameraOffLine,
  RiCameraSwitchFill,
  RiCameraSwitchLine,
  RiCapsuleFill,
  RiCapsuleLine,
  RiCarFill,
  RiCarLine,
  RiCarWashingFill,
  RiCarWashingLine,
  RiCaravanFill,
  RiCaravanLine,
  RiCastFill,
  RiCastLine,
  RiCellphoneFill,
  RiCellphoneLine,
  RiCelsiusFill,
  RiCelsiusLine,
  RiCentosFill,
  RiCentosLine,
  RiCharacterRecognitionFill,
  RiCharacterRecognitionLine,
  RiChargingPile2Fill,
  RiChargingPile2Line,
  RiChargingPileFill,
  RiChargingPileLine,
  RiChat1Fill,
  RiChat1Line,
  RiChat2Fill,
  RiChat2Line,
  RiChat3Fill,
  RiChat3Line,
  RiChat4Fill,
  RiChat4Line,
  RiChatCheckFill,
  RiChatCheckLine,
  RiChatDeleteFill,
  RiChatDeleteLine,
  RiChatDownloadFill,
  RiChatDownloadLine,
  RiChatFollowUpFill,
  RiChatFollowUpLine,
  RiChatForwardFill,
  RiChatForwardLine,
  RiChatHeartFill,
  RiChatHeartLine,
  RiChatHistoryFill,
  RiChatHistoryLine,
  RiChatNewFill,
  RiChatNewLine,
  RiChatOffFill,
  RiChatOffLine,
  RiChatPollFill,
  RiChatPollLine,
  RiChatPrivateFill,
  RiChatPrivateLine,
  RiChatQuoteFill,
  RiChatQuoteLine,
  RiChatSettingsFill,
  RiChatSettingsLine,
  RiChatSmile2Fill,
  RiChatSmile2Line,
  RiChatSmile3Fill,
  RiChatSmile3Line,
  RiChatSmileFill,
  RiChatSmileLine,
  RiChatUploadFill,
  RiChatUploadLine,
  RiChatVoiceFill,
  RiChatVoiceLine,
  RiCheckDoubleFill,
  RiCheckDoubleLine,
  RiCheckFill,
  RiCheckLine,
  RiCheckboxBlankCircleFill,
  RiCheckboxBlankCircleLine,
  RiCheckboxBlankFill,
  RiCheckboxBlankLine,
  RiCheckboxCircleFill,
  RiCheckboxCircleLine,
  RiCheckboxFill,
  RiCheckboxIndeterminateFill,
  RiCheckboxIndeterminateLine,
  RiCheckboxLine,
  RiCheckboxMultipleBlankFill,
  RiCheckboxMultipleBlankLine,
  RiCheckboxMultipleFill,
  RiCheckboxMultipleLine,
  RiChinaRailwayFill,
  RiChinaRailwayLine,
  RiChromeFill,
  RiChromeLine,
  RiClapperboardFill,
  RiClapperboardLine,
  RiClipboardFill,
  RiClipboardLine,
  RiClockwise2Fill,
  RiClockwise2Line,
  RiClockwiseFill,
  RiClockwiseLine,
  RiCloseCircleFill,
  RiCloseCircleLine,
  RiCloseFill,
  RiCloseLine,
  RiClosedCaptioningFill,
  RiClosedCaptioningLine,
  RiCloudFill,
  RiCloudLine,
  RiCloudOffFill,
  RiCloudOffLine,
  RiCloudWindyFill,
  RiCloudWindyLine,
  RiCloudy2Fill,
  RiCloudy2Line,
  RiCloudyFill,
  RiCloudyLine,
  RiCodeBoxFill,
  RiCodeBoxLine,
  RiCodeFill,
  RiCodeLine,
  RiCodeSFill,
  RiCodeSLine,
  RiCodeSSlashFill,
  RiCodeSSlashLine,
  RiCodeView,
  RiCodepenFill,
  RiCodepenLine,
  RiCoinFill,
  RiCoinLine,
  RiCoinsFill,
  RiCoinsLine,
  RiCollageFill,
  RiCollageLine,
  RiCommandFill,
  RiCommandLine,
  RiCommunityFill,
  RiCommunityLine,
  RiCompass2Fill,
  RiCompass2Line,
  RiCompass3Fill,
  RiCompass3Line,
  RiCompass4Fill,
  RiCompass4Line,
  RiCompassDiscoverFill,
  RiCompassDiscoverLine,
  RiCompassFill,
  RiCompassLine,
  RiCompasses2Fill,
  RiCompasses2Line,
  RiCompassesFill,
  RiCompassesLine,
  RiComputerFill,
  RiComputerLine,
  RiContactsBook2Fill,
  RiContactsBook2Line,
  RiContactsBookFill,
  RiContactsBookLine,
  RiContactsBookUploadFill,
  RiContactsBookUploadLine,
  RiContactsFill,
  RiContactsLine,
  RiContrast2Fill,
  RiContrast2Line,
  RiContrastDrop2Fill,
  RiContrastDrop2Line,
  RiContrastDropFill,
  RiContrastDropLine,
  RiContrastFill,
  RiContrastLine,
  RiCopperCoinFill,
  RiCopperCoinLine,
  RiCopperDiamondFill,
  RiCopperDiamondLine,
  RiCopyleftFill,
  RiCopyleftLine,
  RiCopyrightFill,
  RiCopyrightLine,
  RiCoreosFill,
  RiCoreosLine,
  RiCoupon2Fill,
  RiCoupon2Line,
  RiCoupon3Fill,
  RiCoupon3Line,
  RiCoupon4Fill,
  RiCoupon4Line,
  RiCoupon5Fill,
  RiCoupon5Line,
  RiCouponFill,
  RiCouponLine,
  RiCpuFill,
  RiCpuLine,
  RiCreativeCommonsByFill,
  RiCreativeCommonsByLine,
  RiCreativeCommonsFill,
  RiCreativeCommonsLine,
  RiCreativeCommonsNcFill,
  RiCreativeCommonsNcLine,
  RiCreativeCommonsNdFill,
  RiCreativeCommonsNdLine,
  RiCreativeCommonsSaFill,
  RiCreativeCommonsSaLine,
  RiCreativeCommonsZeroFill,
  RiCreativeCommonsZeroLine,
  RiCriminalFill,
  RiCriminalLine,
  RiCrop2Fill,
  RiCrop2Line,
  RiCropFill,
  RiCropLine,
  RiCss3Fill,
  RiCss3Line,
  RiCupFill,
  RiCupLine,
  RiCurrencyFill,
  RiCurrencyLine,
  RiCursorFill,
  RiCursorLine,
  RiCustomerService2Fill,
  RiCustomerService2Line,
  RiCustomerServiceFill,
  RiCustomerServiceLine,
  RiDashboard2Fill,
  RiDashboard2Line,
  RiDashboard3Fill,
  RiDashboard3Line,
  RiDashboardFill,
  RiDashboardLine,
  RiDatabase2Fill,
  RiDatabase2Line,
  RiDatabaseFill,
  RiDatabaseLine,
  RiDeleteBack2Fill,
  RiDeleteBack2Line,
  RiDeleteBackFill,
  RiDeleteBackLine,
  RiDeleteBin2Fill,
  RiDeleteBin2Line,
  RiDeleteBin3Fill,
  RiDeleteBin3Line,
  RiDeleteBin4Fill,
  RiDeleteBin4Line,
  RiDeleteBin5Fill,
  RiDeleteBin5Line,
  RiDeleteBin6Fill,
  RiDeleteBin6Line,
  RiDeleteBin7Fill,
  RiDeleteBin7Line,
  RiDeleteBinFill,
  RiDeleteBinLine,
  RiDeleteColumn,
  RiDeleteRow,
  RiDeviceFill,
  RiDeviceLine,
  RiDeviceRecoverFill,
  RiDeviceRecoverLine,
  RiDingdingFill,
  RiDingdingLine,
  RiDirectionFill,
  RiDirectionLine,
  RiDiscFill,
  RiDiscLine,
  RiDiscordFill,
  RiDiscordLine,
  RiDiscussFill,
  RiDiscussLine,
  RiDislikeFill,
  RiDislikeLine,
  RiDisqusFill,
  RiDisqusLine,
  RiDivideFill,
  RiDivideLine,
  RiDonutChartFill,
  RiDonutChartLine,
  RiDoorClosedFill,
  RiDoorClosedLine,
  RiDoorFill,
  RiDoorLine,
  RiDoorLockBoxFill,
  RiDoorLockBoxLine,
  RiDoorLockFill,
  RiDoorLockLine,
  RiDoorOpenFill,
  RiDoorOpenLine,
  RiDossierFill,
  RiDossierLine,
  RiDoubanFill,
  RiDoubanLine,
  RiDoubleQuotesL,
  RiDoubleQuotesR,
  RiDownload2Fill,
  RiDownload2Line,
  RiDownloadCloud2Fill,
  RiDownloadCloud2Line,
  RiDownloadCloudFill,
  RiDownloadCloudLine,
  RiDownloadFill,
  RiDownloadLine,
  RiDraftFill,
  RiDraftLine,
  RiDragDropFill,
  RiDragDropLine,
  RiDragMove2Fill,
  RiDragMove2Line,
  RiDragMoveFill,
  RiDragMoveLine,
  RiDribbbleFill,
  RiDribbbleLine,
  RiDriveFill,
  RiDriveLine,
  RiDrizzleFill,
  RiDrizzleLine,
  RiDropFill,
  RiDropLine,
  RiDropboxFill,
  RiDropboxLine,
  RiDualSim1Fill,
  RiDualSim1Line,
  RiDualSim2Fill,
  RiDualSim2Line,
  RiDvFill,
  RiDvLine,
  RiDvdFill,
  RiDvdLine,
  RiEBike2Fill,
  RiEBike2Line,
  RiEBikeFill,
  RiEBikeLine,
  RiEarthFill,
  RiEarthLine,
  RiEarthquakeFill,
  RiEarthquakeLine,
  RiEdgeFill,
  RiEdgeLine,
  RiEdit2Fill,
  RiEdit2Line,
  RiEditBoxFill,
  RiEditBoxLine,
  RiEditCircleFill,
  RiEditCircleLine,
  RiEditFill,
  RiEditLine,
  RiEjectFill,
  RiEjectLine,
  RiEmotion2Fill,
  RiEmotion2Line,
  RiEmotionFill,
  RiEmotionHappyFill,
  RiEmotionHappyLine,
  RiEmotionLaughFill,
  RiEmotionLaughLine,
  RiEmotionLine,
  RiEmotionNormalFill,
  RiEmotionNormalLine,
  RiEmotionSadFill,
  RiEmotionSadLine,
  RiEmotionUnhappyFill,
  RiEmotionUnhappyLine,
  RiEmpathizeFill,
  RiEmpathizeLine,
  RiEmphasisCn,
  RiEmphasis,
  RiEnglishInput,
  RiEqualizerFill,
  RiEqualizerLine,
  RiEraserFill,
  RiEraserLine,
  RiErrorWarningFill,
  RiErrorWarningLine,
  RiEvernoteFill,
  RiEvernoteLine,
  RiExchangeBoxFill,
  RiExchangeBoxLine,
  RiExchangeCnyFill,
  RiExchangeCnyLine,
  RiExchangeDollarFill,
  RiExchangeDollarLine,
  RiExchangeFill,
  RiExchangeFundsFill,
  RiExchangeFundsLine,
  RiExchangeLine,
  RiExternalLinkFill,
  RiExternalLinkLine,
  RiEye2Fill,
  RiEye2Line,
  RiEyeCloseFill,
  RiEyeCloseLine,
  RiEyeFill,
  RiEyeLine,
  RiEyeOffFill,
  RiEyeOffLine,
  RiFacebookBoxFill,
  RiFacebookBoxLine,
  RiFacebookCircleFill,
  RiFacebookCircleLine,
  RiFacebookFill,
  RiFacebookLine,
  RiFahrenheitFill,
  RiFahrenheitLine,
  RiFeedbackFill,
  RiFeedbackLine,
  RiFile2Fill,
  RiFile2Line,
  RiFile3Fill,
  RiFile3Line,
  RiFile4Fill,
  RiFile4Line,
  RiFileAddFill,
  RiFileAddLine,
  RiFileChart2Fill,
  RiFileChart2Line,
  RiFileChartFill,
  RiFileChartLine,
  RiFileCloudFill,
  RiFileCloudLine,
  RiFileCodeFill,
  RiFileCodeLine,
  RiFileCopy2Fill,
  RiFileCopy2Line,
  RiFileCopyFill,
  RiFileCopyLine,
  RiFileDamageFill,
  RiFileDamageLine,
  RiFileDownloadFill,
  RiFileDownloadLine,
  RiFileEditFill,
  RiFileEditLine,
  RiFileExcel2Fill,
  RiFileExcel2Line,
  RiFileExcelFill,
  RiFileExcelLine,
  RiFileFill,
  RiFileForbidFill,
  RiFileForbidLine,
  RiFileGifFill,
  RiFileGifLine,
  RiFileHistoryFill,
  RiFileHistoryLine,
  RiFileHwpFill,
  RiFileHwpLine,
  RiFileInfoFill,
  RiFileInfoLine,
  RiFileLine,
  RiFileList2Fill,
  RiFileList2Line,
  RiFileList3Fill,
  RiFileList3Line,
  RiFileListFill,
  RiFileListLine,
  RiFileLockFill,
  RiFileLockLine,
  RiFileMarkedFill,
  RiFileMarkedLine,
  RiFileMusicFill,
  RiFileMusicLine,
  RiFilePaper2Fill,
  RiFilePaper2Line,
  RiFilePaperFill,
  RiFilePaperLine,
  RiFilePdfFill,
  RiFilePdfLine,
  RiFilePpt2Fill,
  RiFilePpt2Line,
  RiFilePptFill,
  RiFilePptLine,
  RiFileReduceFill,
  RiFileReduceLine,
  RiFileSearchFill,
  RiFileSearchLine,
  RiFileSettingsFill,
  RiFileSettingsLine,
  RiFileShield2Fill,
  RiFileShield2Line,
  RiFileShieldFill,
  RiFileShieldLine,
  RiFileShredFill,
  RiFileShredLine,
  RiFileTextFill,
  RiFileTextLine,
  RiFileTransferFill,
  RiFileTransferLine,
  RiFileUnknowFill,
  RiFileUnknowLine,
  RiFileUploadFill,
  RiFileUploadLine,
  RiFileUserFill,
  RiFileUserLine,
  RiFileWarningFill,
  RiFileWarningLine,
  RiFileWord2Fill,
  RiFileWord2Line,
  RiFileWordFill,
  RiFileWordLine,
  RiFileZipFill,
  RiFileZipLine,
  RiFilmFill,
  RiFilmLine,
  RiFilter2Fill,
  RiFilter2Line,
  RiFilter3Fill,
  RiFilter3Line,
  RiFilterFill,
  RiFilterLine,
  RiFilterOffFill,
  RiFilterOffLine,
  RiFindReplaceFill,
  RiFindReplaceLine,
  RiFinderFill,
  RiFinderLine,
  RiFingerprint2Fill,
  RiFingerprint2Line,
  RiFingerprintFill,
  RiFingerprintLine,
  RiFireFill,
  RiFireLine,
  RiFirefoxFill,
  RiFirefoxLine,
  RiFirstAidKitFill,
  RiFirstAidKitLine,
  RiFlag2Fill,
  RiFlag2Line,
  RiFlagFill,
  RiFlagLine,
  RiFlashlightFill,
  RiFlashlightLine,
  RiFlaskFill,
  RiFlaskLine,
  RiFlightLandFill,
  RiFlightLandLine,
  RiFlightTakeoffFill,
  RiFlightTakeoffLine,
  RiFloodFill,
  RiFloodLine,
  RiFlowChart,
  RiFlutterFill,
  RiFlutterLine,
  RiFocus2Fill,
  RiFocus2Line,
  RiFocus3Fill,
  RiFocus3Line,
  RiFocusFill,
  RiFocusLine,
  RiFoggyFill,
  RiFoggyLine,
  RiFolder2Fill,
  RiFolder2Line,
  RiFolder3Fill,
  RiFolder3Line,
  RiFolder4Fill,
  RiFolder4Line,
  RiFolder5Fill,
  RiFolder5Line,
  RiFolderAddFill,
  RiFolderAddLine,
  RiFolderChart2Fill,
  RiFolderChart2Line,
  RiFolderChartFill,
  RiFolderChartLine,
  RiFolderDownloadFill,
  RiFolderDownloadLine,
  RiFolderFill,
  RiFolderForbidFill,
  RiFolderForbidLine,
  RiFolderHistoryFill,
  RiFolderHistoryLine,
  RiFolderInfoFill,
  RiFolderInfoLine,
  RiFolderKeyholeFill,
  RiFolderKeyholeLine,
  RiFolderLine,
  RiFolderLockFill,
  RiFolderLockLine,
  RiFolderMusicFill,
  RiFolderMusicLine,
  RiFolderOpenFill,
  RiFolderOpenLine,
  RiFolderReceivedFill,
  RiFolderReceivedLine,
  RiFolderReduceFill,
  RiFolderReduceLine,
  RiFolderSettingsFill,
  RiFolderSettingsLine,
  RiFolderSharedFill,
  RiFolderSharedLine,
  RiFolderShield2Fill,
  RiFolderShield2Line,
  RiFolderShieldFill,
  RiFolderShieldLine,
  RiFolderTransferFill,
  RiFolderTransferLine,
  RiFolderUnknowFill,
  RiFolderUnknowLine,
  RiFolderUploadFill,
  RiFolderUploadLine,
  RiFolderUserFill,
  RiFolderUserLine,
  RiFolderWarningFill,
  RiFolderWarningLine,
  RiFolderZipFill,
  RiFolderZipLine,
  RiFoldersFill,
  RiFoldersLine,
  RiFontColor,
  RiFontSize2,
  RiFontSize,
  RiFootballFill,
  RiFootballLine,
  RiFootprintFill,
  RiFootprintLine,
  RiForbid2Fill,
  RiForbid2Line,
  RiForbidFill,
  RiForbidLine,
  RiFormatClear,
  RiFridgeFill,
  RiFridgeLine,
  RiFullscreenExitFill,
  RiFullscreenExitLine,
  RiFullscreenFill,
  RiFullscreenLine,
  RiFunctionFill,
  RiFunctionLine,
  RiFunctions,
  RiFundsBoxFill,
  RiFundsBoxLine,
  RiFundsFill,
  RiFundsLine,
  RiGalleryFill,
  RiGalleryLine,
  RiGalleryUploadFill,
  RiGalleryUploadLine,
  RiGameFill,
  RiGameLine,
  RiGamepadFill,
  RiGamepadLine,
  RiGasStationFill,
  RiGasStationLine,
  RiGatsbyFill,
  RiGatsbyLine,
  RiGenderlessFill,
  RiGenderlessLine,
  RiGhost2Fill,
  RiGhost2Line,
  RiGhostFill,
  RiGhostLine,
  RiGhostSmileFill,
  RiGhostSmileLine,
  RiGift2Fill,
  RiGift2Line,
  RiGiftFill,
  RiGiftLine,
  RiGitBranchFill,
  RiGitBranchLine,
  RiGitCommitFill,
  RiGitCommitLine,
  RiGitMergeFill,
  RiGitMergeLine,
  RiGitPullRequestFill,
  RiGitPullRequestLine,
  RiGitRepositoryCommitsFill,
  RiGitRepositoryCommitsLine,
  RiGitRepositoryFill,
  RiGitRepositoryLine,
  RiGitRepositoryPrivateFill,
  RiGitRepositoryPrivateLine,
  RiGithubFill,
  RiGithubLine,
  RiGitlabFill,
  RiGitlabLine,
  RiGlobalFill,
  RiGlobalLine,
  RiGlobeFill,
  RiGlobeLine,
  RiGobletFill,
  RiGobletLine,
  RiGoogleFill,
  RiGoogleLine,
  RiGooglePlayFill,
  RiGooglePlayLine,
  RiGovernmentFill,
  RiGovernmentLine,
  RiGpsFill,
  RiGpsLine,
  RiGradienterFill,
  RiGradienterLine,
  RiGridFill,
  RiGridLine,
  RiGroup2Fill,
  RiGroup2Line,
  RiGroupFill,
  RiGroupLine,
  RiGuideFill,
  RiGuideLine,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiHailFill,
  RiHailLine,
  RiHammerFill,
  RiHammerLine,
  RiHandCoinFill,
  RiHandCoinLine,
  RiHandHeartFill,
  RiHandHeartLine,
  RiHandSanitizerFill,
  RiHandSanitizerLine,
  RiHandbagFill,
  RiHandbagLine,
  RiHardDrive2Fill,
  RiHardDrive2Line,
  RiHardDriveFill,
  RiHardDriveLine,
  RiHashtag,
  RiHaze2Fill,
  RiHaze2Line,
  RiHazeFill,
  RiHazeLine,
  RiHdFill,
  RiHdLine,
  RiHeading,
  RiHeadphoneFill,
  RiHeadphoneLine,
  RiHealthBookFill,
  RiHealthBookLine,
  RiHeart2Fill,
  RiHeart2Line,
  RiHeart3Fill,
  RiHeart3Line,
  RiHeartAddFill,
  RiHeartAddLine,
  RiHeartFill,
  RiHeartLine,
  RiHeartPulseFill,
  RiHeartPulseLine,
  RiHeartsFill,
  RiHeartsLine,
  RiHeavyShowersFill,
  RiHeavyShowersLine,
  RiHistoryFill,
  RiHistoryLine,
  RiHome2Fill,
  RiHome2Line,
  RiHome3Fill,
  RiHome3Line,
  RiHome4Fill,
  RiHome4Line,
  RiHome5Fill,
  RiHome5Line,
  RiHome6Fill,
  RiHome6Line,
  RiHome7Fill,
  RiHome7Line,
  RiHome8Fill,
  RiHome8Line,
  RiHomeFill,
  RiHomeGearFill,
  RiHomeGearLine,
  RiHomeHeartFill,
  RiHomeHeartLine,
  RiHomeLine,
  RiHomeSmile2Fill,
  RiHomeSmile2Line,
  RiHomeSmileFill,
  RiHomeSmileLine,
  RiHomeWifiFill,
  RiHomeWifiLine,
  RiHonorOfKingsFill,
  RiHonorOfKingsLine,
  RiHonourFill,
  RiHonourLine,
  RiHospitalFill,
  RiHospitalLine,
  RiHotelBedFill,
  RiHotelBedLine,
  RiHotelFill,
  RiHotelLine,
  RiHotspotFill,
  RiHotspotLine,
  RiHqFill,
  RiHqLine,
  RiHtml5Fill,
  RiHtml5Line,
  RiIeFill,
  RiIeLine,
  RiImage2Fill,
  RiImage2Line,
  RiImageAddFill,
  RiImageAddLine,
  RiImageEditFill,
  RiImageEditLine,
  RiImageFill,
  RiImageLine,
  RiInboxArchiveFill,
  RiInboxArchiveLine,
  RiInboxFill,
  RiInboxLine,
  RiInboxUnarchiveFill,
  RiInboxUnarchiveLine,
  RiIncreaseDecreaseFill,
  RiIncreaseDecreaseLine,
  RiIndentDecrease,
  RiIndentIncrease,
  RiIndeterminateCircleFill,
  RiIndeterminateCircleLine,
  RiInformationFill,
  RiInformationLine,
  RiInfraredThermometerFill,
  RiInfraredThermometerLine,
  RiInkBottleFill,
  RiInkBottleLine,
  RiInputCursorMove,
  RiInputMethodFill,
  RiInputMethodLine,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiInstagramFill,
  RiInstagramLine,
  RiInstallFill,
  RiInstallLine,
  RiInvisionFill,
  RiInvisionLine,
  RiItalic,
  RiKakaoTalkFill,
  RiKakaoTalkLine,
  RiKey2Fill,
  RiKey2Line,
  RiKeyFill,
  RiKeyLine,
  RiKeyboardBoxFill,
  RiKeyboardBoxLine,
  RiKeyboardFill,
  RiKeyboardLine,
  RiKeynoteFill,
  RiKeynoteLine,
  RiKnifeBloodFill,
  RiKnifeBloodLine,
  RiKnifeFill,
  RiKnifeLine,
  RiLandscapeFill,
  RiLandscapeLine,
  RiLayout2Fill,
  RiLayout2Line,
  RiLayout3Fill,
  RiLayout3Line,
  RiLayout4Fill,
  RiLayout4Line,
  RiLayout5Fill,
  RiLayout5Line,
  RiLayout6Fill,
  RiLayout6Line,
  RiLayoutBottom2Fill,
  RiLayoutBottom2Line,
  RiLayoutBottomFill,
  RiLayoutBottomLine,
  RiLayoutColumnFill,
  RiLayoutColumnLine,
  RiLayoutFill,
  RiLayoutGridFill,
  RiLayoutGridLine,
  RiLayoutLeft2Fill,
  RiLayoutLeft2Line,
  RiLayoutLeftFill,
  RiLayoutLeftLine,
  RiLayoutLine,
  RiLayoutMasonryFill,
  RiLayoutMasonryLine,
  RiLayoutRight2Fill,
  RiLayoutRight2Line,
  RiLayoutRightFill,
  RiLayoutRightLine,
  RiLayoutRowFill,
  RiLayoutRowLine,
  RiLayoutTop2Fill,
  RiLayoutTop2Line,
  RiLayoutTopFill,
  RiLayoutTopLine,
  RiLeafFill,
  RiLeafLine,
  RiLifebuoyFill,
  RiLifebuoyLine,
  RiLightbulbFill,
  RiLightbulbFlashFill,
  RiLightbulbFlashLine,
  RiLightbulbLine,
  RiLineChartFill,
  RiLineChartLine,
  RiLineFill,
  RiLineHeight,
  RiLineLine,
  RiLinkM,
  RiLinkUnlinkM,
  RiLinkUnlink,
  RiLink,
  RiLinkedinBoxFill,
  RiLinkedinBoxLine,
  RiLinkedinFill,
  RiLinkedinLine,
  RiLinksFill,
  RiLinksLine,
  RiListCheck2,
  RiListCheck,
  RiListOrdered,
  RiListSettingsFill,
  RiListSettingsLine,
  RiListUnordered,
  RiLiveFill,
  RiLiveLine,
  RiLoader2Fill,
  RiLoader2Line,
  RiLoader3Fill,
  RiLoader3Line,
  RiLoader4Fill,
  RiLoader4Line,
  RiLoader5Fill,
  RiLoader5Line,
  RiLoaderFill,
  RiLoaderLine,
  RiLock2Fill,
  RiLock2Line,
  RiLockFill,
  RiLockLine,
  RiLockPasswordFill,
  RiLockPasswordLine,
  RiLockUnlockFill,
  RiLockUnlockLine,
  RiLoginBoxFill,
  RiLoginBoxLine,
  RiLoginCircleFill,
  RiLoginCircleLine,
  RiLogoutBoxFill,
  RiLogoutBoxLine,
  RiLogoutBoxRFill,
  RiLogoutBoxRLine,
  RiLogoutCircleFill,
  RiLogoutCircleLine,
  RiLogoutCircleRFill,
  RiLogoutCircleRLine,
  RiLuggageCartFill,
  RiLuggageCartLine,
  RiLuggageDepositFill,
  RiLuggageDepositLine,
  RiLungsFill,
  RiLungsLine,
  RiMacFill,
  RiMacLine,
  RiMacbookFill,
  RiMacbookLine,
  RiMagicFill,
  RiMagicLine,
  RiMailAddFill,
  RiMailAddLine,
  RiMailCheckFill,
  RiMailCheckLine,
  RiMailCloseFill,
  RiMailCloseLine,
  RiMailDownloadFill,
  RiMailDownloadLine,
  RiMailFill,
  RiMailForbidFill,
  RiMailForbidLine,
  RiMailLine,
  RiMailLockFill,
  RiMailLockLine,
  RiMailOpenFill,
  RiMailOpenLine,
  RiMailSendFill,
  RiMailSendLine,
  RiMailSettingsFill,
  RiMailSettingsLine,
  RiMailStarFill,
  RiMailStarLine,
  RiMailUnreadFill,
  RiMailUnreadLine,
  RiMailVolumeFill,
  RiMailVolumeLine,
  RiMap2Fill,
  RiMap2Line,
  RiMapFill,
  RiMapLine,
  RiMapPin2Fill,
  RiMapPin2Line,
  RiMapPin3Fill,
  RiMapPin3Line,
  RiMapPin4Fill,
  RiMapPin4Line,
  RiMapPin5Fill,
  RiMapPin5Line,
  RiMapPinAddFill,
  RiMapPinAddLine,
  RiMapPinFill,
  RiMapPinLine,
  RiMapPinRangeFill,
  RiMapPinRangeLine,
  RiMapPinTimeFill,
  RiMapPinTimeLine,
  RiMapPinUserFill,
  RiMapPinUserLine,
  RiMarkPenFill,
  RiMarkPenLine,
  RiMarkdownFill,
  RiMarkdownLine,
  RiMarkupFill,
  RiMarkupLine,
  RiMastercardFill,
  RiMastercardLine,
  RiMastodonFill,
  RiMastodonLine,
  RiMedal2Fill,
  RiMedal2Line,
  RiMedalFill,
  RiMedalLine,
  RiMedicineBottleFill,
  RiMedicineBottleLine,
  RiMediumFill,
  RiMediumLine,
  RiMenFill,
  RiMenLine,
  RiMentalHealthFill,
  RiMentalHealthLine,
  RiMenu2Fill,
  RiMenu2Line,
  RiMenu3Fill,
  RiMenu3Line,
  RiMenu4Fill,
  RiMenu4Line,
  RiMenu5Fill,
  RiMenu5Line,
  RiMenuAddFill,
  RiMenuAddLine,
  RiMenuFill,
  RiMenuFoldFill,
  RiMenuFoldLine,
  RiMenuLine,
  RiMenuUnfoldFill,
  RiMenuUnfoldLine,
  RiMergeCellsHorizontal,
  RiMergeCellsVertical,
  RiMessage2Fill,
  RiMessage2Line,
  RiMessage3Fill,
  RiMessage3Line,
  RiMessageFill,
  RiMessageLine,
  RiMessengerFill,
  RiMessengerLine,
  RiMeteorFill,
  RiMeteorLine,
  RiMic2Fill,
  RiMic2Line,
  RiMicFill,
  RiMicLine,
  RiMicOffFill,
  RiMicOffLine,
  RiMickeyFill,
  RiMickeyLine,
  RiMicroscopeFill,
  RiMicroscopeLine,
  RiMicrosoftFill,
  RiMicrosoftLine,
  RiMindMap,
  RiMiniProgramFill,
  RiMiniProgramLine,
  RiMistFill,
  RiMistLine,
  RiMoneyCnyBoxFill,
  RiMoneyCnyBoxLine,
  RiMoneyCnyCircleFill,
  RiMoneyCnyCircleLine,
  RiMoneyDollarBoxFill,
  RiMoneyDollarBoxLine,
  RiMoneyDollarCircleFill,
  RiMoneyDollarCircleLine,
  RiMoneyEuroBoxFill,
  RiMoneyEuroBoxLine,
  RiMoneyEuroCircleFill,
  RiMoneyEuroCircleLine,
  RiMoneyPoundBoxFill,
  RiMoneyPoundBoxLine,
  RiMoneyPoundCircleFill,
  RiMoneyPoundCircleLine,
  RiMoonClearFill,
  RiMoonClearLine,
  RiMoonCloudyFill,
  RiMoonCloudyLine,
  RiMoonFill,
  RiMoonFoggyFill,
  RiMoonFoggyLine,
  RiMoonLine,
  RiMore2Fill,
  RiMore2Line,
  RiMoreFill,
  RiMoreLine,
  RiMotorbikeFill,
  RiMotorbikeLine,
  RiMouseFill,
  RiMouseLine,
  RiMovie2Fill,
  RiMovie2Line,
  RiMovieFill,
  RiMovieLine,
  RiMusic2Fill,
  RiMusic2Line,
  RiMusicFill,
  RiMusicLine,
  RiMvFill,
  RiMvLine,
  RiNavigationFill,
  RiNavigationLine,
  RiNeteaseCloudMusicFill,
  RiNeteaseCloudMusicLine,
  RiNetflixFill,
  RiNetflixLine,
  RiNewspaperFill,
  RiNewspaperLine,
  RiNodeTree,
  RiNotification2Fill,
  RiNotification2Line,
  RiNotification3Fill,
  RiNotification3Line,
  RiNotification4Fill,
  RiNotification4Line,
  RiNotificationBadgeFill,
  RiNotificationBadgeLine,
  RiNotificationFill,
  RiNotificationLine,
  RiNotificationOffFill,
  RiNotificationOffLine,
  RiNpmjsFill,
  RiNpmjsLine,
  RiNumber0,
  RiNumber1,
  RiNumber2,
  RiNumber3,
  RiNumber4,
  RiNumber5,
  RiNumber6,
  RiNumber7,
  RiNumber8,
  RiNumber9,
  RiNumbersFill,
  RiNumbersLine,
  RiNurseFill,
  RiNurseLine,
  RiOilFill,
  RiOilLine,
  RiOmega,
  RiOpenArmFill,
  RiOpenArmLine,
  RiOpenSourceFill,
  RiOpenSourceLine,
  RiOperaFill,
  RiOperaLine,
  RiOrderPlayFill,
  RiOrderPlayLine,
  RiOrganizationChart,
  RiOutlet2Fill,
  RiOutlet2Line,
  RiOutletFill,
  RiOutletLine,
  RiPageSeparator,
  RiPagesFill,
  RiPagesLine,
  RiPaintBrushFill,
  RiPaintBrushLine,
  RiPaintFill,
  RiPaintLine,
  RiPaletteFill,
  RiPaletteLine,
  RiPantoneFill,
  RiPantoneLine,
  RiParagraph,
  RiParentFill,
  RiParentLine,
  RiParenthesesFill,
  RiParenthesesLine,
  RiParkingBoxFill,
  RiParkingBoxLine,
  RiParkingFill,
  RiParkingLine,
  RiPassportFill,
  RiPassportLine,
  RiPatreonFill,
  RiPatreonLine,
  RiPauseCircleFill,
  RiPauseCircleLine,
  RiPauseFill,
  RiPauseLine,
  RiPauseMiniFill,
  RiPauseMiniLine,
  RiPaypalFill,
  RiPaypalLine,
  RiPenNibFill,
  RiPenNibLine,
  RiPencilFill,
  RiPencilLine,
  RiPencilRuler2Fill,
  RiPencilRuler2Line,
  RiPencilRulerFill,
  RiPencilRulerLine,
  RiPercentFill,
  RiPercentLine,
  RiPhoneCameraFill,
  RiPhoneCameraLine,
  RiPhoneFill,
  RiPhoneFindFill,
  RiPhoneFindLine,
  RiPhoneLine,
  RiPhoneLockFill,
  RiPhoneLockLine,
  RiPictureInPicture2Fill,
  RiPictureInPicture2Line,
  RiPictureInPictureExitFill,
  RiPictureInPictureExitLine,
  RiPictureInPictureFill,
  RiPictureInPictureLine,
  RiPieChart2Fill,
  RiPieChart2Line,
  RiPieChartBoxFill,
  RiPieChartBoxLine,
  RiPieChartFill,
  RiPieChartLine,
  RiPinDistanceFill,
  RiPinDistanceLine,
  RiPingPongFill,
  RiPingPongLine,
  RiPinterestFill,
  RiPinterestLine,
  RiPinyinInput,
  RiPixelfedFill,
  RiPixelfedLine,
  RiPlaneFill,
  RiPlaneLine,
  RiPlantFill,
  RiPlantLine,
  RiPlayCircleFill,
  RiPlayCircleLine,
  RiPlayFill,
  RiPlayLine,
  RiPlayList2Fill,
  RiPlayList2Line,
  RiPlayListAddFill,
  RiPlayListAddLine,
  RiPlayListFill,
  RiPlayListLine,
  RiPlayMiniFill,
  RiPlayMiniLine,
  RiPlaystationFill,
  RiPlaystationLine,
  RiPlug2Fill,
  RiPlug2Line,
  RiPlugFill,
  RiPlugLine,
  RiPolaroid2Fill,
  RiPolaroid2Line,
  RiPolaroidFill,
  RiPolaroidLine,
  RiPoliceCarFill,
  RiPoliceCarLine,
  RiPriceTag2Fill,
  RiPriceTag2Line,
  RiPriceTag3Fill,
  RiPriceTag3Line,
  RiPriceTagFill,
  RiPriceTagLine,
  RiPrinterCloudFill,
  RiPrinterCloudLine,
  RiPrinterFill,
  RiPrinterLine,
  RiProductHuntFill,
  RiProductHuntLine,
  RiProfileFill,
  RiProfileLine,
  RiProjector2Fill,
  RiProjector2Line,
  RiProjectorFill,
  RiProjectorLine,
  RiPsychotherapyFill,
  RiPsychotherapyLine,
  RiPulseFill,
  RiPulseLine,
  RiPushpin2Fill,
  RiPushpin2Line,
  RiPushpinFill,
  RiPushpinLine,
  RiQqFill,
  RiQqLine,
  RiQrCodeFill,
  RiQrCodeLine,
  RiQrScan2Fill,
  RiQrScan2Line,
  RiQrScanFill,
  RiQrScanLine,
  RiQuestionAnswerFill,
  RiQuestionAnswerLine,
  RiQuestionFill,
  RiQuestionLine,
  RiQuestionMark,
  RiQuestionnaireFill,
  RiQuestionnaireLine,
  RiQuillPenFill,
  RiQuillPenLine,
  RiRadarFill,
  RiRadarLine,
  RiRadio2Fill,
  RiRadio2Line,
  RiRadioButtonFill,
  RiRadioButtonLine,
  RiRadioFill,
  RiRadioLine,
  RiRainbowFill,
  RiRainbowLine,
  RiRainyFill,
  RiRainyLine,
  RiReactjsFill,
  RiReactjsLine,
  RiRecordCircleFill,
  RiRecordCircleLine,
  RiRecordMailFill,
  RiRecordMailLine,
  RiRecycleFill,
  RiRecycleLine,
  RiRedPacketFill,
  RiRedPacketLine,
  RiRedditFill,
  RiRedditLine,
  RiRefreshFill,
  RiRefreshLine,
  RiRefund2Fill,
  RiRefund2Line,
  RiRefundFill,
  RiRefundLine,
  RiRegisteredFill,
  RiRegisteredLine,
  RiRemixiconFill,
  RiRemixiconLine,
  RiRemoteControl2Fill,
  RiRemoteControl2Line,
  RiRemoteControlFill,
  RiRemoteControlLine,
  RiRepeat2Fill,
  RiRepeat2Line,
  RiRepeatFill,
  RiRepeatLine,
  RiRepeatOneFill,
  RiRepeatOneLine,
  RiReplyAllFill,
  RiReplyAllLine,
  RiReplyFill,
  RiReplyLine,
  RiReservedFill,
  RiReservedLine,
  RiRestTimeFill,
  RiRestTimeLine,
  RiRestartFill,
  RiRestartLine,
  RiRestaurant2Fill,
  RiRestaurant2Line,
  RiRestaurantFill,
  RiRestaurantLine,
  RiRewindFill,
  RiRewindLine,
  RiRewindMiniFill,
  RiRewindMiniLine,
  RiRhythmFill,
  RiRhythmLine,
  RiRidingFill,
  RiRidingLine,
  RiRoadMapFill,
  RiRoadMapLine,
  RiRoadsterFill,
  RiRoadsterLine,
  RiRobotFill,
  RiRobotLine,
  RiRocket2Fill,
  RiRocket2Line,
  RiRocketFill,
  RiRocketLine,
  RiRotateLockFill,
  RiRotateLockLine,
  RiRoundedCorner,
  RiRouteFill,
  RiRouteLine,
  RiRouterFill,
  RiRouterLine,
  RiRssFill,
  RiRssLine,
  RiRuler2Fill,
  RiRuler2Line,
  RiRulerFill,
  RiRulerLine,
  RiRunFill,
  RiRunLine,
  RiSafariFill,
  RiSafariLine,
  RiSafe2Fill,
  RiSafe2Line,
  RiSafeFill,
  RiSafeLine,
  RiSailboatFill,
  RiSailboatLine,
  RiSave2Fill,
  RiSave2Line,
  RiSave3Fill,
  RiSave3Line,
  RiSaveFill,
  RiSaveLine,
  RiScales2Fill,
  RiScales2Line,
  RiScales3Fill,
  RiScales3Line,
  RiScalesFill,
  RiScalesLine,
  RiScan2Fill,
  RiScan2Line,
  RiScanFill,
  RiScanLine,
  RiScissors2Fill,
  RiScissors2Line,
  RiScissorsCutFill,
  RiScissorsCutLine,
  RiScissorsFill,
  RiScissorsLine,
  RiScreenshot2Fill,
  RiScreenshot2Line,
  RiScreenshotFill,
  RiScreenshotLine,
  RiSdCardFill,
  RiSdCardLine,
  RiSdCardMiniFill,
  RiSdCardMiniLine,
  RiSearch2Fill,
  RiSearch2Line,
  RiSearchEyeFill,
  RiSearchEyeLine,
  RiSearchFill,
  RiSearchLine,
  RiSecurePaymentFill,
  RiSecurePaymentLine,
  RiSeedlingFill,
  RiSeedlingLine,
  RiSendBackward,
  RiSendPlane2Fill,
  RiSendPlane2Line,
  RiSendPlaneFill,
  RiSendPlaneLine,
  RiSendToBack,
  RiSensorFill,
  RiSensorLine,
  RiSeparator,
  RiServerFill,
  RiServerLine,
  RiServiceFill,
  RiServiceLine,
  RiSettings2Fill,
  RiSettings2Line,
  RiSettings3Fill,
  RiSettings3Line,
  RiSettings4Fill,
  RiSettings4Line,
  RiSettings5Fill,
  RiSettings5Line,
  RiSettings6Fill,
  RiSettings6Line,
  RiSettingsFill,
  RiSettingsLine,
  RiShape2Fill,
  RiShape2Line,
  RiShapeFill,
  RiShapeLine,
  RiShareBoxFill,
  RiShareBoxLine,
  RiShareCircleFill,
  RiShareCircleLine,
  RiShareFill,
  RiShareForward2Fill,
  RiShareForward2Line,
  RiShareForwardBoxFill,
  RiShareForwardBoxLine,
  RiShareForwardFill,
  RiShareForwardLine,
  RiShareLine,
  RiShieldCheckFill,
  RiShieldCheckLine,
  RiShieldCrossFill,
  RiShieldCrossLine,
  RiShieldFill,
  RiShieldFlashFill,
  RiShieldFlashLine,
  RiShieldKeyholeFill,
  RiShieldKeyholeLine,
  RiShieldLine,
  RiShieldStarFill,
  RiShieldStarLine,
  RiShieldUserFill,
  RiShieldUserLine,
  RiShip2Fill,
  RiShip2Line,
  RiShipFill,
  RiShipLine,
  RiShirtFill,
  RiShirtLine,
  RiShoppingBag2Fill,
  RiShoppingBag2Line,
  RiShoppingBag3Fill,
  RiShoppingBag3Line,
  RiShoppingBagFill,
  RiShoppingBagLine,
  RiShoppingBasket2Fill,
  RiShoppingBasket2Line,
  RiShoppingBasketFill,
  RiShoppingBasketLine,
  RiShoppingCart2Fill,
  RiShoppingCart2Line,
  RiShoppingCartFill,
  RiShoppingCartLine,
  RiShowersFill,
  RiShowersLine,
  RiShuffleFill,
  RiShuffleLine,
  RiShutDownFill,
  RiShutDownLine,
  RiSideBarFill,
  RiSideBarLine,
  RiSignalTowerFill,
  RiSignalTowerLine,
  RiSignalWifi1Fill,
  RiSignalWifi1Line,
  RiSignalWifi2Fill,
  RiSignalWifi2Line,
  RiSignalWifi3Fill,
  RiSignalWifi3Line,
  RiSignalWifiErrorFill,
  RiSignalWifiErrorLine,
  RiSignalWifiFill,
  RiSignalWifiLine,
  RiSignalWifiOffFill,
  RiSignalWifiOffLine,
  RiSimCard2Fill,
  RiSimCard2Line,
  RiSimCardFill,
  RiSimCardLine,
  RiSingleQuotesL,
  RiSingleQuotesR,
  RiSipFill,
  RiSipLine,
  RiSkipBackFill,
  RiSkipBackLine,
  RiSkipBackMiniFill,
  RiSkipBackMiniLine,
  RiSkipForwardFill,
  RiSkipForwardLine,
  RiSkipForwardMiniFill,
  RiSkipForwardMiniLine,
  RiSkull2Fill,
  RiSkull2Line,
  RiSkullFill,
  RiSkullLine,
  RiSkypeFill,
  RiSkypeLine,
  RiSlackFill,
  RiSlackLine,
  RiSliceFill,
  RiSliceLine,
  RiSlideshow2Fill,
  RiSlideshow2Line,
  RiSlideshow3Fill,
  RiSlideshow3Line,
  RiSlideshow4Fill,
  RiSlideshow4Line,
  RiSlideshowFill,
  RiSlideshowLine,
  RiSmartphoneFill,
  RiSmartphoneLine,
  RiSnapchatFill,
  RiSnapchatLine,
  RiSnowyFill,
  RiSnowyLine,
  RiSortAsc,
  RiSortDesc,
  RiSoundModuleFill,
  RiSoundModuleLine,
  RiSoundcloudFill,
  RiSoundcloudLine,
  RiSpaceShipFill,
  RiSpaceShipLine,
  RiSpace,
  RiSpam2Fill,
  RiSpam2Line,
  RiSpam3Fill,
  RiSpam3Line,
  RiSpamFill,
  RiSpamLine,
  RiSpeaker2Fill,
  RiSpeaker2Line,
  RiSpeaker3Fill,
  RiSpeaker3Line,
  RiSpeakerFill,
  RiSpeakerLine,
  RiSpectrumFill,
  RiSpectrumLine,
  RiSpeedFill,
  RiSpeedLine,
  RiSpeedMiniFill,
  RiSpeedMiniLine,
  RiSplitCellsHorizontal,
  RiSplitCellsVertical,
  RiSpotifyFill,
  RiSpotifyLine,
  RiSpyFill,
  RiSpyLine,
  RiStackFill,
  RiStackLine,
  RiStackOverflowFill,
  RiStackOverflowLine,
  RiStackshareFill,
  RiStackshareLine,
  RiStarFill,
  RiStarHalfFill,
  RiStarHalfLine,
  RiStarHalfSFill,
  RiStarHalfSLine,
  RiStarLine,
  RiStarSFill,
  RiStarSLine,
  RiStarSmileFill,
  RiStarSmileLine,
  RiSteamFill,
  RiSteamLine,
  RiSteering2Fill,
  RiSteering2Line,
  RiSteeringFill,
  RiSteeringLine,
  RiStethoscopeFill,
  RiStethoscopeLine,
  RiStickyNote2Fill,
  RiStickyNote2Line,
  RiStickyNoteFill,
  RiStickyNoteLine,
  RiStockFill,
  RiStockLine,
  RiStopCircleFill,
  RiStopCircleLine,
  RiStopFill,
  RiStopLine,
  RiStopMiniFill,
  RiStopMiniLine,
  RiStore2Fill,
  RiStore2Line,
  RiStore3Fill,
  RiStore3Line,
  RiStoreFill,
  RiStoreLine,
  RiStrikethrough2,
  RiStrikethrough,
  RiSubscript2,
  RiSubscript,
  RiSubtractFill,
  RiSubtractLine,
  RiSubwayFill,
  RiSubwayLine,
  RiSubwayWifiFill,
  RiSubwayWifiLine,
  RiSuitcase2Fill,
  RiSuitcase2Line,
  RiSuitcase3Fill,
  RiSuitcase3Line,
  RiSuitcaseFill,
  RiSuitcaseLine,
  RiSunCloudyFill,
  RiSunCloudyLine,
  RiSunFill,
  RiSunFoggyFill,
  RiSunFoggyLine,
  RiSunLine,
  RiSuperscript2,
  RiSuperscript,
  RiSurgicalMaskFill,
  RiSurgicalMaskLine,
  RiSurroundSoundFill,
  RiSurroundSoundLine,
  RiSurveyFill,
  RiSurveyLine,
  RiSwapBoxFill,
  RiSwapBoxLine,
  RiSwapFill,
  RiSwapLine,
  RiSwitchFill,
  RiSwitchLine,
  RiSwordFill,
  RiSwordLine,
  RiSyringeFill,
  RiSyringeLine,
  RiTBoxFill,
  RiTBoxLine,
  RiTShirt2Fill,
  RiTShirt2Line,
  RiTShirtAirFill,
  RiTShirtAirLine,
  RiTShirtFill,
  RiTShirtLine,
  RiTable2,
  RiTableAltFill,
  RiTableAltLine,
  RiTableFill,
  RiTableLine,
  RiTabletFill,
  RiTabletLine,
  RiTakeawayFill,
  RiTakeawayLine,
  RiTaobaoFill,
  RiTaobaoLine,
  RiTapeFill,
  RiTapeLine,
  RiTaskFill,
  RiTaskLine,
  RiTaxiFill,
  RiTaxiLine,
  RiTaxiWifiFill,
  RiTaxiWifiLine,
  RiTeamFill,
  RiTeamLine,
  RiTelegramFill,
  RiTelegramLine,
  RiTempColdFill,
  RiTempColdLine,
  RiTempHotFill,
  RiTempHotLine,
  RiTerminalBoxFill,
  RiTerminalBoxLine,
  RiTerminalFill,
  RiTerminalLine,
  RiTerminalWindowFill,
  RiTerminalWindowLine,
  RiTestTubeFill,
  RiTestTubeLine,
  RiTextDirectionL,
  RiTextDirectionR,
  RiTextSpacing,
  RiTextWrap,
  RiText,
  RiThermometerFill,
  RiThermometerLine,
  RiThumbDownFill,
  RiThumbDownLine,
  RiThumbUpFill,
  RiThumbUpLine,
  RiThunderstormsFill,
  RiThunderstormsLine,
  RiTicket2Fill,
  RiTicket2Line,
  RiTicketFill,
  RiTicketLine,
  RiTimeFill,
  RiTimeLine,
  RiTimer2Fill,
  RiTimer2Line,
  RiTimerFill,
  RiTimerFlashFill,
  RiTimerFlashLine,
  RiTimerLine,
  RiTodoFill,
  RiTodoLine,
  RiToggleFill,
  RiToggleLine,
  RiToolsFill,
  RiToolsLine,
  RiTornadoFill,
  RiTornadoLine,
  RiTrademarkFill,
  RiTrademarkLine,
  RiTrafficLightFill,
  RiTrafficLightLine,
  RiTrainFill,
  RiTrainLine,
  RiTrainWifiFill,
  RiTrainWifiLine,
  RiTranslate2,
  RiTranslate,
  RiTravestiFill,
  RiTravestiLine,
  RiTreasureMapFill,
  RiTreasureMapLine,
  RiTrelloFill,
  RiTrelloLine,
  RiTrophyFill,
  RiTrophyLine,
  RiTruckFill,
  RiTruckLine,
  RiTumblrFill,
  RiTumblrLine,
  RiTv2Fill,
  RiTv2Line,
  RiTvFill,
  RiTvLine,
  RiTwitchFill,
  RiTwitchLine,
  RiTwitterFill,
  RiTwitterLine,
  RiTyphoonFill,
  RiTyphoonLine,
  RiUDiskFill,
  RiUDiskLine,
  RiUbuntuFill,
  RiUbuntuLine,
  RiUmbrellaFill,
  RiUmbrellaLine,
  RiUnderline,
  RiUninstallFill,
  RiUninstallLine,
  RiUnsplashFill,
  RiUnsplashLine,
  RiUpload2Fill,
  RiUpload2Line,
  RiUploadCloud2Fill,
  RiUploadCloud2Line,
  RiUploadCloudFill,
  RiUploadCloudLine,
  RiUploadFill,
  RiUploadLine,
  RiUsbFill,
  RiUsbLine,
  RiUser2Fill,
  RiUser2Line,
  RiUser3Fill,
  RiUser3Line,
  RiUser4Fill,
  RiUser4Line,
  RiUser5Fill,
  RiUser5Line,
  RiUser6Fill,
  RiUser6Line,
  RiUserAddFill,
  RiUserAddLine,
  RiUserFill,
  RiUserFollowFill,
  RiUserFollowLine,
  RiUserHeartFill,
  RiUserHeartLine,
  RiUserLine,
  RiUserLocationFill,
  RiUserLocationLine,
  RiUserReceived2Fill,
  RiUserReceived2Line,
  RiUserReceivedFill,
  RiUserReceivedLine,
  RiUserSearchFill,
  RiUserSearchLine,
  RiUserSettingsFill,
  RiUserSettingsLine,
  RiUserShared2Fill,
  RiUserShared2Line,
  RiUserSharedFill,
  RiUserSharedLine,
  RiUserSmileFill,
  RiUserSmileLine,
  RiUserStarFill,
  RiUserStarLine,
  RiUserUnfollowFill,
  RiUserUnfollowLine,
  RiUserVoiceFill,
  RiUserVoiceLine,
  RiVideoAddFill,
  RiVideoAddLine,
  RiVideoChatFill,
  RiVideoChatLine,
  RiVideoDownloadFill,
  RiVideoDownloadLine,
  RiVideoFill,
  RiVideoLine,
  RiVideoUploadFill,
  RiVideoUploadLine,
  RiVidicon2Fill,
  RiVidicon2Line,
  RiVidiconFill,
  RiVidiconLine,
  RiVimeoFill,
  RiVimeoLine,
  RiVipCrown2Fill,
  RiVipCrown2Line,
  RiVipCrownFill,
  RiVipCrownLine,
  RiVipDiamondFill,
  RiVipDiamondLine,
  RiVipFill,
  RiVipLine,
  RiVirusFill,
  RiVirusLine,
  RiVisaFill,
  RiVisaLine,
  RiVoiceRecognitionFill,
  RiVoiceRecognitionLine,
  RiVoiceprintFill,
  RiVoiceprintLine,
  RiVolumeDownFill,
  RiVolumeDownLine,
  RiVolumeMuteFill,
  RiVolumeMuteLine,
  RiVolumeOffVibrateFill,
  RiVolumeOffVibrateLine,
  RiVolumeUpFill,
  RiVolumeUpLine,
  RiVolumeVibrateFill,
  RiVolumeVibrateLine,
  RiVuejsFill,
  RiVuejsLine,
  RiWalkFill,
  RiWalkLine,
  RiWallet2Fill,
  RiWallet2Line,
  RiWallet3Fill,
  RiWallet3Line,
  RiWalletFill,
  RiWalletLine,
  RiWaterFlashFill,
  RiWaterFlashLine,
  RiWebcamFill,
  RiWebcamLine,
  RiWechat2Fill,
  RiWechat2Line,
  RiWechatFill,
  RiWechatLine,
  RiWechatPayFill,
  RiWechatPayLine,
  RiWeiboFill,
  RiWeiboLine,
  RiWhatsappFill,
  RiWhatsappLine,
  RiWheelchairFill,
  RiWheelchairLine,
  RiWifiFill,
  RiWifiLine,
  RiWifiOffFill,
  RiWifiOffLine,
  RiWindow2Fill,
  RiWindow2Line,
  RiWindowFill,
  RiWindowLine,
  RiWindowsFill,
  RiWindowsLine,
  RiWindyFill,
  RiWindyLine,
  RiWirelessChargingFill,
  RiWirelessChargingLine,
  RiWomenFill,
  RiWomenLine,
  RiWubiInput,
  RiXboxFill,
  RiXboxLine,
  RiXingFill,
  RiXingLine,
  RiYoutubeFill,
  RiYoutubeLine,
  RiZcoolFill,
  RiZcoolLine,
  RiZhihuFill,
  RiZhihuLine,
  RiZoomInFill,
  RiZoomInLine,
  RiZoomOutFill,
  RiZoomOutLine,
  RiZzzFill,
  RiZzzLine,
} from '@remixicon/react';

export const RemixIconMap = {
  'ri-24-hours-fill': Ri24HoursFill,
  'ri-24-hours-line': Ri24HoursLine,
  'ri-4k-fill': Ri4kFill,
  'ri-4k-line': Ri4kLine,
  'ri-a-b': RiAB,
  'ri-account-box-fill': RiAccountBoxFill,
  'ri-account-box-line': RiAccountBoxLine,
  'ri-account-circle-fill': RiAccountCircleFill,
  'ri-account-circle-line': RiAccountCircleLine,
  'ri-account-pin-box-fill': RiAccountPinBoxFill,
  'ri-account-pin-box-line': RiAccountPinBoxLine,
  'ri-account-pin-circle-fill': RiAccountPinCircleFill,
  'ri-account-pin-circle-line': RiAccountPinCircleLine,
  'ri-add-box-fill': RiAddBoxFill,
  'ri-add-box-line': RiAddBoxLine,
  'ri-add-circle-fill': RiAddCircleFill,
  'ri-add-circle-line': RiAddCircleLine,
  'ri-add-fill': RiAddFill,
  'ri-add-line': RiAddLine,
  'ri-admin-fill': RiAdminFill,
  'ri-admin-line': RiAdminLine,
  'ri-advertisement-fill': RiAdvertisementFill,
  'ri-advertisement-line': RiAdvertisementLine,
  'ri-airplay-fill': RiAirplayFill,
  'ri-airplay-line': RiAirplayLine,
  'ri-alarm-fill': RiAlarmFill,
  'ri-alarm-line': RiAlarmLine,
  'ri-alarm-warning-fill': RiAlarmWarningFill,
  'ri-alarm-warning-line': RiAlarmWarningLine,
  'ri-album-fill': RiAlbumFill,
  'ri-album-line': RiAlbumLine,
  'ri-alert-fill': RiAlertFill,
  'ri-alert-line': RiAlertLine,
  'ri-aliens-fill': RiAliensFill,
  'ri-aliens-line': RiAliensLine,
  'ri-align-bottom': RiAlignBottom,
  'ri-align-center': RiAlignCenter,
  'ri-align-justify': RiAlignJustify,
  'ri-align-left': RiAlignLeft,
  'ri-align-right': RiAlignRight,
  'ri-align-top': RiAlignTop,
  'ri-align-vertically': RiAlignVertically,
  'ri-alipay-fill': RiAlipayFill,
  'ri-alipay-line': RiAlipayLine,
  'ri-amazon-fill': RiAmazonFill,
  'ri-amazon-line': RiAmazonLine,
  'ri-anchor-fill': RiAnchorFill,
  'ri-anchor-line': RiAnchorLine,
  'ri-ancient-gate-fill': RiAncientGateFill,
  'ri-ancient-gate-line': RiAncientGateLine,
  'ri-ancient-pavilion-fill': RiAncientPavilionFill,
  'ri-ancient-pavilion-line': RiAncientPavilionLine,
  'ri-android-fill': RiAndroidFill,
  'ri-android-line': RiAndroidLine,
  'ri-angularjs-fill': RiAngularjsFill,
  'ri-angularjs-line': RiAngularjsLine,
  'ri-anticlockwise-2-fill': RiAnticlockwise2Fill,
  'ri-anticlockwise-2-line': RiAnticlockwise2Line,
  'ri-anticlockwise-fill': RiAnticlockwiseFill,
  'ri-anticlockwise-line': RiAnticlockwiseLine,
  'ri-app-store-fill': RiAppStoreFill,
  'ri-app-store-line': RiAppStoreLine,
  'ri-apple-fill': RiAppleFill,
  'ri-apple-line': RiAppleLine,
  'ri-apps-2-fill': RiApps2Fill,
  'ri-apps-2-line': RiApps2Line,
  'ri-apps-fill': RiAppsFill,
  'ri-apps-line': RiAppsLine,
  'ri-archive-drawer-fill': RiArchiveDrawerFill,
  'ri-archive-drawer-line': RiArchiveDrawerLine,
  'ri-archive-fill': RiArchiveFill,
  'ri-archive-line': RiArchiveLine,
  'ri-arrow-down-circle-fill': RiArrowDownCircleFill,
  'ri-arrow-down-circle-line': RiArrowDownCircleLine,
  'ri-arrow-down-fill': RiArrowDownFill,
  'ri-arrow-down-line': RiArrowDownLine,
  'ri-arrow-down-s-fill': RiArrowDownSFill,
  'ri-arrow-down-s-line': RiArrowDownSLine,
  'ri-arrow-drop-down-fill': RiArrowDropDownFill,
  'ri-arrow-drop-down-line': RiArrowDropDownLine,
  'ri-arrow-drop-left-fill': RiArrowDropLeftFill,
  'ri-arrow-drop-left-line': RiArrowDropLeftLine,
  'ri-arrow-drop-right-fill': RiArrowDropRightFill,
  'ri-arrow-drop-right-line': RiArrowDropRightLine,
  'ri-arrow-drop-up-fill': RiArrowDropUpFill,
  'ri-arrow-drop-up-line': RiArrowDropUpLine,
  'ri-arrow-go-back-fill': RiArrowGoBackFill,
  'ri-arrow-go-back-line': RiArrowGoBackLine,
  'ri-arrow-go-forward-fill': RiArrowGoForwardFill,
  'ri-arrow-go-forward-line': RiArrowGoForwardLine,
  'ri-arrow-left-circle-fill': RiArrowLeftCircleFill,
  'ri-arrow-left-circle-line': RiArrowLeftCircleLine,
  'ri-arrow-left-down-fill': RiArrowLeftDownFill,
  'ri-arrow-left-down-line': RiArrowLeftDownLine,
  'ri-arrow-left-fill': RiArrowLeftFill,
  'ri-arrow-left-line': RiArrowLeftLine,
  'ri-arrow-left-right-fill': RiArrowLeftRightFill,
  'ri-arrow-left-right-line': RiArrowLeftRightLine,
  'ri-arrow-left-s-fill': RiArrowLeftSFill,
  'ri-arrow-left-s-line': RiArrowLeftSLine,
  'ri-arrow-left-up-fill': RiArrowLeftUpFill,
  'ri-arrow-left-up-line': RiArrowLeftUpLine,
  'ri-arrow-right-circle-fill': RiArrowRightCircleFill,
  'ri-arrow-right-circle-line': RiArrowRightCircleLine,
  'ri-arrow-right-down-fill': RiArrowRightDownFill,
  'ri-arrow-right-down-line': RiArrowRightDownLine,
  'ri-arrow-right-fill': RiArrowRightFill,
  'ri-arrow-right-line': RiArrowRightLine,
  'ri-arrow-right-s-fill': RiArrowRightSFill,
  'ri-arrow-right-s-line': RiArrowRightSLine,
  'ri-arrow-right-up-fill': RiArrowRightUpFill,
  'ri-arrow-right-up-line': RiArrowRightUpLine,
  'ri-arrow-up-circle-fill': RiArrowUpCircleFill,
  'ri-arrow-up-circle-line': RiArrowUpCircleLine,
  'ri-arrow-up-down-fill': RiArrowUpDownFill,
  'ri-arrow-up-down-line': RiArrowUpDownLine,
  'ri-arrow-up-fill': RiArrowUpFill,
  'ri-arrow-up-line': RiArrowUpLine,
  'ri-arrow-up-s-fill': RiArrowUpSFill,
  'ri-arrow-up-s-line': RiArrowUpSLine,
  'ri-artboard-2-fill': RiArtboard2Fill,
  'ri-artboard-2-line': RiArtboard2Line,
  'ri-artboard-fill': RiArtboardFill,
  'ri-artboard-line': RiArtboardLine,
  'ri-article-fill': RiArticleFill,
  'ri-article-line': RiArticleLine,
  'ri-aspect-ratio-fill': RiAspectRatioFill,
  'ri-aspect-ratio-line': RiAspectRatioLine,
  'ri-asterisk': RiAsterisk,
  'ri-at-fill': RiAtFill,
  'ri-at-line': RiAtLine,
  'ri-attachment-2': RiAttachment2,
  'ri-attachment-fill': RiAttachmentFill,
  'ri-attachment-line': RiAttachmentLine,
  'ri-auction-fill': RiAuctionFill,
  'ri-auction-line': RiAuctionLine,
  'ri-award-fill': RiAwardFill,
  'ri-award-line': RiAwardLine,
  'ri-baidu-fill': RiBaiduFill,
  'ri-baidu-line': RiBaiduLine,
  'ri-ball-pen-fill': RiBallPenFill,
  'ri-ball-pen-line': RiBallPenLine,
  'ri-bank-card-2-fill': RiBankCard2Fill,
  'ri-bank-card-2-line': RiBankCard2Line,
  'ri-bank-card-fill': RiBankCardFill,
  'ri-bank-card-line': RiBankCardLine,
  'ri-bank-fill': RiBankFill,
  'ri-bank-line': RiBankLine,
  'ri-bar-chart-2-fill': RiBarChart2Fill,
  'ri-bar-chart-2-line': RiBarChart2Line,
  'ri-bar-chart-box-fill': RiBarChartBoxFill,
  'ri-bar-chart-box-line': RiBarChartBoxLine,
  'ri-bar-chart-fill': RiBarChartFill,
  'ri-bar-chart-grouped-fill': RiBarChartGroupedFill,
  'ri-bar-chart-grouped-line': RiBarChartGroupedLine,
  'ri-bar-chart-horizontal-fill': RiBarChartHorizontalFill,
  'ri-bar-chart-horizontal-line': RiBarChartHorizontalLine,
  'ri-bar-chart-line': RiBarChartLine,
  'ri-barcode-box-fill': RiBarcodeBoxFill,
  'ri-barcode-box-line': RiBarcodeBoxLine,
  'ri-barcode-fill': RiBarcodeFill,
  'ri-barcode-line': RiBarcodeLine,
  'ri-barricade-fill': RiBarricadeFill,
  'ri-barricade-line': RiBarricadeLine,
  'ri-base-station-fill': RiBaseStationFill,
  'ri-base-station-line': RiBaseStationLine,
  'ri-basketball-fill': RiBasketballFill,
  'ri-basketball-line': RiBasketballLine,
  'ri-battery-2-charge-fill': RiBattery2ChargeFill,
  'ri-battery-2-charge-line': RiBattery2ChargeLine,
  'ri-battery-2-fill': RiBattery2Fill,
  'ri-battery-2-line': RiBattery2Line,
  'ri-battery-charge-fill': RiBatteryChargeFill,
  'ri-battery-charge-line': RiBatteryChargeLine,
  'ri-battery-fill': RiBatteryFill,
  'ri-battery-line': RiBatteryLine,
  'ri-battery-low-fill': RiBatteryLowFill,
  'ri-battery-low-line': RiBatteryLowLine,
  'ri-battery-saver-fill': RiBatterySaverFill,
  'ri-battery-saver-line': RiBatterySaverLine,
  'ri-battery-share-fill': RiBatteryShareFill,
  'ri-battery-share-line': RiBatteryShareLine,
  'ri-bear-smile-fill': RiBearSmileFill,
  'ri-bear-smile-line': RiBearSmileLine,
  'ri-behance-fill': RiBehanceFill,
  'ri-behance-line': RiBehanceLine,
  'ri-bell-fill': RiBellFill,
  'ri-bell-line': RiBellLine,
  'ri-bike-fill': RiBikeFill,
  'ri-bike-line': RiBikeLine,
  'ri-bilibili-fill': RiBilibiliFill,
  'ri-bilibili-line': RiBilibiliLine,
  'ri-bill-fill': RiBillFill,
  'ri-bill-line': RiBillLine,
  'ri-billiards-fill': RiBilliardsFill,
  'ri-billiards-line': RiBilliardsLine,
  'ri-bit-coin-fill': RiBitCoinFill,
  'ri-bit-coin-line': RiBitCoinLine,
  'ri-blaze-fill': RiBlazeFill,
  'ri-blaze-line': RiBlazeLine,
  'ri-bluetooth-connect-fill': RiBluetoothConnectFill,
  'ri-bluetooth-connect-line': RiBluetoothConnectLine,
  'ri-bluetooth-fill': RiBluetoothFill,
  'ri-bluetooth-line': RiBluetoothLine,
  'ri-blur-off-fill': RiBlurOffFill,
  'ri-blur-off-line': RiBlurOffLine,
  'ri-body-scan-fill': RiBodyScanFill,
  'ri-body-scan-line': RiBodyScanLine,
  'ri-bold': RiBold,
  'ri-book-2-fill': RiBook2Fill,
  'ri-book-2-line': RiBook2Line,
  'ri-book-3-fill': RiBook3Fill,
  'ri-book-3-line': RiBook3Line,
  'ri-book-fill': RiBookFill,
  'ri-book-line': RiBookLine,
  'ri-book-mark-fill': RiBookmarkFill,
  'ri-book-mark-line': RiBookmarkLine,
  'ri-book-open-fill': RiBookOpenFill,
  'ri-book-open-line': RiBookOpenLine,
  'ri-book-read-fill': RiBookReadFill,
  'ri-book-read-line': RiBookReadLine,
  'ri-booklet-fill': RiBookletFill,
  'ri-booklet-line': RiBookletLine,
  'ri-bookmark-2-fill': RiBookmark2Fill,
  'ri-bookmark-2-line': RiBookmark2Line,
  'ri-bookmark-3-fill': RiBookmark3Fill,
  'ri-bookmark-3-line': RiBookmark3Line,
  'ri-bookmark-fill': RiBookmarkFill,
  'ri-bookmark-line': RiBookmarkLine,
  'ri-boxing-fill': RiBoxingFill,
  'ri-boxing-line': RiBoxingLine,
  'ri-braces-fill': RiBracesFill,
  'ri-braces-line': RiBracesLine,
  'ri-brackets-fill': RiBracketsFill,
  'ri-brackets-line': RiBracketsLine,
  'ri-briefcase-2-fill': RiBriefcase2Fill,
  'ri-briefcase-2-line': RiBriefcase2Line,
  'ri-briefcase-3-fill': RiBriefcase3Fill,
  'ri-briefcase-3-line': RiBriefcase3Line,
  'ri-briefcase-4-fill': RiBriefcase4Fill,
  'ri-briefcase-4-line': RiBriefcase4Line,
  'ri-briefcase-5-fill': RiBriefcase5Fill,
  'ri-briefcase-5-line': RiBriefcase5Line,
  'ri-briefcase-fill': RiBriefcaseFill,
  'ri-briefcase-line': RiBriefcaseLine,
  'ri-bring-forward': RiBringForward,
  'ri-bring-to-front': RiBringToFront,
  'ri-broadcast-fill': RiBroadcastFill,
  'ri-broadcast-line': RiBroadcastLine,
  'ri-brush-2-fill': RiBrush2Fill,
  'ri-brush-2-line': RiBrush2Line,
  'ri-brush-3-fill': RiBrush3Fill,
  'ri-brush-3-line': RiBrush3Line,
  'ri-brush-4-fill': RiBrush4Fill,
  'ri-brush-4-line': RiBrush4Line,
  'ri-brush-fill': RiBrushFill,
  'ri-brush-line': RiBrushLine,
  'ri-bubble-chart-fill': RiBubbleChartFill,
  'ri-bubble-chart-line': RiBubbleChartLine,
  'ri-bug-2-fill': RiBug2Fill,
  'ri-bug-2-line': RiBug2Line,
  'ri-bug-fill': RiBugFill,
  'ri-bug-line': RiBugLine,
  'ri-building-2-fill': RiBuilding2Fill,
  'ri-building-2-line': RiBuilding2Line,
  'ri-building-3-fill': RiBuilding3Fill,
  'ri-building-3-line': RiBuilding3Line,
  'ri-building-4-fill': RiBuilding4Fill,
  'ri-building-4-line': RiBuilding4Line,
  'ri-building-fill': RiBuildingFill,
  'ri-building-line': RiBuildingLine,
  'ri-bus-2-fill': RiBus2Fill,
  'ri-bus-2-line': RiBus2Line,
  'ri-bus-fill': RiBusFill,
  'ri-bus-line': RiBusLine,
  'ri-bus-wifi-fill': RiBusWifiFill,
  'ri-bus-wifi-line': RiBusWifiLine,
  'ri-cactus-fill': RiCactusFill,
  'ri-cactus-line': RiCactusLine,
  'ri-cake-2-fill': RiCake2Fill,
  'ri-cake-2-line': RiCake2Line,
  'ri-cake-3-fill': RiCake3Fill,
  'ri-cake-3-line': RiCake3Line,
  'ri-cake-fill': RiCakeFill,
  'ri-cake-line': RiCakeLine,
  'ri-calculator-fill': RiCalculatorFill,
  'ri-calculator-line': RiCalculatorLine,
  'ri-calendar-2-fill': RiCalendar2Fill,
  'ri-calendar-2-line': RiCalendar2Line,
  'ri-calendar-check-fill': RiCalendarCheckFill,
  'ri-calendar-check-line': RiCalendarCheckLine,
  'ri-calendar-event-fill': RiCalendarEventFill,
  'ri-calendar-event-line': RiCalendarEventLine,
  'ri-calendar-fill': RiCalendarFill,
  'ri-calendar-line': RiCalendarLine,
  'ri-calendar-todo-fill': RiCalendarTodoFill,
  'ri-calendar-todo-line': RiCalendarTodoLine,
  'ri-camera-2-fill': RiCamera2Fill,
  'ri-camera-2-line': RiCamera2Line,
  'ri-camera-3-fill': RiCamera3Fill,
  'ri-camera-3-line': RiCamera3Line,
  'ri-camera-fill': RiCameraFill,
  'ri-camera-lens-fill': RiCameraLensFill,
  'ri-camera-lens-line': RiCameraLensLine,
  'ri-camera-line': RiCameraLine,
  'ri-camera-off-fill': RiCameraOffFill,
  'ri-camera-off-line': RiCameraOffLine,
  'ri-camera-switch-fill': RiCameraSwitchFill,
  'ri-camera-switch-line': RiCameraSwitchLine,
  'ri-capsule-fill': RiCapsuleFill,
  'ri-capsule-line': RiCapsuleLine,
  'ri-car-fill': RiCarFill,
  'ri-car-line': RiCarLine,
  'ri-car-washing-fill': RiCarWashingFill,
  'ri-car-washing-line': RiCarWashingLine,
  'ri-caravan-fill': RiCaravanFill,
  'ri-caravan-line': RiCaravanLine,
  'ri-cast-fill': RiCastFill,
  'ri-cast-line': RiCastLine,
  'ri-cellphone-fill': RiCellphoneFill,
  'ri-cellphone-line': RiCellphoneLine,
  'ri-celsius-fill': RiCelsiusFill,
  'ri-celsius-line': RiCelsiusLine,
  'ri-centos-fill': RiCentosFill,
  'ri-centos-line': RiCentosLine,
  'ri-character-recognition-fill': RiCharacterRecognitionFill,
  'ri-character-recognition-line': RiCharacterRecognitionLine,
  'ri-charging-pile-2-fill': RiChargingPile2Fill,
  'ri-charging-pile-2-line': RiChargingPile2Line,
  'ri-charging-pile-fill': RiChargingPileFill,
  'ri-charging-pile-line': RiChargingPileLine,
  'ri-chat-1-fill': RiChat1Fill,
  'ri-chat-1-line': RiChat1Line,
  'ri-chat-2-fill': RiChat2Fill,
  'ri-chat-2-line': RiChat2Line,
  'ri-chat-3-fill': RiChat3Fill,
  'ri-chat-3-line': RiChat3Line,
  'ri-chat-4-fill': RiChat4Fill,
  'ri-chat-4-line': RiChat4Line,
  'ri-chat-check-fill': RiChatCheckFill,
  'ri-chat-check-line': RiChatCheckLine,
  'ri-chat-delete-fill': RiChatDeleteFill,
  'ri-chat-delete-line': RiChatDeleteLine,
  'ri-chat-download-fill': RiChatDownloadFill,
  'ri-chat-download-line': RiChatDownloadLine,
  'ri-chat-follow-up-fill': RiChatFollowUpFill,
  'ri-chat-follow-up-line': RiChatFollowUpLine,
  'ri-chat-forward-fill': RiChatForwardFill,
  'ri-chat-forward-line': RiChatForwardLine,
  'ri-chat-heart-fill': RiChatHeartFill,
  'ri-chat-heart-line': RiChatHeartLine,
  'ri-chat-history-fill': RiChatHistoryFill,
  'ri-chat-history-line': RiChatHistoryLine,
  'ri-chat-new-fill': RiChatNewFill,
  'ri-chat-new-line': RiChatNewLine,
  'ri-chat-off-fill': RiChatOffFill,
  'ri-chat-off-line': RiChatOffLine,
  'ri-chat-poll-fill': RiChatPollFill,
  'ri-chat-poll-line': RiChatPollLine,
  'ri-chat-private-fill': RiChatPrivateFill,
  'ri-chat-private-line': RiChatPrivateLine,
  'ri-chat-quote-fill': RiChatQuoteFill,
  'ri-chat-quote-line': RiChatQuoteLine,
  'ri-chat-settings-fill': RiChatSettingsFill,
  'ri-chat-settings-line': RiChatSettingsLine,
  'ri-chat-smile-2-fill': RiChatSmile2Fill,
  'ri-chat-smile-2-line': RiChatSmile2Line,
  'ri-chat-smile-3-fill': RiChatSmile3Fill,
  'ri-chat-smile-3-line': RiChatSmile3Line,
  'ri-chat-smile-fill': RiChatSmileFill,
  'ri-chat-smile-line': RiChatSmileLine,
  'ri-chat-upload-fill': RiChatUploadFill,
  'ri-chat-upload-line': RiChatUploadLine,
  'ri-chat-voice-fill': RiChatVoiceFill,
  'ri-chat-voice-line': RiChatVoiceLine,
  'ri-check-double-fill': RiCheckDoubleFill,
  'ri-check-double-line': RiCheckDoubleLine,
  'ri-check-fill': RiCheckFill,
  'ri-check-line': RiCheckLine,
  'ri-checkbox-blank-circle-fill': RiCheckboxBlankCircleFill,
  'ri-checkbox-blank-circle-line': RiCheckboxBlankCircleLine,
  'ri-checkbox-blank-fill': RiCheckboxBlankFill,
  'ri-checkbox-blank-line': RiCheckboxBlankLine,
  'ri-checkbox-circle-fill': RiCheckboxCircleFill,
  'ri-checkbox-circle-line': RiCheckboxCircleLine,
  'ri-checkbox-fill': RiCheckboxFill,
  'ri-checkbox-indeterminate-fill': RiCheckboxIndeterminateFill,
  'ri-checkbox-indeterminate-line': RiCheckboxIndeterminateLine,
  'ri-checkbox-line': RiCheckboxLine,
  'ri-checkbox-multiple-blank-fill': RiCheckboxMultipleBlankFill,
  'ri-checkbox-multiple-blank-line': RiCheckboxMultipleBlankLine,
  'ri-checkbox-multiple-fill': RiCheckboxMultipleFill,
  'ri-checkbox-multiple-line': RiCheckboxMultipleLine,
  'ri-china-railway-fill': RiChinaRailwayFill,
  'ri-china-railway-line': RiChinaRailwayLine,
  'ri-chrome-fill': RiChromeFill,
  'ri-chrome-line': RiChromeLine,
  'ri-clapperboard-fill': RiClapperboardFill,
  'ri-clapperboard-line': RiClapperboardLine,
  'ri-clipboard-fill': RiClipboardFill,
  'ri-clipboard-line': RiClipboardLine,
  'ri-clockwise-2-fill': RiClockwise2Fill,
  'ri-clockwise-2-line': RiClockwise2Line,
  'ri-clockwise-fill': RiClockwiseFill,
  'ri-clockwise-line': RiClockwiseLine,
  'ri-close-circle-fill': RiCloseCircleFill,
  'ri-close-circle-line': RiCloseCircleLine,
  'ri-close-fill': RiCloseFill,
  'ri-close-line': RiCloseLine,
  'ri-closed-captioning-fill': RiClosedCaptioningFill,
  'ri-closed-captioning-line': RiClosedCaptioningLine,
  'ri-cloud-fill': RiCloudFill,
  'ri-cloud-line': RiCloudLine,
  'ri-cloud-off-fill': RiCloudOffFill,
  'ri-cloud-off-line': RiCloudOffLine,
  'ri-cloud-windy-fill': RiCloudWindyFill,
  'ri-cloud-windy-line': RiCloudWindyLine,
  'ri-cloudy-2-fill': RiCloudy2Fill,
  'ri-cloudy-2-line': RiCloudy2Line,
  'ri-cloudy-fill': RiCloudyFill,
  'ri-cloudy-line': RiCloudyLine,
  'ri-code-box-fill': RiCodeBoxFill,
  'ri-code-box-line': RiCodeBoxLine,
  'ri-code-fill': RiCodeFill,
  'ri-code-line': RiCodeLine,
  'ri-code-s-fill': RiCodeSFill,
  'ri-code-s-line': RiCodeSLine,
  'ri-code-s-slash-fill': RiCodeSSlashFill,
  'ri-code-s-slash-line': RiCodeSSlashLine,
  'ri-code-view': RiCodeView,
  'ri-codepen-fill': RiCodepenFill,
  'ri-codepen-line': RiCodepenLine,
  'ri-coin-fill': RiCoinFill,
  'ri-coin-line': RiCoinLine,
  'ri-coins-fill': RiCoinsFill,
  'ri-coins-line': RiCoinsLine,
  'ri-collage-fill': RiCollageFill,
  'ri-collage-line': RiCollageLine,
  'ri-command-fill': RiCommandFill,
  'ri-command-line': RiCommandLine,
  'ri-community-fill': RiCommunityFill,
  'ri-community-line': RiCommunityLine,
  'ri-compass-2-fill': RiCompass2Fill,
  'ri-compass-2-line': RiCompass2Line,
  'ri-compass-3-fill': RiCompass3Fill,
  'ri-compass-3-line': RiCompass3Line,
  'ri-compass-4-fill': RiCompass4Fill,
  'ri-compass-4-line': RiCompass4Line,
  'ri-compass-discover-fill': RiCompassDiscoverFill,
  'ri-compass-discover-line': RiCompassDiscoverLine,
  'ri-compass-fill': RiCompassFill,
  'ri-compass-line': RiCompassLine,
  'ri-compasses-2-fill': RiCompasses2Fill,
  'ri-compasses-2-line': RiCompasses2Line,
  'ri-compasses-fill': RiCompassesFill,
  'ri-compasses-line': RiCompassesLine,
  'ri-computer-fill': RiComputerFill,
  'ri-computer-line': RiComputerLine,
  'ri-contacts-book-2-fill': RiContactsBook2Fill,
  'ri-contacts-book-2-line': RiContactsBook2Line,
  'ri-contacts-book-fill': RiContactsBookFill,
  'ri-contacts-book-line': RiContactsBookLine,
  'ri-contacts-book-upload-fill': RiContactsBookUploadFill,
  'ri-contacts-book-upload-line': RiContactsBookUploadLine,
  'ri-contacts-fill': RiContactsFill,
  'ri-contacts-line': RiContactsLine,
  'ri-contrast-2-fill': RiContrast2Fill,
  'ri-contrast-2-line': RiContrast2Line,
  'ri-contrast-drop-2-fill': RiContrastDrop2Fill,
  'ri-contrast-drop-2-line': RiContrastDrop2Line,
  'ri-contrast-drop-fill': RiContrastDropFill,
  'ri-contrast-drop-line': RiContrastDropLine,
  'ri-contrast-fill': RiContrastFill,
  'ri-contrast-line': RiContrastLine,
  'ri-copper-coin-fill': RiCopperCoinFill,
  'ri-copper-coin-line': RiCopperCoinLine,
  'ri-copper-diamond-fill': RiCopperDiamondFill,
  'ri-copper-diamond-line': RiCopperDiamondLine,
  'ri-copyleft-fill': RiCopyleftFill,
  'ri-copyleft-line': RiCopyleftLine,
  'ri-copyright-fill': RiCopyrightFill,
  'ri-copyright-line': RiCopyrightLine,
  'ri-coreos-fill': RiCoreosFill,
  'ri-coreos-line': RiCoreosLine,
  'ri-coupon-2-fill': RiCoupon2Fill,
  'ri-coupon-2-line': RiCoupon2Line,
  'ri-coupon-3-fill': RiCoupon3Fill,
  'ri-coupon-3-line': RiCoupon3Line,
  'ri-coupon-4-fill': RiCoupon4Fill,
  'ri-coupon-4-line': RiCoupon4Line,
  'ri-coupon-5-fill': RiCoupon5Fill,
  'ri-coupon-5-line': RiCoupon5Line,
  'ri-coupon-fill': RiCouponFill,
  'ri-coupon-line': RiCouponLine,
  'ri-cpu-fill': RiCpuFill,
  'ri-cpu-line': RiCpuLine,
  'ri-creative-commons-by-fill': RiCreativeCommonsByFill,
  'ri-creative-commons-by-line': RiCreativeCommonsByLine,
  'ri-creative-commons-fill': RiCreativeCommonsFill,
  'ri-creative-commons-line': RiCreativeCommonsLine,
  'ri-creative-commons-nc-fill': RiCreativeCommonsNcFill,
  'ri-creative-commons-nc-line': RiCreativeCommonsNcLine,
  'ri-creative-commons-nd-fill': RiCreativeCommonsNdFill,
  'ri-creative-commons-nd-line': RiCreativeCommonsNdLine,
  'ri-creative-commons-sa-fill': RiCreativeCommonsSaFill,
  'ri-creative-commons-sa-line': RiCreativeCommonsSaLine,
  'ri-creative-commons-zero-fill': RiCreativeCommonsZeroFill,
  'ri-creative-commons-zero-line': RiCreativeCommonsZeroLine,
  'ri-criminal-fill': RiCriminalFill,
  'ri-criminal-line': RiCriminalLine,
  'ri-crop-2-fill': RiCrop2Fill,
  'ri-crop-2-line': RiCrop2Line,
  'ri-crop-fill': RiCropFill,
  'ri-crop-line': RiCropLine,
  'ri-css3-fill': RiCss3Fill,
  'ri-css3-line': RiCss3Line,
  'ri-cup-fill': RiCupFill,
  'ri-cup-line': RiCupLine,
  'ri-currency-fill': RiCurrencyFill,
  'ri-currency-line': RiCurrencyLine,
  'ri-cursor-fill': RiCursorFill,
  'ri-cursor-line': RiCursorLine,
  'ri-customer-service-2-fill': RiCustomerService2Fill,
  'ri-customer-service-2-line': RiCustomerService2Line,
  'ri-customer-service-fill': RiCustomerServiceFill,
  'ri-customer-service-line': RiCustomerServiceLine,
  'ri-dashboard-2-fill': RiDashboard2Fill,
  'ri-dashboard-2-line': RiDashboard2Line,
  'ri-dashboard-3-fill': RiDashboard3Fill,
  'ri-dashboard-3-line': RiDashboard3Line,
  'ri-dashboard-fill': RiDashboardFill,
  'ri-dashboard-line': RiDashboardLine,
  'ri-database-2-fill': RiDatabase2Fill,
  'ri-database-2-line': RiDatabase2Line,
  'ri-database-fill': RiDatabaseFill,
  'ri-database-line': RiDatabaseLine,
  'ri-delete-back-2-fill': RiDeleteBack2Fill,
  'ri-delete-back-2-line': RiDeleteBack2Line,
  'ri-delete-back-fill': RiDeleteBackFill,
  'ri-delete-back-line': RiDeleteBackLine,
  'ri-delete-bin-2-fill': RiDeleteBin2Fill,
  'ri-delete-bin-2-line': RiDeleteBin2Line,
  'ri-delete-bin-3-fill': RiDeleteBin3Fill,
  'ri-delete-bin-3-line': RiDeleteBin3Line,
  'ri-delete-bin-4-fill': RiDeleteBin4Fill,
  'ri-delete-bin-4-line': RiDeleteBin4Line,
  'ri-delete-bin-5-fill': RiDeleteBin5Fill,
  'ri-delete-bin-5-line': RiDeleteBin5Line,
  'ri-delete-bin-6-fill': RiDeleteBin6Fill,
  'ri-delete-bin-6-line': RiDeleteBin6Line,
  'ri-delete-bin-7-fill': RiDeleteBin7Fill,
  'ri-delete-bin-7-line': RiDeleteBin7Line,
  'ri-delete-bin-fill': RiDeleteBinFill,
  'ri-delete-bin-line': RiDeleteBinLine,
  'ri-delete-column': RiDeleteColumn,
  'ri-delete-row': RiDeleteRow,
  'ri-device-fill': RiDeviceFill,
  'ri-device-line': RiDeviceLine,
  'ri-device-recover-fill': RiDeviceRecoverFill,
  'ri-device-recover-line': RiDeviceRecoverLine,
  'ri-dingding-fill': RiDingdingFill,
  'ri-dingding-line': RiDingdingLine,
  'ri-direction-fill': RiDirectionFill,
  'ri-direction-line': RiDirectionLine,
  'ri-disc-fill': RiDiscFill,
  'ri-disc-line': RiDiscLine,
  'ri-discord-fill': RiDiscordFill,
  'ri-discord-line': RiDiscordLine,
  'ri-discuss-fill': RiDiscussFill,
  'ri-discuss-line': RiDiscussLine,
  'ri-dislike-fill': RiDislikeFill,
  'ri-dislike-line': RiDislikeLine,
  'ri-disqus-fill': RiDisqusFill,
  'ri-disqus-line': RiDisqusLine,
  'ri-divide-fill': RiDivideFill,
  'ri-divide-line': RiDivideLine,
  'ri-donut-chart-fill': RiDonutChartFill,
  'ri-donut-chart-line': RiDonutChartLine,
  'ri-door-closed-fill': RiDoorClosedFill,
  'ri-door-closed-line': RiDoorClosedLine,
  'ri-door-fill': RiDoorFill,
  'ri-door-line': RiDoorLine,
  'ri-door-lock-box-fill': RiDoorLockBoxFill,
  'ri-door-lock-box-line': RiDoorLockBoxLine,
  'ri-door-lock-fill': RiDoorLockFill,
  'ri-door-lock-line': RiDoorLockLine,
  'ri-door-open-fill': RiDoorOpenFill,
  'ri-door-open-line': RiDoorOpenLine,
  'ri-dossier-fill': RiDossierFill,
  'ri-dossier-line': RiDossierLine,
  'ri-douban-fill': RiDoubanFill,
  'ri-douban-line': RiDoubanLine,
  'ri-double-quotes-l': RiDoubleQuotesL,
  'ri-double-quotes-r': RiDoubleQuotesR,
  'ri-download-2-fill': RiDownload2Fill,
  'ri-download-2-line': RiDownload2Line,
  'ri-download-cloud-2-fill': RiDownloadCloud2Fill,
  'ri-download-cloud-2-line': RiDownloadCloud2Line,
  'ri-download-cloud-fill': RiDownloadCloudFill,
  'ri-download-cloud-line': RiDownloadCloudLine,
  'ri-download-fill': RiDownloadFill,
  'ri-download-line': RiDownloadLine,
  'ri-draft-fill': RiDraftFill,
  'ri-draft-line': RiDraftLine,
  'ri-drag-drop-fill': RiDragDropFill,
  'ri-drag-drop-line': RiDragDropLine,
  'ri-drag-move-2-fill': RiDragMove2Fill,
  'ri-drag-move-2-line': RiDragMove2Line,
  'ri-drag-move-fill': RiDragMoveFill,
  'ri-drag-move-line': RiDragMoveLine,
  'ri-dribbble-fill': RiDribbbleFill,
  'ri-dribbble-line': RiDribbbleLine,
  'ri-drive-fill': RiDriveFill,
  'ri-drive-line': RiDriveLine,
  'ri-drizzle-fill': RiDrizzleFill,
  'ri-drizzle-line': RiDrizzleLine,
  'ri-drop-fill': RiDropFill,
  'ri-drop-line': RiDropLine,
  'ri-dropbox-fill': RiDropboxFill,
  'ri-dropbox-line': RiDropboxLine,
  'ri-dual-sim-1-fill': RiDualSim1Fill,
  'ri-dual-sim-1-line': RiDualSim1Line,
  'ri-dual-sim-2-fill': RiDualSim2Fill,
  'ri-dual-sim-2-line': RiDualSim2Line,
  'ri-dv-fill': RiDvFill,
  'ri-dv-line': RiDvLine,
  'ri-dvd-fill': RiDvdFill,
  'ri-dvd-line': RiDvdLine,
  'ri-e-bike-2-fill': RiEBike2Fill,
  'ri-e-bike-2-line': RiEBike2Line,
  'ri-e-bike-fill': RiEBikeFill,
  'ri-e-bike-line': RiEBikeLine,
  'ri-earth-fill': RiEarthFill,
  'ri-earth-line': RiEarthLine,
  'ri-earthquake-fill': RiEarthquakeFill,
  'ri-earthquake-line': RiEarthquakeLine,
  'ri-edge-fill': RiEdgeFill,
  'ri-edge-line': RiEdgeLine,
  'ri-edit-2-fill': RiEdit2Fill,
  'ri-edit-2-line': RiEdit2Line,
  'ri-edit-box-fill': RiEditBoxFill,
  'ri-edit-box-line': RiEditBoxLine,
  'ri-edit-circle-fill': RiEditCircleFill,
  'ri-edit-circle-line': RiEditCircleLine,
  'ri-edit-fill': RiEditFill,
  'ri-edit-line': RiEditLine,
  'ri-eject-fill': RiEjectFill,
  'ri-eject-line': RiEjectLine,
  'ri-emotion-2-fill': RiEmotion2Fill,
  'ri-emotion-2-line': RiEmotion2Line,
  'ri-emotion-fill': RiEmotionFill,
  'ri-emotion-happy-fill': RiEmotionHappyFill,
  'ri-emotion-happy-line': RiEmotionHappyLine,
  'ri-emotion-laugh-fill': RiEmotionLaughFill,
  'ri-emotion-laugh-line': RiEmotionLaughLine,
  'ri-emotion-line': RiEmotionLine,
  'ri-emotion-normal-fill': RiEmotionNormalFill,
  'ri-emotion-normal-line': RiEmotionNormalLine,
  'ri-emotion-sad-fill': RiEmotionSadFill,
  'ri-emotion-sad-line': RiEmotionSadLine,
  'ri-emotion-unhappy-fill': RiEmotionUnhappyFill,
  'ri-emotion-unhappy-line': RiEmotionUnhappyLine,
  'ri-empathize-fill': RiEmpathizeFill,
  'ri-empathize-line': RiEmpathizeLine,
  'ri-emphasis-cn': RiEmphasisCn,
  'ri-emphasis': RiEmphasis,
  'ri-english-input': RiEnglishInput,
  'ri-equalizer-fill': RiEqualizerFill,
  'ri-equalizer-line': RiEqualizerLine,
  'ri-eraser-fill': RiEraserFill,
  'ri-eraser-line': RiEraserLine,
  'ri-error-warning-fill': RiErrorWarningFill,
  'ri-error-warning-line': RiErrorWarningLine,
  'ri-evernote-fill': RiEvernoteFill,
  'ri-evernote-line': RiEvernoteLine,
  'ri-exchange-box-fill': RiExchangeBoxFill,
  'ri-exchange-box-line': RiExchangeBoxLine,
  'ri-exchange-cny-fill': RiExchangeCnyFill,
  'ri-exchange-cny-line': RiExchangeCnyLine,
  'ri-exchange-dollar-fill': RiExchangeDollarFill,
  'ri-exchange-dollar-line': RiExchangeDollarLine,
  'ri-exchange-fill': RiExchangeFill,
  'ri-exchange-funds-fill': RiExchangeFundsFill,
  'ri-exchange-funds-line': RiExchangeFundsLine,
  'ri-exchange-line': RiExchangeLine,
  'ri-external-link-fill': RiExternalLinkFill,
  'ri-external-link-line': RiExternalLinkLine,
  'ri-eye-2-fill': RiEye2Fill,
  'ri-eye-2-line': RiEye2Line,
  'ri-eye-close-fill': RiEyeCloseFill,
  'ri-eye-close-line': RiEyeCloseLine,
  'ri-eye-fill': RiEyeFill,
  'ri-eye-line': RiEyeLine,
  'ri-eye-off-fill': RiEyeOffFill,
  'ri-eye-off-line': RiEyeOffLine,
  'ri-facebook-box-fill': RiFacebookBoxFill,
  'ri-facebook-box-line': RiFacebookBoxLine,
  'ri-facebook-circle-fill': RiFacebookCircleFill,
  'ri-facebook-circle-line': RiFacebookCircleLine,
  'ri-facebook-fill': RiFacebookFill,
  'ri-facebook-line': RiFacebookLine,
  'ri-fahrenheit-fill': RiFahrenheitFill,
  'ri-fahrenheit-line': RiFahrenheitLine,
  'ri-feedback-fill': RiFeedbackFill,
  'ri-feedback-line': RiFeedbackLine,
  'ri-file-2-fill': RiFile2Fill,
  'ri-file-2-line': RiFile2Line,
  'ri-file-3-fill': RiFile3Fill,
  'ri-file-3-line': RiFile3Line,
  'ri-file-4-fill': RiFile4Fill,
  'ri-file-4-line': RiFile4Line,
  'ri-file-add-fill': RiFileAddFill,
  'ri-file-add-line': RiFileAddLine,
  'ri-file-chart-2-fill': RiFileChart2Fill,
  'ri-file-chart-2-line': RiFileChart2Line,
  'ri-file-chart-fill': RiFileChartFill,
  'ri-file-chart-line': RiFileChartLine,
  'ri-file-cloud-fill': RiFileCloudFill,
  'ri-file-cloud-line': RiFileCloudLine,
  'ri-file-code-fill': RiFileCodeFill,
  'ri-file-code-line': RiFileCodeLine,
  'ri-file-copy-2-fill': RiFileCopy2Fill,
  'ri-file-copy-2-line': RiFileCopy2Line,
  'ri-file-copy-fill': RiFileCopyFill,
  'ri-file-copy-line': RiFileCopyLine,
  'ri-file-damage-fill': RiFileDamageFill,
  'ri-file-damage-line': RiFileDamageLine,
  'ri-file-download-fill': RiFileDownloadFill,
  'ri-file-download-line': RiFileDownloadLine,
  'ri-file-edit-fill': RiFileEditFill,
  'ri-file-edit-line': RiFileEditLine,
  'ri-file-excel-2-fill': RiFileExcel2Fill,
  'ri-file-excel-2-line': RiFileExcel2Line,
  'ri-file-excel-fill': RiFileExcelFill,
  'ri-file-excel-line': RiFileExcelLine,
  'ri-file-fill': RiFileFill,
  'ri-file-forbid-fill': RiFileForbidFill,
  'ri-file-forbid-line': RiFileForbidLine,
  'ri-file-gif-fill': RiFileGifFill,
  'ri-file-gif-line': RiFileGifLine,
  'ri-file-history-fill': RiFileHistoryFill,
  'ri-file-history-line': RiFileHistoryLine,
  'ri-file-hwp-fill': RiFileHwpFill,
  'ri-file-hwp-line': RiFileHwpLine,
  'ri-file-info-fill': RiFileInfoFill,
  'ri-file-info-line': RiFileInfoLine,
  'ri-file-line': RiFileLine,
  'ri-file-list-2-fill': RiFileList2Fill,
  'ri-file-list-2-line': RiFileList2Line,
  'ri-file-list-3-fill': RiFileList3Fill,
  'ri-file-list-3-line': RiFileList3Line,
  'ri-file-list-fill': RiFileListFill,
  'ri-file-list-line': RiFileListLine,
  'ri-file-lock-fill': RiFileLockFill,
  'ri-file-lock-line': RiFileLockLine,
  'ri-file-mark-fill': RiFileMarkedFill,
  'ri-file-mark-line': RiFileMarkedLine,
  'ri-file-music-fill': RiFileMusicFill,
  'ri-file-music-line': RiFileMusicLine,
  'ri-file-paper-2-fill': RiFilePaper2Fill,
  'ri-file-paper-2-line': RiFilePaper2Line,
  'ri-file-paper-fill': RiFilePaperFill,
  'ri-file-paper-line': RiFilePaperLine,
  'ri-file-pdf-fill': RiFilePdfFill,
  'ri-file-pdf-line': RiFilePdfLine,
  'ri-file-ppt-2-fill': RiFilePpt2Fill,
  'ri-file-ppt-2-line': RiFilePpt2Line,
  'ri-file-ppt-fill': RiFilePptFill,
  'ri-file-ppt-line': RiFilePptLine,
  'ri-file-reduce-fill': RiFileReduceFill,
  'ri-file-reduce-line': RiFileReduceLine,
  'ri-file-search-fill': RiFileSearchFill,
  'ri-file-search-line': RiFileSearchLine,
  'ri-file-settings-fill': RiFileSettingsFill,
  'ri-file-settings-line': RiFileSettingsLine,
  'ri-file-shield-2-fill': RiFileShield2Fill,
  'ri-file-shield-2-line': RiFileShield2Line,
  'ri-file-shield-fill': RiFileShieldFill,
  'ri-file-shield-line': RiFileShieldLine,
  'ri-file-shred-fill': RiFileShredFill,
  'ri-file-shred-line': RiFileShredLine,
  'ri-file-text-fill': RiFileTextFill,
  'ri-file-text-line': RiFileTextLine,
  'ri-file-transfer-fill': RiFileTransferFill,
  'ri-file-transfer-line': RiFileTransferLine,
  'ri-file-unknow-fill': RiFileUnknowFill,
  'ri-file-unknow-line': RiFileUnknowLine,
  'ri-file-upload-fill': RiFileUploadFill,
  'ri-file-upload-line': RiFileUploadLine,
  'ri-file-user-fill': RiFileUserFill,
  'ri-file-user-line': RiFileUserLine,
  'ri-file-warning-fill': RiFileWarningFill,
  'ri-file-warning-line': RiFileWarningLine,
  'ri-file-word-2-fill': RiFileWord2Fill,
  'ri-file-word-2-line': RiFileWord2Line,
  'ri-file-word-fill': RiFileWordFill,
  'ri-file-word-line': RiFileWordLine,
  'ri-file-zip-fill': RiFileZipFill,
  'ri-file-zip-line': RiFileZipLine,
  'ri-film-fill': RiFilmFill,
  'ri-film-line': RiFilmLine,
  'ri-filter-2-fill': RiFilter2Fill,
  'ri-filter-2-line': RiFilter2Line,
  'ri-filter-3-fill': RiFilter3Fill,
  'ri-filter-3-line': RiFilter3Line,
  'ri-filter-fill': RiFilterFill,
  'ri-filter-line': RiFilterLine,
  'ri-filter-off-fill': RiFilterOffFill,
  'ri-filter-off-line': RiFilterOffLine,
  'ri-find-replace-fill': RiFindReplaceFill,
  'ri-find-replace-line': RiFindReplaceLine,
  'ri-finder-fill': RiFinderFill,
  'ri-finder-line': RiFinderLine,
  'ri-fingerprint-2-fill': RiFingerprint2Fill,
  'ri-fingerprint-2-line': RiFingerprint2Line,
  'ri-fingerprint-fill': RiFingerprintFill,
  'ri-fingerprint-line': RiFingerprintLine,
  'ri-fire-fill': RiFireFill,
  'ri-fire-line': RiFireLine,
  'ri-firefox-fill': RiFirefoxFill,
  'ri-firefox-line': RiFirefoxLine,
  'ri-first-aid-kit-fill': RiFirstAidKitFill,
  'ri-first-aid-kit-line': RiFirstAidKitLine,
  'ri-flag-2-fill': RiFlag2Fill,
  'ri-flag-2-line': RiFlag2Line,
  'ri-flag-fill': RiFlagFill,
  'ri-flag-line': RiFlagLine,
  'ri-flashlight-fill': RiFlashlightFill,
  'ri-flashlight-line': RiFlashlightLine,
  'ri-flask-fill': RiFlaskFill,
  'ri-flask-line': RiFlaskLine,
  'ri-flight-land-fill': RiFlightLandFill,
  'ri-flight-land-line': RiFlightLandLine,
  'ri-flight-takeoff-fill': RiFlightTakeoffFill,
  'ri-flight-takeoff-line': RiFlightTakeoffLine,
  'ri-flood-fill': RiFloodFill,
  'ri-flood-line': RiFloodLine,
  'ri-flow-chart': RiFlowChart,
  'ri-flutter-fill': RiFlutterFill,
  'ri-flutter-line': RiFlutterLine,
  'ri-focus-2-fill': RiFocus2Fill,
  'ri-focus-2-line': RiFocus2Line,
  'ri-focus-3-fill': RiFocus3Fill,
  'ri-focus-3-line': RiFocus3Line,
  'ri-focus-fill': RiFocusFill,
  'ri-focus-line': RiFocusLine,
  'ri-foggy-fill': RiFoggyFill,
  'ri-foggy-line': RiFoggyLine,
  'ri-folder-2-fill': RiFolder2Fill,
  'ri-folder-2-line': RiFolder2Line,
  'ri-folder-3-fill': RiFolder3Fill,
  'ri-folder-3-line': RiFolder3Line,
  'ri-folder-4-fill': RiFolder4Fill,
  'ri-folder-4-line': RiFolder4Line,
  'ri-folder-5-fill': RiFolder5Fill,
  'ri-folder-5-line': RiFolder5Line,
  'ri-folder-add-fill': RiFolderAddFill,
  'ri-folder-add-line': RiFolderAddLine,
  'ri-folder-chart-2-fill': RiFolderChart2Fill,
  'ri-folder-chart-2-line': RiFolderChart2Line,
  'ri-folder-chart-fill': RiFolderChartFill,
  'ri-folder-chart-line': RiFolderChartLine,
  'ri-folder-download-fill': RiFolderDownloadFill,
  'ri-folder-download-line': RiFolderDownloadLine,
  'ri-folder-fill': RiFolderFill,
  'ri-folder-forbid-fill': RiFolderForbidFill,
  'ri-folder-forbid-line': RiFolderForbidLine,
  'ri-folder-history-fill': RiFolderHistoryFill,
  'ri-folder-history-line': RiFolderHistoryLine,
  'ri-folder-info-fill': RiFolderInfoFill,
  'ri-folder-info-line': RiFolderInfoLine,
  'ri-folder-keyhole-fill': RiFolderKeyholeFill,
  'ri-folder-keyhole-line': RiFolderKeyholeLine,
  'ri-folder-line': RiFolderLine,
  'ri-folder-lock-fill': RiFolderLockFill,
  'ri-folder-lock-line': RiFolderLockLine,
  'ri-folder-music-fill': RiFolderMusicFill,
  'ri-folder-music-line': RiFolderMusicLine,
  'ri-folder-open-fill': RiFolderOpenFill,
  'ri-folder-open-line': RiFolderOpenLine,
  'ri-folder-received-fill': RiFolderReceivedFill,
  'ri-folder-received-line': RiFolderReceivedLine,
  'ri-folder-reduce-fill': RiFolderReduceFill,
  'ri-folder-reduce-line': RiFolderReduceLine,
  'ri-folder-settings-fill': RiFolderSettingsFill,
  'ri-folder-settings-line': RiFolderSettingsLine,
  'ri-folder-shared-fill': RiFolderSharedFill,
  'ri-folder-shared-line': RiFolderSharedLine,
  'ri-folder-shield-2-fill': RiFolderShield2Fill,
  'ri-folder-shield-2-line': RiFolderShield2Line,
  'ri-folder-shield-fill': RiFolderShieldFill,
  'ri-folder-shield-line': RiFolderShieldLine,
  'ri-folder-transfer-fill': RiFolderTransferFill,
  'ri-folder-transfer-line': RiFolderTransferLine,
  'ri-folder-unknow-fill': RiFolderUnknowFill,
  'ri-folder-unknow-line': RiFolderUnknowLine,
  'ri-folder-upload-fill': RiFolderUploadFill,
  'ri-folder-upload-line': RiFolderUploadLine,
  'ri-folder-user-fill': RiFolderUserFill,
  'ri-folder-user-line': RiFolderUserLine,
  'ri-folder-warning-fill': RiFolderWarningFill,
  'ri-folder-warning-line': RiFolderWarningLine,
  'ri-folder-zip-fill': RiFolderZipFill,
  'ri-folder-zip-line': RiFolderZipLine,
  'ri-folders-fill': RiFoldersFill,
  'ri-folders-line': RiFoldersLine,
  'ri-font-color': RiFontColor,
  'ri-font-size-2': RiFontSize2,
  'ri-font-size': RiFontSize,
  'ri-football-fill': RiFootballFill,
  'ri-football-line': RiFootballLine,
  'ri-footprint-fill': RiFootprintFill,
  'ri-footprint-line': RiFootprintLine,
  'ri-forbid-2-fill': RiForbid2Fill,
  'ri-forbid-2-line': RiForbid2Line,
  'ri-forbid-fill': RiForbidFill,
  'ri-forbid-line': RiForbidLine,
  'ri-format-clear': RiFormatClear,
  'ri-fridge-fill': RiFridgeFill,
  'ri-fridge-line': RiFridgeLine,
  'ri-fullscreen-exit-fill': RiFullscreenExitFill,
  'ri-fullscreen-exit-line': RiFullscreenExitLine,
  'ri-fullscreen-fill': RiFullscreenFill,
  'ri-fullscreen-line': RiFullscreenLine,
  'ri-function-fill': RiFunctionFill,
  'ri-function-line': RiFunctionLine,
  'ri-functions': RiFunctions,
  'ri-funds-box-fill': RiFundsBoxFill,
  'ri-funds-box-line': RiFundsBoxLine,
  'ri-funds-fill': RiFundsFill,
  'ri-funds-line': RiFundsLine,
  'ri-gallery-fill': RiGalleryFill,
  'ri-gallery-line': RiGalleryLine,
  'ri-gallery-upload-fill': RiGalleryUploadFill,
  'ri-gallery-upload-line': RiGalleryUploadLine,
  'ri-game-fill': RiGameFill,
  'ri-game-line': RiGameLine,
  'ri-gamepad-fill': RiGamepadFill,
  'ri-gamepad-line': RiGamepadLine,
  'ri-gas-station-fill': RiGasStationFill,
  'ri-gas-station-line': RiGasStationLine,
  'ri-gatsby-fill': RiGatsbyFill,
  'ri-gatsby-line': RiGatsbyLine,
  'ri-genderless-fill': RiGenderlessFill,
  'ri-genderless-line': RiGenderlessLine,
  'ri-ghost-2-fill': RiGhost2Fill,
  'ri-ghost-2-line': RiGhost2Line,
  'ri-ghost-fill': RiGhostFill,
  'ri-ghost-line': RiGhostLine,
  'ri-ghost-smile-fill': RiGhostSmileFill,
  'ri-ghost-smile-line': RiGhostSmileLine,
  'ri-gift-2-fill': RiGift2Fill,
  'ri-gift-2-line': RiGift2Line,
  'ri-gift-fill': RiGiftFill,
  'ri-gift-line': RiGiftLine,
  'ri-git-branch-fill': RiGitBranchFill,
  'ri-git-branch-line': RiGitBranchLine,
  'ri-git-commit-fill': RiGitCommitFill,
  'ri-git-commit-line': RiGitCommitLine,
  'ri-git-merge-fill': RiGitMergeFill,
  'ri-git-merge-line': RiGitMergeLine,
  'ri-git-pull-request-fill': RiGitPullRequestFill,
  'ri-git-pull-request-line': RiGitPullRequestLine,
  'ri-git-repository-commits-fill': RiGitRepositoryCommitsFill,
  'ri-git-repository-commits-line': RiGitRepositoryCommitsLine,
  'ri-git-repository-fill': RiGitRepositoryFill,
  'ri-git-repository-line': RiGitRepositoryLine,
  'ri-git-repository-private-fill': RiGitRepositoryPrivateFill,
  'ri-git-repository-private-line': RiGitRepositoryPrivateLine,
  'ri-github-fill': RiGithubFill,
  'ri-github-line': RiGithubLine,
  'ri-gitlab-fill': RiGitlabFill,
  'ri-gitlab-line': RiGitlabLine,
  'ri-global-fill': RiGlobalFill,
  'ri-global-line': RiGlobalLine,
  'ri-globe-fill': RiGlobeFill,
  'ri-globe-line': RiGlobeLine,
  'ri-goblet-fill': RiGobletFill,
  'ri-goblet-line': RiGobletLine,
  'ri-google-fill': RiGoogleFill,
  'ri-google-line': RiGoogleLine,
  'ri-google-play-fill': RiGooglePlayFill,
  'ri-google-play-line': RiGooglePlayLine,
  'ri-government-fill': RiGovernmentFill,
  'ri-government-line': RiGovernmentLine,
  'ri-gps-fill': RiGpsFill,
  'ri-gps-line': RiGpsLine,
  'ri-gradienter-fill': RiGradienterFill,
  'ri-gradienter-line': RiGradienterLine,
  'ri-grid-fill': RiGridFill,
  'ri-grid-line': RiGridLine,
  'ri-group-2-fill': RiGroup2Fill,
  'ri-group-2-line': RiGroup2Line,
  'ri-group-fill': RiGroupFill,
  'ri-group-line': RiGroupLine,
  'ri-guide-fill': RiGuideFill,
  'ri-guide-line': RiGuideLine,
  'ri-h-1': RiH1,
  'ri-h-2': RiH2,
  'ri-h-3': RiH3,
  'ri-h-4': RiH4,
  'ri-h-5': RiH5,
  'ri-h-6': RiH6,
  'ri-hail-fill': RiHailFill,
  'ri-hail-line': RiHailLine,
  'ri-hammer-fill': RiHammerFill,
  'ri-hammer-line': RiHammerLine,
  'ri-hand-coin-fill': RiHandCoinFill,
  'ri-hand-coin-line': RiHandCoinLine,
  'ri-hand-heart-fill': RiHandHeartFill,
  'ri-hand-heart-line': RiHandHeartLine,
  'ri-hand-sanitizer-fill': RiHandSanitizerFill,
  'ri-hand-sanitizer-line': RiHandSanitizerLine,
  'ri-handbag-fill': RiHandbagFill,
  'ri-handbag-line': RiHandbagLine,
  'ri-hard-drive-2-fill': RiHardDrive2Fill,
  'ri-hard-drive-2-line': RiHardDrive2Line,
  'ri-hard-drive-fill': RiHardDriveFill,
  'ri-hard-drive-line': RiHardDriveLine,
  'ri-hashtag': RiHashtag,
  'ri-haze-2-fill': RiHaze2Fill,
  'ri-haze-2-line': RiHaze2Line,
  'ri-haze-fill': RiHazeFill,
  'ri-haze-line': RiHazeLine,
  'ri-hd-fill': RiHdFill,
  'ri-hd-line': RiHdLine,
  'ri-heading': RiHeading,
  'ri-headphone-fill': RiHeadphoneFill,
  'ri-headphone-line': RiHeadphoneLine,
  'ri-health-book-fill': RiHealthBookFill,
  'ri-health-book-line': RiHealthBookLine,
  'ri-heart-2-fill': RiHeart2Fill,
  'ri-heart-2-line': RiHeart2Line,
  'ri-heart-3-fill': RiHeart3Fill,
  'ri-heart-3-line': RiHeart3Line,
  'ri-heart-add-fill': RiHeartAddFill,
  'ri-heart-add-line': RiHeartAddLine,
  'ri-heart-fill': RiHeartFill,
  'ri-heart-line': RiHeartLine,
  'ri-heart-pulse-fill': RiHeartPulseFill,
  'ri-heart-pulse-line': RiHeartPulseLine,
  'ri-hearts-fill': RiHeartsFill,
  'ri-hearts-line': RiHeartsLine,
  'ri-heavy-showers-fill': RiHeavyShowersFill,
  'ri-heavy-showers-line': RiHeavyShowersLine,
  'ri-history-fill': RiHistoryFill,
  'ri-history-line': RiHistoryLine,
  'ri-home-2-fill': RiHome2Fill,
  'ri-home-2-line': RiHome2Line,
  'ri-home-3-fill': RiHome3Fill,
  'ri-home-3-line': RiHome3Line,
  'ri-home-4-fill': RiHome4Fill,
  'ri-home-4-line': RiHome4Line,
  'ri-home-5-fill': RiHome5Fill,
  'ri-home-5-line': RiHome5Line,
  'ri-home-6-fill': RiHome6Fill,
  'ri-home-6-line': RiHome6Line,
  'ri-home-7-fill': RiHome7Fill,
  'ri-home-7-line': RiHome7Line,
  'ri-home-8-fill': RiHome8Fill,
  'ri-home-8-line': RiHome8Line,
  'ri-home-fill': RiHomeFill,
  'ri-home-gear-fill': RiHomeGearFill,
  'ri-home-gear-line': RiHomeGearLine,
  'ri-home-heart-fill': RiHomeHeartFill,
  'ri-home-heart-line': RiHomeHeartLine,
  'ri-home-line': RiHomeLine,
  'ri-home-smile-2-fill': RiHomeSmile2Fill,
  'ri-home-smile-2-line': RiHomeSmile2Line,
  'ri-home-smile-fill': RiHomeSmileFill,
  'ri-home-smile-line': RiHomeSmileLine,
  'ri-home-wifi-fill': RiHomeWifiFill,
  'ri-home-wifi-line': RiHomeWifiLine,
  'ri-honor-of-kings-fill': RiHonorOfKingsFill,
  'ri-honor-of-kings-line': RiHonorOfKingsLine,
  'ri-honour-fill': RiHonourFill,
  'ri-honour-line': RiHonourLine,
  'ri-hospital-fill': RiHospitalFill,
  'ri-hospital-line': RiHospitalLine,
  'ri-hotel-bed-fill': RiHotelBedFill,
  'ri-hotel-bed-line': RiHotelBedLine,
  'ri-hotel-fill': RiHotelFill,
  'ri-hotel-line': RiHotelLine,
  'ri-hotspot-fill': RiHotspotFill,
  'ri-hotspot-line': RiHotspotLine,
  'ri-hq-fill': RiHqFill,
  'ri-hq-line': RiHqLine,
  'ri-html5-fill': RiHtml5Fill,
  'ri-html5-line': RiHtml5Line,
  'ri-ie-fill': RiIeFill,
  'ri-ie-line': RiIeLine,
  'ri-image-2-fill': RiImage2Fill,
  'ri-image-2-line': RiImage2Line,
  'ri-image-add-fill': RiImageAddFill,
  'ri-image-add-line': RiImageAddLine,
  'ri-image-edit-fill': RiImageEditFill,
  'ri-image-edit-line': RiImageEditLine,
  'ri-image-fill': RiImageFill,
  'ri-image-line': RiImageLine,
  'ri-inbox-archive-fill': RiInboxArchiveFill,
  'ri-inbox-archive-line': RiInboxArchiveLine,
  'ri-inbox-fill': RiInboxFill,
  'ri-inbox-line': RiInboxLine,
  'ri-inbox-unarchive-fill': RiInboxUnarchiveFill,
  'ri-inbox-unarchive-line': RiInboxUnarchiveLine,
  'ri-increase-decrease-fill': RiIncreaseDecreaseFill,
  'ri-increase-decrease-line': RiIncreaseDecreaseLine,
  'ri-indent-decrease': RiIndentDecrease,
  'ri-indent-increase': RiIndentIncrease,
  'ri-indeterminate-circle-fill': RiIndeterminateCircleFill,
  'ri-indeterminate-circle-line': RiIndeterminateCircleLine,
  'ri-information-fill': RiInformationFill,
  'ri-information-line': RiInformationLine,
  'ri-infrared-thermometer-fill': RiInfraredThermometerFill,
  'ri-infrared-thermometer-line': RiInfraredThermometerLine,
  'ri-ink-bottle-fill': RiInkBottleFill,
  'ri-ink-bottle-line': RiInkBottleLine,
  'ri-input-cursor-move': RiInputCursorMove,
  'ri-input-method-fill': RiInputMethodFill,
  'ri-input-method-line': RiInputMethodLine,
  'ri-insert-column-left': RiInsertColumnLeft,
  'ri-insert-column-right': RiInsertColumnRight,
  'ri-insert-row-bottom': RiInsertRowBottom,
  'ri-insert-row-top': RiInsertRowTop,
  'ri-instagram-fill': RiInstagramFill,
  'ri-instagram-line': RiInstagramLine,
  'ri-install-fill': RiInstallFill,
  'ri-install-line': RiInstallLine,
  'ri-invision-fill': RiInvisionFill,
  'ri-invision-line': RiInvisionLine,
  'ri-italic': RiItalic,
  'ri-kakao-talk-fill': RiKakaoTalkFill,
  'ri-kakao-talk-line': RiKakaoTalkLine,
  'ri-key-2-fill': RiKey2Fill,
  'ri-key-2-line': RiKey2Line,
  'ri-key-fill': RiKeyFill,
  'ri-key-line': RiKeyLine,
  'ri-keyboard-box-fill': RiKeyboardBoxFill,
  'ri-keyboard-box-line': RiKeyboardBoxLine,
  'ri-keyboard-fill': RiKeyboardFill,
  'ri-keyboard-line': RiKeyboardLine,
  'ri-keynote-fill': RiKeynoteFill,
  'ri-keynote-line': RiKeynoteLine,
  'ri-knife-blood-fill': RiKnifeBloodFill,
  'ri-knife-blood-line': RiKnifeBloodLine,
  'ri-knife-fill': RiKnifeFill,
  'ri-knife-line': RiKnifeLine,
  'ri-landscape-fill': RiLandscapeFill,
  'ri-landscape-line': RiLandscapeLine,
  'ri-layout-2-fill': RiLayout2Fill,
  'ri-layout-2-line': RiLayout2Line,
  'ri-layout-3-fill': RiLayout3Fill,
  'ri-layout-3-line': RiLayout3Line,
  'ri-layout-4-fill': RiLayout4Fill,
  'ri-layout-4-line': RiLayout4Line,
  'ri-layout-5-fill': RiLayout5Fill,
  'ri-layout-5-line': RiLayout5Line,
  'ri-layout-6-fill': RiLayout6Fill,
  'ri-layout-6-line': RiLayout6Line,
  'ri-layout-bottom-2-fill': RiLayoutBottom2Fill,
  'ri-layout-bottom-2-line': RiLayoutBottom2Line,
  'ri-layout-bottom-fill': RiLayoutBottomFill,
  'ri-layout-bottom-line': RiLayoutBottomLine,
  'ri-layout-column-fill': RiLayoutColumnFill,
  'ri-layout-column-line': RiLayoutColumnLine,
  'ri-layout-fill': RiLayoutFill,
  'ri-layout-grid-fill': RiLayoutGridFill,
  'ri-layout-grid-line': RiLayoutGridLine,
  'ri-layout-left-2-fill': RiLayoutLeft2Fill,
  'ri-layout-left-2-line': RiLayoutLeft2Line,
  'ri-layout-left-fill': RiLayoutLeftFill,
  'ri-layout-left-line': RiLayoutLeftLine,
  'ri-layout-line': RiLayoutLine,
  'ri-layout-masonry-fill': RiLayoutMasonryFill,
  'ri-layout-masonry-line': RiLayoutMasonryLine,
  'ri-layout-right-2-fill': RiLayoutRight2Fill,
  'ri-layout-right-2-line': RiLayoutRight2Line,
  'ri-layout-right-fill': RiLayoutRightFill,
  'ri-layout-right-line': RiLayoutRightLine,
  'ri-layout-row-fill': RiLayoutRowFill,
  'ri-layout-row-line': RiLayoutRowLine,
  'ri-layout-top-2-fill': RiLayoutTop2Fill,
  'ri-layout-top-2-line': RiLayoutTop2Line,
  'ri-layout-top-fill': RiLayoutTopFill,
  'ri-layout-top-line': RiLayoutTopLine,
  'ri-leaf-fill': RiLeafFill,
  'ri-leaf-line': RiLeafLine,
  'ri-lifebuoy-fill': RiLifebuoyFill,
  'ri-lifebuoy-line': RiLifebuoyLine,
  'ri-lightbulb-fill': RiLightbulbFill,
  'ri-lightbulb-flash-fill': RiLightbulbFlashFill,
  'ri-lightbulb-flash-line': RiLightbulbFlashLine,
  'ri-lightbulb-line': RiLightbulbLine,
  'ri-line-chart-fill': RiLineChartFill,
  'ri-line-chart-line': RiLineChartLine,
  'ri-line-fill': RiLineFill,
  'ri-line-height': RiLineHeight,
  'ri-line-line': RiLineLine,
  'ri-link-m': RiLinkM,
  'ri-link-unlink-m': RiLinkUnlinkM,
  'ri-link-unlink': RiLinkUnlink,
  'ri-link': RiLink,
  'ri-linkedin-box-fill': RiLinkedinBoxFill,
  'ri-linkedin-box-line': RiLinkedinBoxLine,
  'ri-linkedin-fill': RiLinkedinFill,
  'ri-linkedin-line': RiLinkedinLine,
  'ri-links-fill': RiLinksFill,
  'ri-links-line': RiLinksLine,
  'ri-list-check-2': RiListCheck2,
  'ri-list-check': RiListCheck,
  'ri-list-ordered': RiListOrdered,
  'ri-list-settings-fill': RiListSettingsFill,
  'ri-list-settings-line': RiListSettingsLine,
  'ri-list-unordered': RiListUnordered,
  'ri-live-fill': RiLiveFill,
  'ri-live-line': RiLiveLine,
  'ri-loader-2-fill': RiLoader2Fill,
  'ri-loader-2-line': RiLoader2Line,
  'ri-loader-3-fill': RiLoader3Fill,
  'ri-loader-3-line': RiLoader3Line,
  'ri-loader-4-fill': RiLoader4Fill,
  'ri-loader-4-line': RiLoader4Line,
  'ri-loader-5-fill': RiLoader5Fill,
  'ri-loader-5-line': RiLoader5Line,
  'ri-loader-fill': RiLoaderFill,
  'ri-loader-line': RiLoaderLine,
  'ri-lock-2-fill': RiLock2Fill,
  'ri-lock-2-line': RiLock2Line,
  'ri-lock-fill': RiLockFill,
  'ri-lock-line': RiLockLine,
  'ri-lock-password-fill': RiLockPasswordFill,
  'ri-lock-password-line': RiLockPasswordLine,
  'ri-lock-unlock-fill': RiLockUnlockFill,
  'ri-lock-unlock-line': RiLockUnlockLine,
  'ri-login-box-fill': RiLoginBoxFill,
  'ri-login-box-line': RiLoginBoxLine,
  'ri-login-circle-fill': RiLoginCircleFill,
  'ri-login-circle-line': RiLoginCircleLine,
  'ri-logout-box-fill': RiLogoutBoxFill,
  'ri-logout-box-line': RiLogoutBoxLine,
  'ri-logout-box-r-fill': RiLogoutBoxRFill,
  'ri-logout-box-r-line': RiLogoutBoxRLine,
  'ri-logout-circle-fill': RiLogoutCircleFill,
  'ri-logout-circle-line': RiLogoutCircleLine,
  'ri-logout-circle-r-fill': RiLogoutCircleRFill,
  'ri-logout-circle-r-line': RiLogoutCircleRLine,
  'ri-luggage-cart-fill': RiLuggageCartFill,
  'ri-luggage-cart-line': RiLuggageCartLine,
  'ri-luggage-deposit-fill': RiLuggageDepositFill,
  'ri-luggage-deposit-line': RiLuggageDepositLine,
  'ri-lungs-fill': RiLungsFill,
  'ri-lungs-line': RiLungsLine,
  'ri-mac-fill': RiMacFill,
  'ri-mac-line': RiMacLine,
  'ri-macbook-fill': RiMacbookFill,
  'ri-macbook-line': RiMacbookLine,
  'ri-magic-fill': RiMagicFill,
  'ri-magic-line': RiMagicLine,
  'ri-mail-add-fill': RiMailAddFill,
  'ri-mail-add-line': RiMailAddLine,
  'ri-mail-check-fill': RiMailCheckFill,
  'ri-mail-check-line': RiMailCheckLine,
  'ri-mail-close-fill': RiMailCloseFill,
  'ri-mail-close-line': RiMailCloseLine,
  'ri-mail-download-fill': RiMailDownloadFill,
  'ri-mail-download-line': RiMailDownloadLine,
  'ri-mail-fill': RiMailFill,
  'ri-mail-forbid-fill': RiMailForbidFill,
  'ri-mail-forbid-line': RiMailForbidLine,
  'ri-mail-line': RiMailLine,
  'ri-mail-lock-fill': RiMailLockFill,
  'ri-mail-lock-line': RiMailLockLine,
  'ri-mail-open-fill': RiMailOpenFill,
  'ri-mail-open-line': RiMailOpenLine,
  'ri-mail-send-fill': RiMailSendFill,
  'ri-mail-send-line': RiMailSendLine,
  'ri-mail-settings-fill': RiMailSettingsFill,
  'ri-mail-settings-line': RiMailSettingsLine,
  'ri-mail-star-fill': RiMailStarFill,
  'ri-mail-star-line': RiMailStarLine,
  'ri-mail-unread-fill': RiMailUnreadFill,
  'ri-mail-unread-line': RiMailUnreadLine,
  'ri-mail-volume-fill': RiMailVolumeFill,
  'ri-mail-volume-line': RiMailVolumeLine,
  'ri-map-2-fill': RiMap2Fill,
  'ri-map-2-line': RiMap2Line,
  'ri-map-fill': RiMapFill,
  'ri-map-line': RiMapLine,
  'ri-map-pin-2-fill': RiMapPin2Fill,
  'ri-map-pin-2-line': RiMapPin2Line,
  'ri-map-pin-3-fill': RiMapPin3Fill,
  'ri-map-pin-3-line': RiMapPin3Line,
  'ri-map-pin-4-fill': RiMapPin4Fill,
  'ri-map-pin-4-line': RiMapPin4Line,
  'ri-map-pin-5-fill': RiMapPin5Fill,
  'ri-map-pin-5-line': RiMapPin5Line,
  'ri-map-pin-add-fill': RiMapPinAddFill,
  'ri-map-pin-add-line': RiMapPinAddLine,
  'ri-map-pin-fill': RiMapPinFill,
  'ri-map-pin-line': RiMapPinLine,
  'ri-map-pin-range-fill': RiMapPinRangeFill,
  'ri-map-pin-range-line': RiMapPinRangeLine,
  'ri-map-pin-time-fill': RiMapPinTimeFill,
  'ri-map-pin-time-line': RiMapPinTimeLine,
  'ri-map-pin-user-fill': RiMapPinUserFill,
  'ri-map-pin-user-line': RiMapPinUserLine,
  'ri-mark-pen-fill': RiMarkPenFill,
  'ri-mark-pen-line': RiMarkPenLine,
  'ri-markdown-fill': RiMarkdownFill,
  'ri-markdown-line': RiMarkdownLine,
  'ri-markup-fill': RiMarkupFill,
  'ri-markup-line': RiMarkupLine,
  'ri-mastercard-fill': RiMastercardFill,
  'ri-mastercard-line': RiMastercardLine,
  'ri-mastodon-fill': RiMastodonFill,
  'ri-mastodon-line': RiMastodonLine,
  'ri-medal-2-fill': RiMedal2Fill,
  'ri-medal-2-line': RiMedal2Line,
  'ri-medal-fill': RiMedalFill,
  'ri-medal-line': RiMedalLine,
  'ri-medicine-bottle-fill': RiMedicineBottleFill,
  'ri-medicine-bottle-line': RiMedicineBottleLine,
  'ri-medium-fill': RiMediumFill,
  'ri-medium-line': RiMediumLine,
  'ri-men-fill': RiMenFill,
  'ri-men-line': RiMenLine,
  'ri-mental-health-fill': RiMentalHealthFill,
  'ri-mental-health-line': RiMentalHealthLine,
  'ri-menu-2-fill': RiMenu2Fill,
  'ri-menu-2-line': RiMenu2Line,
  'ri-menu-3-fill': RiMenu3Fill,
  'ri-menu-3-line': RiMenu3Line,
  'ri-menu-4-fill': RiMenu4Fill,
  'ri-menu-4-line': RiMenu4Line,
  'ri-menu-5-fill': RiMenu5Fill,
  'ri-menu-5-line': RiMenu5Line,
  'ri-menu-add-fill': RiMenuAddFill,
  'ri-menu-add-line': RiMenuAddLine,
  'ri-menu-fill': RiMenuFill,
  'ri-menu-fold-fill': RiMenuFoldFill,
  'ri-menu-fold-line': RiMenuFoldLine,
  'ri-menu-line': RiMenuLine,
  'ri-menu-unfold-fill': RiMenuUnfoldFill,
  'ri-menu-unfold-line': RiMenuUnfoldLine,
  'ri-merge-cells-horizontal': RiMergeCellsHorizontal,
  'ri-merge-cells-vertical': RiMergeCellsVertical,
  'ri-message-2-fill': RiMessage2Fill,
  'ri-message-2-line': RiMessage2Line,
  'ri-message-3-fill': RiMessage3Fill,
  'ri-message-3-line': RiMessage3Line,
  'ri-message-fill': RiMessageFill,
  'ri-message-line': RiMessageLine,
  'ri-messenger-fill': RiMessengerFill,
  'ri-messenger-line': RiMessengerLine,
  'ri-meteor-fill': RiMeteorFill,
  'ri-meteor-line': RiMeteorLine,
  'ri-mic-2-fill': RiMic2Fill,
  'ri-mic-2-line': RiMic2Line,
  'ri-mic-fill': RiMicFill,
  'ri-mic-line': RiMicLine,
  'ri-mic-off-fill': RiMicOffFill,
  'ri-mic-off-line': RiMicOffLine,
  'ri-mickey-fill': RiMickeyFill,
  'ri-mickey-line': RiMickeyLine,
  'ri-microscope-fill': RiMicroscopeFill,
  'ri-microscope-line': RiMicroscopeLine,
  'ri-microsoft-fill': RiMicrosoftFill,
  'ri-microsoft-line': RiMicrosoftLine,
  'ri-mind-map': RiMindMap,
  'ri-mini-program-fill': RiMiniProgramFill,
  'ri-mini-program-line': RiMiniProgramLine,
  'ri-mist-fill': RiMistFill,
  'ri-mist-line': RiMistLine,
  'ri-money-cny-box-fill': RiMoneyCnyBoxFill,
  'ri-money-cny-box-line': RiMoneyCnyBoxLine,
  'ri-money-cny-circle-fill': RiMoneyCnyCircleFill,
  'ri-money-cny-circle-line': RiMoneyCnyCircleLine,
  'ri-money-dollar-box-fill': RiMoneyDollarBoxFill,
  'ri-money-dollar-box-line': RiMoneyDollarBoxLine,
  'ri-money-dollar-circle-fill': RiMoneyDollarCircleFill,
  'ri-money-dollar-circle-line': RiMoneyDollarCircleLine,
  'ri-money-euro-box-fill': RiMoneyEuroBoxFill,
  'ri-money-euro-box-line': RiMoneyEuroBoxLine,
  'ri-money-euro-circle-fill': RiMoneyEuroCircleFill,
  'ri-money-euro-circle-line': RiMoneyEuroCircleLine,
  'ri-money-pound-box-fill': RiMoneyPoundBoxFill,
  'ri-money-pound-box-line': RiMoneyPoundBoxLine,
  'ri-money-pound-circle-fill': RiMoneyPoundCircleFill,
  'ri-money-pound-circle-line': RiMoneyPoundCircleLine,
  'ri-moon-clear-fill': RiMoonClearFill,
  'ri-moon-clear-line': RiMoonClearLine,
  'ri-moon-cloudy-fill': RiMoonCloudyFill,
  'ri-moon-cloudy-line': RiMoonCloudyLine,
  'ri-moon-fill': RiMoonFill,
  'ri-moon-foggy-fill': RiMoonFoggyFill,
  'ri-moon-foggy-line': RiMoonFoggyLine,
  'ri-moon-line': RiMoonLine,
  'ri-more-2-fill': RiMore2Fill,
  'ri-more-2-line': RiMore2Line,
  'ri-more-fill': RiMoreFill,
  'ri-more-line': RiMoreLine,
  'ri-motorbike-fill': RiMotorbikeFill,
  'ri-motorbike-line': RiMotorbikeLine,
  'ri-mouse-fill': RiMouseFill,
  'ri-mouse-line': RiMouseLine,
  'ri-movie-2-fill': RiMovie2Fill,
  'ri-movie-2-line': RiMovie2Line,
  'ri-movie-fill': RiMovieFill,
  'ri-movie-line': RiMovieLine,
  'ri-music-2-fill': RiMusic2Fill,
  'ri-music-2-line': RiMusic2Line,
  'ri-music-fill': RiMusicFill,
  'ri-music-line': RiMusicLine,
  'ri-mv-fill': RiMvFill,
  'ri-mv-line': RiMvLine,
  'ri-navigation-fill': RiNavigationFill,
  'ri-navigation-line': RiNavigationLine,
  'ri-netease-cloud-music-fill': RiNeteaseCloudMusicFill,
  'ri-netease-cloud-music-line': RiNeteaseCloudMusicLine,
  'ri-netflix-fill': RiNetflixFill,
  'ri-netflix-line': RiNetflixLine,
  'ri-newspaper-fill': RiNewspaperFill,
  'ri-newspaper-line': RiNewspaperLine,
  'ri-node-tree': RiNodeTree,
  'ri-notification-2-fill': RiNotification2Fill,
  'ri-notification-2-line': RiNotification2Line,
  'ri-notification-3-fill': RiNotification3Fill,
  'ri-notification-3-line': RiNotification3Line,
  'ri-notification-4-fill': RiNotification4Fill,
  'ri-notification-4-line': RiNotification4Line,
  'ri-notification-badge-fill': RiNotificationBadgeFill,
  'ri-notification-badge-line': RiNotificationBadgeLine,
  'ri-notification-fill': RiNotificationFill,
  'ri-notification-line': RiNotificationLine,
  'ri-notification-off-fill': RiNotificationOffFill,
  'ri-notification-off-line': RiNotificationOffLine,
  'ri-npmjs-fill': RiNpmjsFill,
  'ri-npmjs-line': RiNpmjsLine,
  'ri-number-0': RiNumber0,
  'ri-number-1': RiNumber1,
  'ri-number-2': RiNumber2,
  'ri-number-3': RiNumber3,
  'ri-number-4': RiNumber4,
  'ri-number-5': RiNumber5,
  'ri-number-6': RiNumber6,
  'ri-number-7': RiNumber7,
  'ri-number-8': RiNumber8,
  'ri-number-9': RiNumber9,
  'ri-numbers-fill': RiNumbersFill,
  'ri-numbers-line': RiNumbersLine,
  'ri-nurse-fill': RiNurseFill,
  'ri-nurse-line': RiNurseLine,
  'ri-oil-fill': RiOilFill,
  'ri-oil-line': RiOilLine,
  'ri-omega': RiOmega,
  'ri-open-arm-fill': RiOpenArmFill,
  'ri-open-arm-line': RiOpenArmLine,
  'ri-open-source-fill': RiOpenSourceFill,
  'ri-open-source-line': RiOpenSourceLine,
  'ri-opera-fill': RiOperaFill,
  'ri-opera-line': RiOperaLine,
  'ri-order-play-fill': RiOrderPlayFill,
  'ri-order-play-line': RiOrderPlayLine,
  'ri-organization-chart': RiOrganizationChart,
  'ri-outlet-2-fill': RiOutlet2Fill,
  'ri-outlet-2-line': RiOutlet2Line,
  'ri-outlet-fill': RiOutletFill,
  'ri-outlet-line': RiOutletLine,
  'ri-page-separator': RiPageSeparator,
  'ri-pages-fill': RiPagesFill,
  'ri-pages-line': RiPagesLine,
  'ri-paint-brush-fill': RiPaintBrushFill,
  'ri-paint-brush-line': RiPaintBrushLine,
  'ri-paint-fill': RiPaintFill,
  'ri-paint-line': RiPaintLine,
  'ri-palette-fill': RiPaletteFill,
  'ri-palette-line': RiPaletteLine,
  'ri-pantone-fill': RiPantoneFill,
  'ri-pantone-line': RiPantoneLine,
  'ri-paragraph': RiParagraph,
  'ri-parent-fill': RiParentFill,
  'ri-parent-line': RiParentLine,
  'ri-parentheses-fill': RiParenthesesFill,
  'ri-parentheses-line': RiParenthesesLine,
  'ri-parking-box-fill': RiParkingBoxFill,
  'ri-parking-box-line': RiParkingBoxLine,
  'ri-parking-fill': RiParkingFill,
  'ri-parking-line': RiParkingLine,
  'ri-passport-fill': RiPassportFill,
  'ri-passport-line': RiPassportLine,
  'ri-patreon-fill': RiPatreonFill,
  'ri-patreon-line': RiPatreonLine,
  'ri-pause-circle-fill': RiPauseCircleFill,
  'ri-pause-circle-line': RiPauseCircleLine,
  'ri-pause-fill': RiPauseFill,
  'ri-pause-line': RiPauseLine,
  'ri-pause-mini-fill': RiPauseMiniFill,
  'ri-pause-mini-line': RiPauseMiniLine,
  'ri-paypal-fill': RiPaypalFill,
  'ri-paypal-line': RiPaypalLine,
  'ri-pen-nib-fill': RiPenNibFill,
  'ri-pen-nib-line': RiPenNibLine,
  'ri-pencil-fill': RiPencilFill,
  'ri-pencil-line': RiPencilLine,
  'ri-pencil-ruler-2-fill': RiPencilRuler2Fill,
  'ri-pencil-ruler-2-line': RiPencilRuler2Line,
  'ri-pencil-ruler-fill': RiPencilRulerFill,
  'ri-pencil-ruler-line': RiPencilRulerLine,
  'ri-percent-fill': RiPercentFill,
  'ri-percent-line': RiPercentLine,
  'ri-phone-camera-fill': RiPhoneCameraFill,
  'ri-phone-camera-line': RiPhoneCameraLine,
  'ri-phone-fill': RiPhoneFill,
  'ri-phone-find-fill': RiPhoneFindFill,
  'ri-phone-find-line': RiPhoneFindLine,
  'ri-phone-line': RiPhoneLine,
  'ri-phone-lock-fill': RiPhoneLockFill,
  'ri-phone-lock-line': RiPhoneLockLine,
  'ri-picture-in-picture-2-fill': RiPictureInPicture2Fill,
  'ri-picture-in-picture-2-line': RiPictureInPicture2Line,
  'ri-picture-in-picture-exit-fill': RiPictureInPictureExitFill,
  'ri-picture-in-picture-exit-line': RiPictureInPictureExitLine,
  'ri-picture-in-picture-fill': RiPictureInPictureFill,
  'ri-picture-in-picture-line': RiPictureInPictureLine,
  'ri-pie-chart-2-fill': RiPieChart2Fill,
  'ri-pie-chart-2-line': RiPieChart2Line,
  'ri-pie-chart-box-fill': RiPieChartBoxFill,
  'ri-pie-chart-box-line': RiPieChartBoxLine,
  'ri-pie-chart-fill': RiPieChartFill,
  'ri-pie-chart-line': RiPieChartLine,
  'ri-pin-distance-fill': RiPinDistanceFill,
  'ri-pin-distance-line': RiPinDistanceLine,
  'ri-ping-pong-fill': RiPingPongFill,
  'ri-ping-pong-line': RiPingPongLine,
  'ri-pinterest-fill': RiPinterestFill,
  'ri-pinterest-line': RiPinterestLine,
  'ri-pinyin-input': RiPinyinInput,
  'ri-pixelfed-fill': RiPixelfedFill,
  'ri-pixelfed-line': RiPixelfedLine,
  'ri-plane-fill': RiPlaneFill,
  'ri-plane-line': RiPlaneLine,
  'ri-plant-fill': RiPlantFill,
  'ri-plant-line': RiPlantLine,
  'ri-play-circle-fill': RiPlayCircleFill,
  'ri-play-circle-line': RiPlayCircleLine,
  'ri-play-fill': RiPlayFill,
  'ri-play-line': RiPlayLine,
  'ri-play-list-2-fill': RiPlayList2Fill,
  'ri-play-list-2-line': RiPlayList2Line,
  'ri-play-list-add-fill': RiPlayListAddFill,
  'ri-play-list-add-line': RiPlayListAddLine,
  'ri-play-list-fill': RiPlayListFill,
  'ri-play-list-line': RiPlayListLine,
  'ri-play-mini-fill': RiPlayMiniFill,
  'ri-play-mini-line': RiPlayMiniLine,
  'ri-playstation-fill': RiPlaystationFill,
  'ri-playstation-line': RiPlaystationLine,
  'ri-plug-2-fill': RiPlug2Fill,
  'ri-plug-2-line': RiPlug2Line,
  'ri-plug-fill': RiPlugFill,
  'ri-plug-line': RiPlugLine,
  'ri-polaroid-2-fill': RiPolaroid2Fill,
  'ri-polaroid-2-line': RiPolaroid2Line,
  'ri-polaroid-fill': RiPolaroidFill,
  'ri-polaroid-line': RiPolaroidLine,
  'ri-police-car-fill': RiPoliceCarFill,
  'ri-police-car-line': RiPoliceCarLine,
  'ri-price-tag-2-fill': RiPriceTag2Fill,
  'ri-price-tag-2-line': RiPriceTag2Line,
  'ri-price-tag-3-fill': RiPriceTag3Fill,
  'ri-price-tag-3-line': RiPriceTag3Line,
  'ri-price-tag-fill': RiPriceTagFill,
  'ri-price-tag-line': RiPriceTagLine,
  'ri-printer-cloud-fill': RiPrinterCloudFill,
  'ri-printer-cloud-line': RiPrinterCloudLine,
  'ri-printer-fill': RiPrinterFill,
  'ri-printer-line': RiPrinterLine,
  'ri-product-hunt-fill': RiProductHuntFill,
  'ri-product-hunt-line': RiProductHuntLine,
  'ri-profile-fill': RiProfileFill,
  'ri-profile-line': RiProfileLine,
  'ri-projector-2-fill': RiProjector2Fill,
  'ri-projector-2-line': RiProjector2Line,
  'ri-projector-fill': RiProjectorFill,
  'ri-projector-line': RiProjectorLine,
  'ri-psychotherapy-fill': RiPsychotherapyFill,
  'ri-psychotherapy-line': RiPsychotherapyLine,
  'ri-pulse-fill': RiPulseFill,
  'ri-pulse-line': RiPulseLine,
  'ri-pushpin-2-fill': RiPushpin2Fill,
  'ri-pushpin-2-line': RiPushpin2Line,
  'ri-pushpin-fill': RiPushpinFill,
  'ri-pushpin-line': RiPushpinLine,
  'ri-qq-fill': RiQqFill,
  'ri-qq-line': RiQqLine,
  'ri-qr-code-fill': RiQrCodeFill,
  'ri-qr-code-line': RiQrCodeLine,
  'ri-qr-scan-2-fill': RiQrScan2Fill,
  'ri-qr-scan-2-line': RiQrScan2Line,
  'ri-qr-scan-fill': RiQrScanFill,
  'ri-qr-scan-line': RiQrScanLine,
  'ri-question-answer-fill': RiQuestionAnswerFill,
  'ri-question-answer-line': RiQuestionAnswerLine,
  'ri-question-fill': RiQuestionFill,
  'ri-question-line': RiQuestionLine,
  'ri-question-mark': RiQuestionMark,
  'ri-questionnaire-fill': RiQuestionnaireFill,
  'ri-questionnaire-line': RiQuestionnaireLine,
  'ri-quill-pen-fill': RiQuillPenFill,
  'ri-quill-pen-line': RiQuillPenLine,
  'ri-radar-fill': RiRadarFill,
  'ri-radar-line': RiRadarLine,
  'ri-radio-2-fill': RiRadio2Fill,
  'ri-radio-2-line': RiRadio2Line,
  'ri-radio-button-fill': RiRadioButtonFill,
  'ri-radio-button-line': RiRadioButtonLine,
  'ri-radio-fill': RiRadioFill,
  'ri-radio-line': RiRadioLine,
  'ri-rainbow-fill': RiRainbowFill,
  'ri-rainbow-line': RiRainbowLine,
  'ri-rainy-fill': RiRainyFill,
  'ri-rainy-line': RiRainyLine,
  'ri-reactjs-fill': RiReactjsFill,
  'ri-reactjs-line': RiReactjsLine,
  'ri-record-circle-fill': RiRecordCircleFill,
  'ri-record-circle-line': RiRecordCircleLine,
  'ri-record-mail-fill': RiRecordMailFill,
  'ri-record-mail-line': RiRecordMailLine,
  'ri-recycle-fill': RiRecycleFill,
  'ri-recycle-line': RiRecycleLine,
  'ri-red-packet-fill': RiRedPacketFill,
  'ri-red-packet-line': RiRedPacketLine,
  'ri-reddit-fill': RiRedditFill,
  'ri-reddit-line': RiRedditLine,
  'ri-refresh-fill': RiRefreshFill,
  'ri-refresh-line': RiRefreshLine,
  'ri-refund-2-fill': RiRefund2Fill,
  'ri-refund-2-line': RiRefund2Line,
  'ri-refund-fill': RiRefundFill,
  'ri-refund-line': RiRefundLine,
  'ri-registered-fill': RiRegisteredFill,
  'ri-registered-line': RiRegisteredLine,
  'ri-remixicon-fill': RiRemixiconFill,
  'ri-remixicon-line': RiRemixiconLine,
  'ri-remote-control-2-fill': RiRemoteControl2Fill,
  'ri-remote-control-2-line': RiRemoteControl2Line,
  'ri-remote-control-fill': RiRemoteControlFill,
  'ri-remote-control-line': RiRemoteControlLine,
  'ri-repeat-2-fill': RiRepeat2Fill,
  'ri-repeat-2-line': RiRepeat2Line,
  'ri-repeat-fill': RiRepeatFill,
  'ri-repeat-line': RiRepeatLine,
  'ri-repeat-one-fill': RiRepeatOneFill,
  'ri-repeat-one-line': RiRepeatOneLine,
  'ri-reply-all-fill': RiReplyAllFill,
  'ri-reply-all-line': RiReplyAllLine,
  'ri-reply-fill': RiReplyFill,
  'ri-reply-line': RiReplyLine,
  'ri-reserved-fill': RiReservedFill,
  'ri-reserved-line': RiReservedLine,
  'ri-rest-time-fill': RiRestTimeFill,
  'ri-rest-time-line': RiRestTimeLine,
  'ri-restart-fill': RiRestartFill,
  'ri-restart-line': RiRestartLine,
  'ri-restaurant-2-fill': RiRestaurant2Fill,
  'ri-restaurant-2-line': RiRestaurant2Line,
  'ri-restaurant-fill': RiRestaurantFill,
  'ri-restaurant-line': RiRestaurantLine,
  'ri-rewind-fill': RiRewindFill,
  'ri-rewind-line': RiRewindLine,
  'ri-rewind-mini-fill': RiRewindMiniFill,
  'ri-rewind-mini-line': RiRewindMiniLine,
  'ri-rhythm-fill': RiRhythmFill,
  'ri-rhythm-line': RiRhythmLine,
  'ri-riding-fill': RiRidingFill,
  'ri-riding-line': RiRidingLine,
  'ri-road-map-fill': RiRoadMapFill,
  'ri-road-map-line': RiRoadMapLine,
  'ri-roadster-fill': RiRoadsterFill,
  'ri-roadster-line': RiRoadsterLine,
  'ri-robot-fill': RiRobotFill,
  'ri-robot-line': RiRobotLine,
  'ri-rocket-2-fill': RiRocket2Fill,
  'ri-rocket-2-line': RiRocket2Line,
  'ri-rocket-fill': RiRocketFill,
  'ri-rocket-line': RiRocketLine,
  'ri-rotate-lock-fill': RiRotateLockFill,
  'ri-rotate-lock-line': RiRotateLockLine,
  'ri-rounded-corner': RiRoundedCorner,
  'ri-route-fill': RiRouteFill,
  'ri-route-line': RiRouteLine,
  'ri-router-fill': RiRouterFill,
  'ri-router-line': RiRouterLine,
  'ri-rss-fill': RiRssFill,
  'ri-rss-line': RiRssLine,
  'ri-ruler-2-fill': RiRuler2Fill,
  'ri-ruler-2-line': RiRuler2Line,
  'ri-ruler-fill': RiRulerFill,
  'ri-ruler-line': RiRulerLine,
  'ri-run-fill': RiRunFill,
  'ri-run-line': RiRunLine,
  'ri-safari-fill': RiSafariFill,
  'ri-safari-line': RiSafariLine,
  'ri-safe-2-fill': RiSafe2Fill,
  'ri-safe-2-line': RiSafe2Line,
  'ri-safe-fill': RiSafeFill,
  'ri-safe-line': RiSafeLine,
  'ri-sailboat-fill': RiSailboatFill,
  'ri-sailboat-line': RiSailboatLine,
  'ri-save-2-fill': RiSave2Fill,
  'ri-save-2-line': RiSave2Line,
  'ri-save-3-fill': RiSave3Fill,
  'ri-save-3-line': RiSave3Line,
  'ri-save-fill': RiSaveFill,
  'ri-save-line': RiSaveLine,
  'ri-scales-2-fill': RiScales2Fill,
  'ri-scales-2-line': RiScales2Line,
  'ri-scales-3-fill': RiScales3Fill,
  'ri-scales-3-line': RiScales3Line,
  'ri-scales-fill': RiScalesFill,
  'ri-scales-line': RiScalesLine,
  'ri-scan-2-fill': RiScan2Fill,
  'ri-scan-2-line': RiScan2Line,
  'ri-scan-fill': RiScanFill,
  'ri-scan-line': RiScanLine,
  'ri-scissors-2-fill': RiScissors2Fill,
  'ri-scissors-2-line': RiScissors2Line,
  'ri-scissors-cut-fill': RiScissorsCutFill,
  'ri-scissors-cut-line': RiScissorsCutLine,
  'ri-scissors-fill': RiScissorsFill,
  'ri-scissors-line': RiScissorsLine,
  'ri-screenshot-2-fill': RiScreenshot2Fill,
  'ri-screenshot-2-line': RiScreenshot2Line,
  'ri-screenshot-fill': RiScreenshotFill,
  'ri-screenshot-line': RiScreenshotLine,
  'ri-sd-card-fill': RiSdCardFill,
  'ri-sd-card-line': RiSdCardLine,
  'ri-sd-card-mini-fill': RiSdCardMiniFill,
  'ri-sd-card-mini-line': RiSdCardMiniLine,
  'ri-search-2-fill': RiSearch2Fill,
  'ri-search-2-line': RiSearch2Line,
  'ri-search-eye-fill': RiSearchEyeFill,
  'ri-search-eye-line': RiSearchEyeLine,
  'ri-search-fill': RiSearchFill,
  'ri-search-line': RiSearchLine,
  'ri-secure-payment-fill': RiSecurePaymentFill,
  'ri-secure-payment-line': RiSecurePaymentLine,
  'ri-seedling-fill': RiSeedlingFill,
  'ri-seedling-line': RiSeedlingLine,
  'ri-send-backward': RiSendBackward,
  'ri-send-plane-2-fill': RiSendPlane2Fill,
  'ri-send-plane-2-line': RiSendPlane2Line,
  'ri-send-plane-fill': RiSendPlaneFill,
  'ri-send-plane-line': RiSendPlaneLine,
  'ri-send-to-back': RiSendToBack,
  'ri-sensor-fill': RiSensorFill,
  'ri-sensor-line': RiSensorLine,
  'ri-separator': RiSeparator,
  'ri-server-fill': RiServerFill,
  'ri-server-line': RiServerLine,
  'ri-service-fill': RiServiceFill,
  'ri-service-line': RiServiceLine,
  'ri-settings-2-fill': RiSettings2Fill,
  'ri-settings-2-line': RiSettings2Line,
  'ri-settings-3-fill': RiSettings3Fill,
  'ri-settings-3-line': RiSettings3Line,
  'ri-settings-4-fill': RiSettings4Fill,
  'ri-settings-4-line': RiSettings4Line,
  'ri-settings-5-fill': RiSettings5Fill,
  'ri-settings-5-line': RiSettings5Line,
  'ri-settings-6-fill': RiSettings6Fill,
  'ri-settings-6-line': RiSettings6Line,
  'ri-settings-fill': RiSettingsFill,
  'ri-settings-line': RiSettingsLine,
  'ri-shape-2-fill': RiShape2Fill,
  'ri-shape-2-line': RiShape2Line,
  'ri-shape-fill': RiShapeFill,
  'ri-shape-line': RiShapeLine,
  'ri-share-box-fill': RiShareBoxFill,
  'ri-share-box-line': RiShareBoxLine,
  'ri-share-circle-fill': RiShareCircleFill,
  'ri-share-circle-line': RiShareCircleLine,
  'ri-share-fill': RiShareFill,
  'ri-share-forward-2-fill': RiShareForward2Fill,
  'ri-share-forward-2-line': RiShareForward2Line,
  'ri-share-forward-box-fill': RiShareForwardBoxFill,
  'ri-share-forward-box-line': RiShareForwardBoxLine,
  'ri-share-forward-fill': RiShareForwardFill,
  'ri-share-forward-line': RiShareForwardLine,
  'ri-share-line': RiShareLine,
  'ri-shield-check-fill': RiShieldCheckFill,
  'ri-shield-check-line': RiShieldCheckLine,
  'ri-shield-cross-fill': RiShieldCrossFill,
  'ri-shield-cross-line': RiShieldCrossLine,
  'ri-shield-fill': RiShieldFill,
  'ri-shield-flash-fill': RiShieldFlashFill,
  'ri-shield-flash-line': RiShieldFlashLine,
  'ri-shield-keyhole-fill': RiShieldKeyholeFill,
  'ri-shield-keyhole-line': RiShieldKeyholeLine,
  'ri-shield-line': RiShieldLine,
  'ri-shield-star-fill': RiShieldStarFill,
  'ri-shield-star-line': RiShieldStarLine,
  'ri-shield-user-fill': RiShieldUserFill,
  'ri-shield-user-line': RiShieldUserLine,
  'ri-ship-2-fill': RiShip2Fill,
  'ri-ship-2-line': RiShip2Line,
  'ri-ship-fill': RiShipFill,
  'ri-ship-line': RiShipLine,
  'ri-shirt-fill': RiShirtFill,
  'ri-shirt-line': RiShirtLine,
  'ri-shopping-bag-2-fill': RiShoppingBag2Fill,
  'ri-shopping-bag-2-line': RiShoppingBag2Line,
  'ri-shopping-bag-3-fill': RiShoppingBag3Fill,
  'ri-shopping-bag-3-line': RiShoppingBag3Line,
  'ri-shopping-bag-fill': RiShoppingBagFill,
  'ri-shopping-bag-line': RiShoppingBagLine,
  'ri-shopping-basket-2-fill': RiShoppingBasket2Fill,
  'ri-shopping-basket-2-line': RiShoppingBasket2Line,
  'ri-shopping-basket-fill': RiShoppingBasketFill,
  'ri-shopping-basket-line': RiShoppingBasketLine,
  'ri-shopping-cart-2-fill': RiShoppingCart2Fill,
  'ri-shopping-cart-2-line': RiShoppingCart2Line,
  'ri-shopping-cart-fill': RiShoppingCartFill,
  'ri-shopping-cart-line': RiShoppingCartLine,
  'ri-showers-fill': RiShowersFill,
  'ri-showers-line': RiShowersLine,
  'ri-shuffle-fill': RiShuffleFill,
  'ri-shuffle-line': RiShuffleLine,
  'ri-shut-down-fill': RiShutDownFill,
  'ri-shut-down-line': RiShutDownLine,
  'ri-side-bar-fill': RiSideBarFill,
  'ri-side-bar-line': RiSideBarLine,
  'ri-signal-tower-fill': RiSignalTowerFill,
  'ri-signal-tower-line': RiSignalTowerLine,
  'ri-signal-wifi-1-fill': RiSignalWifi1Fill,
  'ri-signal-wifi-1-line': RiSignalWifi1Line,
  'ri-signal-wifi-2-fill': RiSignalWifi2Fill,
  'ri-signal-wifi-2-line': RiSignalWifi2Line,
  'ri-signal-wifi-3-fill': RiSignalWifi3Fill,
  'ri-signal-wifi-3-line': RiSignalWifi3Line,
  'ri-signal-wifi-error-fill': RiSignalWifiErrorFill,
  'ri-signal-wifi-error-line': RiSignalWifiErrorLine,
  'ri-signal-wifi-fill': RiSignalWifiFill,
  'ri-signal-wifi-line': RiSignalWifiLine,
  'ri-signal-wifi-off-fill': RiSignalWifiOffFill,
  'ri-signal-wifi-off-line': RiSignalWifiOffLine,
  'ri-sim-card-2-fill': RiSimCard2Fill,
  'ri-sim-card-2-line': RiSimCard2Line,
  'ri-sim-card-fill': RiSimCardFill,
  'ri-sim-card-line': RiSimCardLine,
  'ri-single-quotes-l': RiSingleQuotesL,
  'ri-single-quotes-r': RiSingleQuotesR,
  'ri-sip-fill': RiSipFill,
  'ri-sip-line': RiSipLine,
  'ri-skip-back-fill': RiSkipBackFill,
  'ri-skip-back-line': RiSkipBackLine,
  'ri-skip-back-mini-fill': RiSkipBackMiniFill,
  'ri-skip-back-mini-line': RiSkipBackMiniLine,
  'ri-skip-forward-fill': RiSkipForwardFill,
  'ri-skip-forward-line': RiSkipForwardLine,
  'ri-skip-forward-mini-fill': RiSkipForwardMiniFill,
  'ri-skip-forward-mini-line': RiSkipForwardMiniLine,
  'ri-skull-2-fill': RiSkull2Fill,
  'ri-skull-2-line': RiSkull2Line,
  'ri-skull-fill': RiSkullFill,
  'ri-skull-line': RiSkullLine,
  'ri-skype-fill': RiSkypeFill,
  'ri-skype-line': RiSkypeLine,
  'ri-slack-fill': RiSlackFill,
  'ri-slack-line': RiSlackLine,
  'ri-slice-fill': RiSliceFill,
  'ri-slice-line': RiSliceLine,
  'ri-slideshow-2-fill': RiSlideshow2Fill,
  'ri-slideshow-2-line': RiSlideshow2Line,
  'ri-slideshow-3-fill': RiSlideshow3Fill,
  'ri-slideshow-3-line': RiSlideshow3Line,
  'ri-slideshow-4-fill': RiSlideshow4Fill,
  'ri-slideshow-4-line': RiSlideshow4Line,
  'ri-slideshow-fill': RiSlideshowFill,
  'ri-slideshow-line': RiSlideshowLine,
  'ri-smartphone-fill': RiSmartphoneFill,
  'ri-smartphone-line': RiSmartphoneLine,
  'ri-snapchat-fill': RiSnapchatFill,
  'ri-snapchat-line': RiSnapchatLine,
  'ri-snowy-fill': RiSnowyFill,
  'ri-snowy-line': RiSnowyLine,
  'ri-sort-asc': RiSortAsc,
  'ri-sort-desc': RiSortDesc,
  'ri-sound-module-fill': RiSoundModuleFill,
  'ri-sound-module-line': RiSoundModuleLine,
  'ri-soundcloud-fill': RiSoundcloudFill,
  'ri-soundcloud-line': RiSoundcloudLine,
  'ri-space-ship-fill': RiSpaceShipFill,
  'ri-space-ship-line': RiSpaceShipLine,
  'ri-space': RiSpace,
  'ri-spam-2-fill': RiSpam2Fill,
  'ri-spam-2-line': RiSpam2Line,
  'ri-spam-3-fill': RiSpam3Fill,
  'ri-spam-3-line': RiSpam3Line,
  'ri-spam-fill': RiSpamFill,
  'ri-spam-line': RiSpamLine,
  'ri-speaker-2-fill': RiSpeaker2Fill,
  'ri-speaker-2-line': RiSpeaker2Line,
  'ri-speaker-3-fill': RiSpeaker3Fill,
  'ri-speaker-3-line': RiSpeaker3Line,
  'ri-speaker-fill': RiSpeakerFill,
  'ri-speaker-line': RiSpeakerLine,
  'ri-spectrum-fill': RiSpectrumFill,
  'ri-spectrum-line': RiSpectrumLine,
  'ri-speed-fill': RiSpeedFill,
  'ri-speed-line': RiSpeedLine,
  'ri-speed-mini-fill': RiSpeedMiniFill,
  'ri-speed-mini-line': RiSpeedMiniLine,
  'ri-split-cells-horizontal': RiSplitCellsHorizontal,
  'ri-split-cells-vertical': RiSplitCellsVertical,
  'ri-spotify-fill': RiSpotifyFill,
  'ri-spotify-line': RiSpotifyLine,
  'ri-spy-fill': RiSpyFill,
  'ri-spy-line': RiSpyLine,
  'ri-stack-fill': RiStackFill,
  'ri-stack-line': RiStackLine,
  'ri-stack-overflow-fill': RiStackOverflowFill,
  'ri-stack-overflow-line': RiStackOverflowLine,
  'ri-stackshare-fill': RiStackshareFill,
  'ri-stackshare-line': RiStackshareLine,
  'ri-star-fill': RiStarFill,
  'ri-star-half-fill': RiStarHalfFill,
  'ri-star-half-line': RiStarHalfLine,
  'ri-star-half-s-fill': RiStarHalfSFill,
  'ri-star-half-s-line': RiStarHalfSLine,
  'ri-star-line': RiStarLine,
  'ri-star-s-fill': RiStarSFill,
  'ri-star-s-line': RiStarSLine,
  'ri-star-smile-fill': RiStarSmileFill,
  'ri-star-smile-line': RiStarSmileLine,
  'ri-steam-fill': RiSteamFill,
  'ri-steam-line': RiSteamLine,
  'ri-steering-2-fill': RiSteering2Fill,
  'ri-steering-2-line': RiSteering2Line,
  'ri-steering-fill': RiSteeringFill,
  'ri-steering-line': RiSteeringLine,
  'ri-stethoscope-fill': RiStethoscopeFill,
  'ri-stethoscope-line': RiStethoscopeLine,
  'ri-sticky-note-2-fill': RiStickyNote2Fill,
  'ri-sticky-note-2-line': RiStickyNote2Line,
  'ri-sticky-note-fill': RiStickyNoteFill,
  'ri-sticky-note-line': RiStickyNoteLine,
  'ri-stock-fill': RiStockFill,
  'ri-stock-line': RiStockLine,
  'ri-stop-circle-fill': RiStopCircleFill,
  'ri-stop-circle-line': RiStopCircleLine,
  'ri-stop-fill': RiStopFill,
  'ri-stop-line': RiStopLine,
  'ri-stop-mini-fill': RiStopMiniFill,
  'ri-stop-mini-line': RiStopMiniLine,
  'ri-store-2-fill': RiStore2Fill,
  'ri-store-2-line': RiStore2Line,
  'ri-store-3-fill': RiStore3Fill,
  'ri-store-3-line': RiStore3Line,
  'ri-store-fill': RiStoreFill,
  'ri-store-line': RiStoreLine,
  'ri-strikethrough-2': RiStrikethrough2,
  'ri-strikethrough': RiStrikethrough,
  'ri-subscript-2': RiSubscript2,
  'ri-subscript': RiSubscript,
  'ri-subtract-fill': RiSubtractFill,
  'ri-subtract-line': RiSubtractLine,
  'ri-subway-fill': RiSubwayFill,
  'ri-subway-line': RiSubwayLine,
  'ri-subway-wifi-fill': RiSubwayWifiFill,
  'ri-subway-wifi-line': RiSubwayWifiLine,
  'ri-suitcase-2-fill': RiSuitcase2Fill,
  'ri-suitcase-2-line': RiSuitcase2Line,
  'ri-suitcase-3-fill': RiSuitcase3Fill,
  'ri-suitcase-3-line': RiSuitcase3Line,
  'ri-suitcase-fill': RiSuitcaseFill,
  'ri-suitcase-line': RiSuitcaseLine,
  'ri-sun-cloudy-fill': RiSunCloudyFill,
  'ri-sun-cloudy-line': RiSunCloudyLine,
  'ri-sun-fill': RiSunFill,
  'ri-sun-foggy-fill': RiSunFoggyFill,
  'ri-sun-foggy-line': RiSunFoggyLine,
  'ri-sun-line': RiSunLine,
  'ri-superscript-2': RiSuperscript2,
  'ri-superscript': RiSuperscript,
  'ri-surgical-mask-fill': RiSurgicalMaskFill,
  'ri-surgical-mask-line': RiSurgicalMaskLine,
  'ri-surround-sound-fill': RiSurroundSoundFill,
  'ri-surround-sound-line': RiSurroundSoundLine,
  'ri-survey-fill': RiSurveyFill,
  'ri-survey-line': RiSurveyLine,
  'ri-swap-box-fill': RiSwapBoxFill,
  'ri-swap-box-line': RiSwapBoxLine,
  'ri-swap-fill': RiSwapFill,
  'ri-swap-line': RiSwapLine,
  'ri-switch-fill': RiSwitchFill,
  'ri-switch-line': RiSwitchLine,
  'ri-sword-fill': RiSwordFill,
  'ri-sword-line': RiSwordLine,
  'ri-syringe-fill': RiSyringeFill,
  'ri-syringe-line': RiSyringeLine,
  'ri-t-box-fill': RiTBoxFill,
  'ri-t-box-line': RiTBoxLine,
  'ri-t-shirt-2-fill': RiTShirt2Fill,
  'ri-t-shirt-2-line': RiTShirt2Line,
  'ri-t-shirt-air-fill': RiTShirtAirFill,
  'ri-t-shirt-air-line': RiTShirtAirLine,
  'ri-t-shirt-fill': RiTShirtFill,
  'ri-t-shirt-line': RiTShirtLine,
  'ri-table-2': RiTable2,
  'ri-table-alt-fill': RiTableAltFill,
  'ri-table-alt-line': RiTableAltLine,
  'ri-table-fill': RiTableFill,
  'ri-table-line': RiTableLine,
  'ri-tablet-fill': RiTabletFill,
  'ri-tablet-line': RiTabletLine,
  'ri-takeaway-fill': RiTakeawayFill,
  'ri-takeaway-line': RiTakeawayLine,
  'ri-taobao-fill': RiTaobaoFill,
  'ri-taobao-line': RiTaobaoLine,
  'ri-tape-fill': RiTapeFill,
  'ri-tape-line': RiTapeLine,
  'ri-task-fill': RiTaskFill,
  'ri-task-line': RiTaskLine,
  'ri-taxi-fill': RiTaxiFill,
  'ri-taxi-line': RiTaxiLine,
  'ri-taxi-wifi-fill': RiTaxiWifiFill,
  'ri-taxi-wifi-line': RiTaxiWifiLine,
  'ri-team-fill': RiTeamFill,
  'ri-team-line': RiTeamLine,
  'ri-telegram-fill': RiTelegramFill,
  'ri-telegram-line': RiTelegramLine,
  'ri-temp-cold-fill': RiTempColdFill,
  'ri-temp-cold-line': RiTempColdLine,
  'ri-temp-hot-fill': RiTempHotFill,
  'ri-temp-hot-line': RiTempHotLine,
  'ri-terminal-box-fill': RiTerminalBoxFill,
  'ri-terminal-box-line': RiTerminalBoxLine,
  'ri-terminal-fill': RiTerminalFill,
  'ri-terminal-line': RiTerminalLine,
  'ri-terminal-window-fill': RiTerminalWindowFill,
  'ri-terminal-window-line': RiTerminalWindowLine,
  'ri-test-tube-fill': RiTestTubeFill,
  'ri-test-tube-line': RiTestTubeLine,
  'ri-text-direction-l': RiTextDirectionL,
  'ri-text-direction-r': RiTextDirectionR,
  'ri-text-spacing': RiTextSpacing,
  'ri-text-wrap': RiTextWrap,
  'ri-text': RiText,
  'ri-thermometer-fill': RiThermometerFill,
  'ri-thermometer-line': RiThermometerLine,
  'ri-thumb-down-fill': RiThumbDownFill,
  'ri-thumb-down-line': RiThumbDownLine,
  'ri-thumb-up-fill': RiThumbUpFill,
  'ri-thumb-up-line': RiThumbUpLine,
  'ri-thunderstorms-fill': RiThunderstormsFill,
  'ri-thunderstorms-line': RiThunderstormsLine,
  'ri-ticket-2-fill': RiTicket2Fill,
  'ri-ticket-2-line': RiTicket2Line,
  'ri-ticket-fill': RiTicketFill,
  'ri-ticket-line': RiTicketLine,
  'ri-time-fill': RiTimeFill,
  'ri-time-line': RiTimeLine,
  'ri-timer-2-fill': RiTimer2Fill,
  'ri-timer-2-line': RiTimer2Line,
  'ri-timer-fill': RiTimerFill,
  'ri-timer-flash-fill': RiTimerFlashFill,
  'ri-timer-flash-line': RiTimerFlashLine,
  'ri-timer-line': RiTimerLine,
  'ri-todo-fill': RiTodoFill,
  'ri-todo-line': RiTodoLine,
  'ri-toggle-fill': RiToggleFill,
  'ri-toggle-line': RiToggleLine,
  'ri-tools-fill': RiToolsFill,
  'ri-tools-line': RiToolsLine,
  'ri-tornado-fill': RiTornadoFill,
  'ri-tornado-line': RiTornadoLine,
  'ri-trademark-fill': RiTrademarkFill,
  'ri-trademark-line': RiTrademarkLine,
  'ri-traffic-light-fill': RiTrafficLightFill,
  'ri-traffic-light-line': RiTrafficLightLine,
  'ri-train-fill': RiTrainFill,
  'ri-train-line': RiTrainLine,
  'ri-train-wifi-fill': RiTrainWifiFill,
  'ri-train-wifi-line': RiTrainWifiLine,
  'ri-translate-2': RiTranslate2,
  'ri-translate': RiTranslate,
  'ri-travesti-fill': RiTravestiFill,
  'ri-travesti-line': RiTravestiLine,
  'ri-treasure-map-fill': RiTreasureMapFill,
  'ri-treasure-map-line': RiTreasureMapLine,
  'ri-trello-fill': RiTrelloFill,
  'ri-trello-line': RiTrelloLine,
  'ri-trophy-fill': RiTrophyFill,
  'ri-trophy-line': RiTrophyLine,
  'ri-truck-fill': RiTruckFill,
  'ri-truck-line': RiTruckLine,
  'ri-tumblr-fill': RiTumblrFill,
  'ri-tumblr-line': RiTumblrLine,
  'ri-tv-2-fill': RiTv2Fill,
  'ri-tv-2-line': RiTv2Line,
  'ri-tv-fill': RiTvFill,
  'ri-tv-line': RiTvLine,
  'ri-twitch-fill': RiTwitchFill,
  'ri-twitch-line': RiTwitchLine,
  'ri-twitter-fill': RiTwitterFill,
  'ri-twitter-line': RiTwitterLine,
  'ri-typhoon-fill': RiTyphoonFill,
  'ri-typhoon-line': RiTyphoonLine,
  'ri-u-disk-fill': RiUDiskFill,
  'ri-u-disk-line': RiUDiskLine,
  'ri-ubuntu-fill': RiUbuntuFill,
  'ri-ubuntu-line': RiUbuntuLine,
  'ri-umbrella-fill': RiUmbrellaFill,
  'ri-umbrella-line': RiUmbrellaLine,
  'ri-underline': RiUnderline,
  'ri-uninstall-fill': RiUninstallFill,
  'ri-uninstall-line': RiUninstallLine,
  'ri-unsplash-fill': RiUnsplashFill,
  'ri-unsplash-line': RiUnsplashLine,
  'ri-upload-2-fill': RiUpload2Fill,
  'ri-upload-2-line': RiUpload2Line,
  'ri-upload-cloud-2-fill': RiUploadCloud2Fill,
  'ri-upload-cloud-2-line': RiUploadCloud2Line,
  'ri-upload-cloud-fill': RiUploadCloudFill,
  'ri-upload-cloud-line': RiUploadCloudLine,
  'ri-upload-fill': RiUploadFill,
  'ri-upload-line': RiUploadLine,
  'ri-usb-fill': RiUsbFill,
  'ri-usb-line': RiUsbLine,
  'ri-user-2-fill': RiUser2Fill,
  'ri-user-2-line': RiUser2Line,
  'ri-user-3-fill': RiUser3Fill,
  'ri-user-3-line': RiUser3Line,
  'ri-user-4-fill': RiUser4Fill,
  'ri-user-4-line': RiUser4Line,
  'ri-user-5-fill': RiUser5Fill,
  'ri-user-5-line': RiUser5Line,
  'ri-user-6-fill': RiUser6Fill,
  'ri-user-6-line': RiUser6Line,
  'ri-user-add-fill': RiUserAddFill,
  'ri-user-add-line': RiUserAddLine,
  'ri-user-fill': RiUserFill,
  'ri-user-follow-fill': RiUserFollowFill,
  'ri-user-follow-line': RiUserFollowLine,
  'ri-user-heart-fill': RiUserHeartFill,
  'ri-user-heart-line': RiUserHeartLine,
  'ri-user-line': RiUserLine,
  'ri-user-location-fill': RiUserLocationFill,
  'ri-user-location-line': RiUserLocationLine,
  'ri-user-received-2-fill': RiUserReceived2Fill,
  'ri-user-received-2-line': RiUserReceived2Line,
  'ri-user-received-fill': RiUserReceivedFill,
  'ri-user-received-line': RiUserReceivedLine,
  'ri-user-search-fill': RiUserSearchFill,
  'ri-user-search-line': RiUserSearchLine,
  'ri-user-settings-fill': RiUserSettingsFill,
  'ri-user-settings-line': RiUserSettingsLine,
  'ri-user-shared-2-fill': RiUserShared2Fill,
  'ri-user-shared-2-line': RiUserShared2Line,
  'ri-user-shared-fill': RiUserSharedFill,
  'ri-user-shared-line': RiUserSharedLine,
  'ri-user-smile-fill': RiUserSmileFill,
  'ri-user-smile-line': RiUserSmileLine,
  'ri-user-star-fill': RiUserStarFill,
  'ri-user-star-line': RiUserStarLine,
  'ri-user-unfollow-fill': RiUserUnfollowFill,
  'ri-user-unfollow-line': RiUserUnfollowLine,
  'ri-user-voice-fill': RiUserVoiceFill,
  'ri-user-voice-line': RiUserVoiceLine,
  'ri-video-add-fill': RiVideoAddFill,
  'ri-video-add-line': RiVideoAddLine,
  'ri-video-chat-fill': RiVideoChatFill,
  'ri-video-chat-line': RiVideoChatLine,
  'ri-video-download-fill': RiVideoDownloadFill,
  'ri-video-download-line': RiVideoDownloadLine,
  'ri-video-fill': RiVideoFill,
  'ri-video-line': RiVideoLine,
  'ri-video-upload-fill': RiVideoUploadFill,
  'ri-video-upload-line': RiVideoUploadLine,
  'ri-vidicon-2-fill': RiVidicon2Fill,
  'ri-vidicon-2-line': RiVidicon2Line,
  'ri-vidicon-fill': RiVidiconFill,
  'ri-vidicon-line': RiVidiconLine,
  'ri-vimeo-fill': RiVimeoFill,
  'ri-vimeo-line': RiVimeoLine,
  'ri-vip-crown-2-fill': RiVipCrown2Fill,
  'ri-vip-crown-2-line': RiVipCrown2Line,
  'ri-vip-crown-fill': RiVipCrownFill,
  'ri-vip-crown-line': RiVipCrownLine,
  'ri-vip-diamond-fill': RiVipDiamondFill,
  'ri-vip-diamond-line': RiVipDiamondLine,
  'ri-vip-fill': RiVipFill,
  'ri-vip-line': RiVipLine,
  'ri-virus-fill': RiVirusFill,
  'ri-virus-line': RiVirusLine,
  'ri-visa-fill': RiVisaFill,
  'ri-visa-line': RiVisaLine,
  'ri-voice-recognition-fill': RiVoiceRecognitionFill,
  'ri-voice-recognition-line': RiVoiceRecognitionLine,
  'ri-voiceprint-fill': RiVoiceprintFill,
  'ri-voiceprint-line': RiVoiceprintLine,
  'ri-volume-down-fill': RiVolumeDownFill,
  'ri-volume-down-line': RiVolumeDownLine,
  'ri-volume-mute-fill': RiVolumeMuteFill,
  'ri-volume-mute-line': RiVolumeMuteLine,
  'ri-volume-off-vibrate-fill': RiVolumeOffVibrateFill,
  'ri-volume-off-vibrate-line': RiVolumeOffVibrateLine,
  'ri-volume-up-fill': RiVolumeUpFill,
  'ri-volume-up-line': RiVolumeUpLine,
  'ri-volume-vibrate-fill': RiVolumeVibrateFill,
  'ri-volume-vibrate-line': RiVolumeVibrateLine,
  'ri-vuejs-fill': RiVuejsFill,
  'ri-vuejs-line': RiVuejsLine,
  'ri-walk-fill': RiWalkFill,
  'ri-walk-line': RiWalkLine,
  'ri-wallet-2-fill': RiWallet2Fill,
  'ri-wallet-2-line': RiWallet2Line,
  'ri-wallet-3-fill': RiWallet3Fill,
  'ri-wallet-3-line': RiWallet3Line,
  'ri-wallet-fill': RiWalletFill,
  'ri-wallet-line': RiWalletLine,
  'ri-water-flash-fill': RiWaterFlashFill,
  'ri-water-flash-line': RiWaterFlashLine,
  'ri-webcam-fill': RiWebcamFill,
  'ri-webcam-line': RiWebcamLine,
  'ri-wechat-2-fill': RiWechat2Fill,
  'ri-wechat-2-line': RiWechat2Line,
  'ri-wechat-fill': RiWechatFill,
  'ri-wechat-line': RiWechatLine,
  'ri-wechat-pay-fill': RiWechatPayFill,
  'ri-wechat-pay-line': RiWechatPayLine,
  'ri-weibo-fill': RiWeiboFill,
  'ri-weibo-line': RiWeiboLine,
  'ri-whatsapp-fill': RiWhatsappFill,
  'ri-whatsapp-line': RiWhatsappLine,
  'ri-wheelchair-fill': RiWheelchairFill,
  'ri-wheelchair-line': RiWheelchairLine,
  'ri-wifi-fill': RiWifiFill,
  'ri-wifi-line': RiWifiLine,
  'ri-wifi-off-fill': RiWifiOffFill,
  'ri-wifi-off-line': RiWifiOffLine,
  'ri-window-2-fill': RiWindow2Fill,
  'ri-window-2-line': RiWindow2Line,
  'ri-window-fill': RiWindowFill,
  'ri-window-line': RiWindowLine,
  'ri-windows-fill': RiWindowsFill,
  'ri-windows-line': RiWindowsLine,
  'ri-windy-fill': RiWindyFill,
  'ri-windy-line': RiWindyLine,
  'ri-wireless-charging-fill': RiWirelessChargingFill,
  'ri-wireless-charging-line': RiWirelessChargingLine,
  'ri-women-fill': RiWomenFill,
  'ri-women-line': RiWomenLine,
  'ri-wubi-input': RiWubiInput,
  'ri-xbox-fill': RiXboxFill,
  'ri-xbox-line': RiXboxLine,
  'ri-xing-fill': RiXingFill,
  'ri-xing-line': RiXingLine,
  'ri-youtube-fill': RiYoutubeFill,
  'ri-youtube-line': RiYoutubeLine,
  'ri-zcool-fill': RiZcoolFill,
  'ri-zcool-line': RiZcoolLine,
  'ri-zhihu-fill': RiZhihuFill,
  'ri-zhihu-line': RiZhihuLine,
  'ri-zoom-in-fill': RiZoomInFill,
  'ri-zoom-in-line': RiZoomInLine,
  'ri-zoom-out-fill': RiZoomOutFill,
  'ri-zoom-out-line': RiZoomOutLine,
  'ri-zzz-fill': RiZzzFill,
  'ri-zzz-line': RiZzzLine,
};

export const RemixIconClasses = [
  'ri-24-hours-fill',
  'ri-24-hours-line',
  'ri-4k-fill',
  'ri-4k-line',
  'ri-a-b',
  'ri-account-box-fill',
  'ri-account-box-line',
  'ri-account-circle-fill',
  'ri-account-circle-line',
  'ri-account-pin-box-fill',
  'ri-account-pin-box-line',
  'ri-account-pin-circle-fill',
  'ri-account-pin-circle-line',
  'ri-add-box-fill',
  'ri-add-box-line',
  'ri-add-circle-fill',
  'ri-add-circle-line',
  'ri-add-fill',
  'ri-add-line',
  'ri-admin-fill',
  'ri-admin-line',
  'ri-advertisement-fill',
  'ri-advertisement-line',
  'ri-airplay-fill',
  'ri-airplay-line',
  'ri-alarm-fill',
  'ri-alarm-line',
  'ri-alarm-warning-fill',
  'ri-alarm-warning-line',
  'ri-album-fill',
  'ri-album-line',
  'ri-alert-fill',
  'ri-alert-line',
  'ri-aliens-fill',
  'ri-aliens-line',
  'ri-align-bottom',
  'ri-align-center',
  'ri-align-justify',
  'ri-align-left',
  'ri-align-right',
  'ri-align-top',
  'ri-align-vertically',
  'ri-alipay-fill',
  'ri-alipay-line',
  'ri-amazon-fill',
  'ri-amazon-line',
  'ri-anchor-fill',
  'ri-anchor-line',
  'ri-ancient-gate-fill',
  'ri-ancient-gate-line',
  'ri-ancient-pavilion-fill',
  'ri-ancient-pavilion-line',
  'ri-android-fill',
  'ri-android-line',
  'ri-angularjs-fill',
  'ri-angularjs-line',
  'ri-anticlockwise-2-fill',
  'ri-anticlockwise-2-line',
  'ri-anticlockwise-fill',
  'ri-anticlockwise-line',
  'ri-app-store-fill',
  'ri-app-store-line',
  'ri-apple-fill',
  'ri-apple-line',
  'ri-apps-2-fill',
  'ri-apps-2-line',
  'ri-apps-fill',
  'ri-apps-line',
  'ri-archive-drawer-fill',
  'ri-archive-drawer-line',
  'ri-archive-fill',
  'ri-archive-line',
  'ri-arrow-down-circle-fill',
  'ri-arrow-down-circle-line',
  'ri-arrow-down-fill',
  'ri-arrow-down-line',
  'ri-arrow-down-s-fill',
  'ri-arrow-down-s-line',
  'ri-arrow-drop-down-fill',
  'ri-arrow-drop-down-line',
  'ri-arrow-drop-left-fill',
  'ri-arrow-drop-left-line',
  'ri-arrow-drop-right-fill',
  'ri-arrow-drop-right-line',
  'ri-arrow-drop-up-fill',
  'ri-arrow-drop-up-line',
  'ri-arrow-go-back-fill',
  'ri-arrow-go-back-line',
  'ri-arrow-go-forward-fill',
  'ri-arrow-go-forward-line',
  'ri-arrow-left-circle-fill',
  'ri-arrow-left-circle-line',
  'ri-arrow-left-down-fill',
  'ri-arrow-left-down-line',
  'ri-arrow-left-fill',
  'ri-arrow-left-line',
  'ri-arrow-left-right-fill',
  'ri-arrow-left-right-line',
  'ri-arrow-left-s-fill',
  'ri-arrow-left-s-line',
  'ri-arrow-left-up-fill',
  'ri-arrow-left-up-line',
  'ri-arrow-right-circle-fill',
  'ri-arrow-right-circle-line',
  'ri-arrow-right-down-fill',
  'ri-arrow-right-down-line',
  'ri-arrow-right-fill',
  'ri-arrow-right-line',
  'ri-arrow-right-s-fill',
  'ri-arrow-right-s-line',
  'ri-arrow-right-up-fill',
  'ri-arrow-right-up-line',
  'ri-arrow-up-circle-fill',
  'ri-arrow-up-circle-line',
  'ri-arrow-up-down-fill',
  'ri-arrow-up-down-line',
  'ri-arrow-up-fill',
  'ri-arrow-up-line',
  'ri-arrow-up-s-fill',
  'ri-arrow-up-s-line',
  'ri-artboard-2-fill',
  'ri-artboard-2-line',
  'ri-artboard-fill',
  'ri-artboard-line',
  'ri-article-fill',
  'ri-article-line',
  'ri-aspect-ratio-fill',
  'ri-aspect-ratio-line',
  'ri-asterisk',
  'ri-at-fill',
  'ri-at-line',
  'ri-attachment-2',
  'ri-attachment-fill',
  'ri-attachment-line',
  'ri-auction-fill',
  'ri-auction-line',
  'ri-award-fill',
  'ri-award-line',
  'ri-baidu-fill',
  'ri-baidu-line',
  'ri-ball-pen-fill',
  'ri-ball-pen-line',
  'ri-bank-card-2-fill',
  'ri-bank-card-2-line',
  'ri-bank-card-fill',
  'ri-bank-card-line',
  'ri-bank-fill',
  'ri-bank-line',
  'ri-bar-chart-2-fill',
  'ri-bar-chart-2-line',
  'ri-bar-chart-box-fill',
  'ri-bar-chart-box-line',
  'ri-bar-chart-fill',
  'ri-bar-chart-grouped-fill',
  'ri-bar-chart-grouped-line',
  'ri-bar-chart-horizontal-fill',
  'ri-bar-chart-horizontal-line',
  'ri-bar-chart-line',
  'ri-barcode-box-fill',
  'ri-barcode-box-line',
  'ri-barcode-fill',
  'ri-barcode-line',
  'ri-barricade-fill',
  'ri-barricade-line',
  'ri-base-station-fill',
  'ri-base-station-line',
  'ri-basketball-fill',
  'ri-basketball-line',
  'ri-battery-2-charge-fill',
  'ri-battery-2-charge-line',
  'ri-battery-2-fill',
  'ri-battery-2-line',
  'ri-battery-charge-fill',
  'ri-battery-charge-line',
  'ri-battery-fill',
  'ri-battery-line',
  'ri-battery-low-fill',
  'ri-battery-low-line',
  'ri-battery-saver-fill',
  'ri-battery-saver-line',
  'ri-battery-share-fill',
  'ri-battery-share-line',
  'ri-bear-smile-fill',
  'ri-bear-smile-line',
  'ri-behance-fill',
  'ri-behance-line',
  'ri-bell-fill',
  'ri-bell-line',
  'ri-bike-fill',
  'ri-bike-line',
  'ri-bilibili-fill',
  'ri-bilibili-line',
  'ri-bill-fill',
  'ri-bill-line',
  'ri-billiards-fill',
  'ri-billiards-line',
  'ri-bit-coin-fill',
  'ri-bit-coin-line',
  'ri-blaze-fill',
  'ri-blaze-line',
  'ri-bluetooth-connect-fill',
  'ri-bluetooth-connect-line',
  'ri-bluetooth-fill',
  'ri-bluetooth-line',
  'ri-blur-off-fill',
  'ri-blur-off-line',
  'ri-body-scan-fill',
  'ri-body-scan-line',
  'ri-bold',
  'ri-book-2-fill',
  'ri-book-2-line',
  'ri-book-3-fill',
  'ri-book-3-line',
  'ri-book-fill',
  'ri-book-line',
  'ri-book-mark-fill',
  'ri-book-mark-line',
  'ri-book-open-fill',
  'ri-book-open-line',
  'ri-book-read-fill',
  'ri-book-read-line',
  'ri-booklet-fill',
  'ri-booklet-line',
  'ri-bookmark-2-fill',
  'ri-bookmark-2-line',
  'ri-bookmark-3-fill',
  'ri-bookmark-3-line',
  'ri-bookmark-fill',
  'ri-bookmark-line',
  'ri-boxing-fill',
  'ri-boxing-line',
  'ri-braces-fill',
  'ri-braces-line',
  'ri-brackets-fill',
  'ri-brackets-line',
  'ri-briefcase-2-fill',
  'ri-briefcase-2-line',
  'ri-briefcase-3-fill',
  'ri-briefcase-3-line',
  'ri-briefcase-4-fill',
  'ri-briefcase-4-line',
  'ri-briefcase-5-fill',
  'ri-briefcase-5-line',
  'ri-briefcase-fill',
  'ri-briefcase-line',
  'ri-bring-forward',
  'ri-bring-to-front',
  'ri-broadcast-fill',
  'ri-broadcast-line',
  'ri-brush-2-fill',
  'ri-brush-2-line',
  'ri-brush-3-fill',
  'ri-brush-3-line',
  'ri-brush-4-fill',
  'ri-brush-4-line',
  'ri-brush-fill',
  'ri-brush-line',
  'ri-bubble-chart-fill',
  'ri-bubble-chart-line',
  'ri-bug-2-fill',
  'ri-bug-2-line',
  'ri-bug-fill',
  'ri-bug-line',
  'ri-building-2-fill',
  'ri-building-2-line',
  'ri-building-3-fill',
  'ri-building-3-line',
  'ri-building-4-fill',
  'ri-building-4-line',
  'ri-building-fill',
  'ri-building-line',
  'ri-bus-2-fill',
  'ri-bus-2-line',
  'ri-bus-fill',
  'ri-bus-line',
  'ri-bus-wifi-fill',
  'ri-bus-wifi-line',
  'ri-cactus-fill',
  'ri-cactus-line',
  'ri-cake-2-fill',
  'ri-cake-2-line',
  'ri-cake-3-fill',
  'ri-cake-3-line',
  'ri-cake-fill',
  'ri-cake-line',
  'ri-calculator-fill',
  'ri-calculator-line',
  'ri-calendar-2-fill',
  'ri-calendar-2-line',
  'ri-calendar-check-fill',
  'ri-calendar-check-line',
  'ri-calendar-event-fill',
  'ri-calendar-event-line',
  'ri-calendar-fill',
  'ri-calendar-line',
  'ri-calendar-todo-fill',
  'ri-calendar-todo-line',
  'ri-camera-2-fill',
  'ri-camera-2-line',
  'ri-camera-3-fill',
  'ri-camera-3-line',
  'ri-camera-fill',
  'ri-camera-lens-fill',
  'ri-camera-lens-line',
  'ri-camera-line',
  'ri-camera-off-fill',
  'ri-camera-off-line',
  'ri-camera-switch-fill',
  'ri-camera-switch-line',
  'ri-capsule-fill',
  'ri-capsule-line',
  'ri-car-fill',
  'ri-car-line',
  'ri-car-washing-fill',
  'ri-car-washing-line',
  'ri-caravan-fill',
  'ri-caravan-line',
  'ri-cast-fill',
  'ri-cast-line',
  'ri-cellphone-fill',
  'ri-cellphone-line',
  'ri-celsius-fill',
  'ri-celsius-line',
  'ri-centos-fill',
  'ri-centos-line',
  'ri-character-recognition-fill',
  'ri-character-recognition-line',
  'ri-charging-pile-2-fill',
  'ri-charging-pile-2-line',
  'ri-charging-pile-fill',
  'ri-charging-pile-line',
  'ri-chat-1-fill',
  'ri-chat-1-line',
  'ri-chat-2-fill',
  'ri-chat-2-line',
  'ri-chat-3-fill',
  'ri-chat-3-line',
  'ri-chat-4-fill',
  'ri-chat-4-line',
  'ri-chat-check-fill',
  'ri-chat-check-line',
  'ri-chat-delete-fill',
  'ri-chat-delete-line',
  'ri-chat-download-fill',
  'ri-chat-download-line',
  'ri-chat-follow-up-fill',
  'ri-chat-follow-up-line',
  'ri-chat-forward-fill',
  'ri-chat-forward-line',
  'ri-chat-heart-fill',
  'ri-chat-heart-line',
  'ri-chat-history-fill',
  'ri-chat-history-line',
  'ri-chat-new-fill',
  'ri-chat-new-line',
  'ri-chat-off-fill',
  'ri-chat-off-line',
  'ri-chat-poll-fill',
  'ri-chat-poll-line',
  'ri-chat-private-fill',
  'ri-chat-private-line',
  'ri-chat-quote-fill',
  'ri-chat-quote-line',
  'ri-chat-settings-fill',
  'ri-chat-settings-line',
  'ri-chat-smile-2-fill',
  'ri-chat-smile-2-line',
  'ri-chat-smile-3-fill',
  'ri-chat-smile-3-line',
  'ri-chat-smile-fill',
  'ri-chat-smile-line',
  'ri-chat-upload-fill',
  'ri-chat-upload-line',
  'ri-chat-voice-fill',
  'ri-chat-voice-line',
  'ri-check-double-fill',
  'ri-check-double-line',
  'ri-check-fill',
  'ri-check-line',
  'ri-checkbox-blank-circle-fill',
  'ri-checkbox-blank-circle-line',
  'ri-checkbox-blank-fill',
  'ri-checkbox-blank-line',
  'ri-checkbox-circle-fill',
  'ri-checkbox-circle-line',
  'ri-checkbox-fill',
  'ri-checkbox-indeterminate-fill',
  'ri-checkbox-indeterminate-line',
  'ri-checkbox-line',
  'ri-checkbox-multiple-blank-fill',
  'ri-checkbox-multiple-blank-line',
  'ri-checkbox-multiple-fill',
  'ri-checkbox-multiple-line',
  'ri-china-railway-fill',
  'ri-china-railway-line',
  'ri-chrome-fill',
  'ri-chrome-line',
  'ri-clapperboard-fill',
  'ri-clapperboard-line',
  'ri-clipboard-fill',
  'ri-clipboard-line',
  'ri-clockwise-2-fill',
  'ri-clockwise-2-line',
  'ri-clockwise-fill',
  'ri-clockwise-line',
  'ri-close-circle-fill',
  'ri-close-circle-line',
  'ri-close-fill',
  'ri-close-line',
  'ri-closed-captioning-fill',
  'ri-closed-captioning-line',
  'ri-cloud-fill',
  'ri-cloud-line',
  'ri-cloud-off-fill',
  'ri-cloud-off-line',
  'ri-cloud-windy-fill',
  'ri-cloud-windy-line',
  'ri-cloudy-2-fill',
  'ri-cloudy-2-line',
  'ri-cloudy-fill',
  'ri-cloudy-line',
  'ri-code-box-fill',
  'ri-code-box-line',
  'ri-code-fill',
  'ri-code-line',
  'ri-code-s-fill',
  'ri-code-s-line',
  'ri-code-s-slash-fill',
  'ri-code-s-slash-line',
  'ri-code-view',
  'ri-codepen-fill',
  'ri-codepen-line',
  'ri-coin-fill',
  'ri-coin-line',
  'ri-coins-fill',
  'ri-coins-line',
  'ri-collage-fill',
  'ri-collage-line',
  'ri-command-fill',
  'ri-command-line',
  'ri-community-fill',
  'ri-community-line',
  'ri-compass-2-fill',
  'ri-compass-2-line',
  'ri-compass-3-fill',
  'ri-compass-3-line',
  'ri-compass-4-fill',
  'ri-compass-4-line',
  'ri-compass-discover-fill',
  'ri-compass-discover-line',
  'ri-compass-fill',
  'ri-compass-line',
  'ri-compasses-2-fill',
  'ri-compasses-2-line',
  'ri-compasses-fill',
  'ri-compasses-line',
  'ri-computer-fill',
  'ri-computer-line',
  'ri-contacts-book-2-fill',
  'ri-contacts-book-2-line',
  'ri-contacts-book-fill',
  'ri-contacts-book-line',
  'ri-contacts-book-upload-fill',
  'ri-contacts-book-upload-line',
  'ri-contacts-fill',
  'ri-contacts-line',
  'ri-contrast-2-fill',
  'ri-contrast-2-line',
  'ri-contrast-drop-2-fill',
  'ri-contrast-drop-2-line',
  'ri-contrast-drop-fill',
  'ri-contrast-drop-line',
  'ri-contrast-fill',
  'ri-contrast-line',
  'ri-copper-coin-fill',
  'ri-copper-coin-line',
  'ri-copper-diamond-fill',
  'ri-copper-diamond-line',
  'ri-copyleft-fill',
  'ri-copyleft-line',
  'ri-copyright-fill',
  'ri-copyright-line',
  'ri-coreos-fill',
  'ri-coreos-line',
  'ri-coupon-2-fill',
  'ri-coupon-2-line',
  'ri-coupon-3-fill',
  'ri-coupon-3-line',
  'ri-coupon-4-fill',
  'ri-coupon-4-line',
  'ri-coupon-5-fill',
  'ri-coupon-5-line',
  'ri-coupon-fill',
  'ri-coupon-line',
  'ri-cpu-fill',
  'ri-cpu-line',
  'ri-creative-commons-by-fill',
  'ri-creative-commons-by-line',
  'ri-creative-commons-fill',
  'ri-creative-commons-line',
  'ri-creative-commons-nc-fill',
  'ri-creative-commons-nc-line',
  'ri-creative-commons-nd-fill',
  'ri-creative-commons-nd-line',
  'ri-creative-commons-sa-fill',
  'ri-creative-commons-sa-line',
  'ri-creative-commons-zero-fill',
  'ri-creative-commons-zero-line',
  'ri-criminal-fill',
  'ri-criminal-line',
  'ri-crop-2-fill',
  'ri-crop-2-line',
  'ri-crop-fill',
  'ri-crop-line',
  'ri-css3-fill',
  'ri-css3-line',
  'ri-cup-fill',
  'ri-cup-line',
  'ri-currency-fill',
  'ri-currency-line',
  'ri-cursor-fill',
  'ri-cursor-line',
  'ri-customer-service-2-fill',
  'ri-customer-service-2-line',
  'ri-customer-service-fill',
  'ri-customer-service-line',
  'ri-dashboard-2-fill',
  'ri-dashboard-2-line',
  'ri-dashboard-3-fill',
  'ri-dashboard-3-line',
  'ri-dashboard-fill',
  'ri-dashboard-line',
  'ri-database-2-fill',
  'ri-database-2-line',
  'ri-database-fill',
  'ri-database-line',
  'ri-delete-back-2-fill',
  'ri-delete-back-2-line',
  'ri-delete-back-fill',
  'ri-delete-back-line',
  'ri-delete-bin-2-fill',
  'ri-delete-bin-2-line',
  'ri-delete-bin-3-fill',
  'ri-delete-bin-3-line',
  'ri-delete-bin-4-fill',
  'ri-delete-bin-4-line',
  'ri-delete-bin-5-fill',
  'ri-delete-bin-5-line',
  'ri-delete-bin-6-fill',
  'ri-delete-bin-6-line',
  'ri-delete-bin-7-fill',
  'ri-delete-bin-7-line',
  'ri-delete-bin-fill',
  'ri-delete-bin-line',
  'ri-delete-column',
  'ri-delete-row',
  'ri-device-fill',
  'ri-device-line',
  'ri-device-recover-fill',
  'ri-device-recover-line',
  'ri-dingding-fill',
  'ri-dingding-line',
  'ri-direction-fill',
  'ri-direction-line',
  'ri-disc-fill',
  'ri-disc-line',
  'ri-discord-fill',
  'ri-discord-line',
  'ri-discuss-fill',
  'ri-discuss-line',
  'ri-dislike-fill',
  'ri-dislike-line',
  'ri-disqus-fill',
  'ri-disqus-line',
  'ri-divide-fill',
  'ri-divide-line',
  'ri-donut-chart-fill',
  'ri-donut-chart-line',
  'ri-door-closed-fill',
  'ri-door-closed-line',
  'ri-door-fill',
  'ri-door-line',
  'ri-door-lock-box-fill',
  'ri-door-lock-box-line',
  'ri-door-lock-fill',
  'ri-door-lock-line',
  'ri-door-open-fill',
  'ri-door-open-line',
  'ri-dossier-fill',
  'ri-dossier-line',
  'ri-douban-fill',
  'ri-douban-line',
  'ri-double-quotes-l',
  'ri-double-quotes-r',
  'ri-download-2-fill',
  'ri-download-2-line',
  'ri-download-cloud-2-fill',
  'ri-download-cloud-2-line',
  'ri-download-cloud-fill',
  'ri-download-cloud-line',
  'ri-download-fill',
  'ri-download-line',
  'ri-draft-fill',
  'ri-draft-line',
  'ri-drag-drop-fill',
  'ri-drag-drop-line',
  'ri-drag-move-2-fill',
  'ri-drag-move-2-line',
  'ri-drag-move-fill',
  'ri-drag-move-line',
  'ri-dribbble-fill',
  'ri-dribbble-line',
  'ri-drive-fill',
  'ri-drive-line',
  'ri-drizzle-fill',
  'ri-drizzle-line',
  'ri-drop-fill',
  'ri-drop-line',
  'ri-dropbox-fill',
  'ri-dropbox-line',
  'ri-dual-sim-1-fill',
  'ri-dual-sim-1-line',
  'ri-dual-sim-2-fill',
  'ri-dual-sim-2-line',
  'ri-dv-fill',
  'ri-dv-line',
  'ri-dvd-fill',
  'ri-dvd-line',
  'ri-e-bike-2-fill',
  'ri-e-bike-2-line',
  'ri-e-bike-fill',
  'ri-e-bike-line',
  'ri-earth-fill',
  'ri-earth-line',
  'ri-earthquake-fill',
  'ri-earthquake-line',
  'ri-edge-fill',
  'ri-edge-line',
  'ri-edit-2-fill',
  'ri-edit-2-line',
  'ri-edit-box-fill',
  'ri-edit-box-line',
  'ri-edit-circle-fill',
  'ri-edit-circle-line',
  'ri-edit-fill',
  'ri-edit-line',
  'ri-eject-fill',
  'ri-eject-line',
  'ri-emotion-2-fill',
  'ri-emotion-2-line',
  'ri-emotion-fill',
  'ri-emotion-happy-fill',
  'ri-emotion-happy-line',
  'ri-emotion-laugh-fill',
  'ri-emotion-laugh-line',
  'ri-emotion-line',
  'ri-emotion-normal-fill',
  'ri-emotion-normal-line',
  'ri-emotion-sad-fill',
  'ri-emotion-sad-line',
  'ri-emotion-unhappy-fill',
  'ri-emotion-unhappy-line',
  'ri-empathize-fill',
  'ri-empathize-line',
  'ri-emphasis-cn',
  'ri-emphasis',
  'ri-english-input',
  'ri-equalizer-fill',
  'ri-equalizer-line',
  'ri-eraser-fill',
  'ri-eraser-line',
  'ri-error-warning-fill',
  'ri-error-warning-line',
  'ri-evernote-fill',
  'ri-evernote-line',
  'ri-exchange-box-fill',
  'ri-exchange-box-line',
  'ri-exchange-cny-fill',
  'ri-exchange-cny-line',
  'ri-exchange-dollar-fill',
  'ri-exchange-dollar-line',
  'ri-exchange-fill',
  'ri-exchange-funds-fill',
  'ri-exchange-funds-line',
  'ri-exchange-line',
  'ri-external-link-fill',
  'ri-external-link-line',
  'ri-eye-2-fill',
  'ri-eye-2-line',
  'ri-eye-close-fill',
  'ri-eye-close-line',
  'ri-eye-fill',
  'ri-eye-line',
  'ri-eye-off-fill',
  'ri-eye-off-line',
  'ri-facebook-box-fill',
  'ri-facebook-box-line',
  'ri-facebook-circle-fill',
  'ri-facebook-circle-line',
  'ri-facebook-fill',
  'ri-facebook-line',
  'ri-fahrenheit-fill',
  'ri-fahrenheit-line',
  'ri-feedback-fill',
  'ri-feedback-line',
  'ri-file-2-fill',
  'ri-file-2-line',
  'ri-file-3-fill',
  'ri-file-3-line',
  'ri-file-4-fill',
  'ri-file-4-line',
  'ri-file-add-fill',
  'ri-file-add-line',
  'ri-file-chart-2-fill',
  'ri-file-chart-2-line',
  'ri-file-chart-fill',
  'ri-file-chart-line',
  'ri-file-cloud-fill',
  'ri-file-cloud-line',
  'ri-file-code-fill',
  'ri-file-code-line',
  'ri-file-copy-2-fill',
  'ri-file-copy-2-line',
  'ri-file-copy-fill',
  'ri-file-copy-line',
  'ri-file-damage-fill',
  'ri-file-damage-line',
  'ri-file-download-fill',
  'ri-file-download-line',
  'ri-file-edit-fill',
  'ri-file-edit-line',
  'ri-file-excel-2-fill',
  'ri-file-excel-2-line',
  'ri-file-excel-fill',
  'ri-file-excel-line',
  'ri-file-fill',
  'ri-file-forbid-fill',
  'ri-file-forbid-line',
  'ri-file-gif-fill',
  'ri-file-gif-line',
  'ri-file-history-fill',
  'ri-file-history-line',
  'ri-file-hwp-fill',
  'ri-file-hwp-line',
  'ri-file-info-fill',
  'ri-file-info-line',
  'ri-file-line',
  'ri-file-list-2-fill',
  'ri-file-list-2-line',
  'ri-file-list-3-fill',
  'ri-file-list-3-line',
  'ri-file-list-fill',
  'ri-file-list-line',
  'ri-file-lock-fill',
  'ri-file-lock-line',
  'ri-file-mark-fill',
  'ri-file-mark-line',
  'ri-file-music-fill',
  'ri-file-music-line',
  'ri-file-paper-2-fill',
  'ri-file-paper-2-line',
  'ri-file-paper-fill',
  'ri-file-paper-line',
  'ri-file-pdf-fill',
  'ri-file-pdf-line',
  'ri-file-ppt-2-fill',
  'ri-file-ppt-2-line',
  'ri-file-ppt-fill',
  'ri-file-ppt-line',
  'ri-file-reduce-fill',
  'ri-file-reduce-line',
  'ri-file-search-fill',
  'ri-file-search-line',
  'ri-file-settings-fill',
  'ri-file-settings-line',
  'ri-file-shield-2-fill',
  'ri-file-shield-2-line',
  'ri-file-shield-fill',
  'ri-file-shield-line',
  'ri-file-shred-fill',
  'ri-file-shred-line',
  'ri-file-text-fill',
  'ri-file-text-line',
  'ri-file-transfer-fill',
  'ri-file-transfer-line',
  'ri-file-unknow-fill',
  'ri-file-unknow-line',
  'ri-file-upload-fill',
  'ri-file-upload-line',
  'ri-file-user-fill',
  'ri-file-user-line',
  'ri-file-warning-fill',
  'ri-file-warning-line',
  'ri-file-word-2-fill',
  'ri-file-word-2-line',
  'ri-file-word-fill',
  'ri-file-word-line',
  'ri-file-zip-fill',
  'ri-file-zip-line',
  'ri-film-fill',
  'ri-film-line',
  'ri-filter-2-fill',
  'ri-filter-2-line',
  'ri-filter-3-fill',
  'ri-filter-3-line',
  'ri-filter-fill',
  'ri-filter-line',
  'ri-filter-off-fill',
  'ri-filter-off-line',
  'ri-find-replace-fill',
  'ri-find-replace-line',
  'ri-finder-fill',
  'ri-finder-line',
  'ri-fingerprint-2-fill',
  'ri-fingerprint-2-line',
  'ri-fingerprint-fill',
  'ri-fingerprint-line',
  'ri-fire-fill',
  'ri-fire-line',
  'ri-firefox-fill',
  'ri-firefox-line',
  'ri-first-aid-kit-fill',
  'ri-first-aid-kit-line',
  'ri-flag-2-fill',
  'ri-flag-2-line',
  'ri-flag-fill',
  'ri-flag-line',
  'ri-flashlight-fill',
  'ri-flashlight-line',
  'ri-flask-fill',
  'ri-flask-line',
  'ri-flight-land-fill',
  'ri-flight-land-line',
  'ri-flight-takeoff-fill',
  'ri-flight-takeoff-line',
  'ri-flood-fill',
  'ri-flood-line',
  'ri-flow-chart',
  'ri-flutter-fill',
  'ri-flutter-line',
  'ri-focus-2-fill',
  'ri-focus-2-line',
  'ri-focus-3-fill',
  'ri-focus-3-line',
  'ri-focus-fill',
  'ri-focus-line',
  'ri-foggy-fill',
  'ri-foggy-line',
  'ri-folder-2-fill',
  'ri-folder-2-line',
  'ri-folder-3-fill',
  'ri-folder-3-line',
  'ri-folder-4-fill',
  'ri-folder-4-line',
  'ri-folder-5-fill',
  'ri-folder-5-line',
  'ri-folder-add-fill',
  'ri-folder-add-line',
  'ri-folder-chart-2-fill',
  'ri-folder-chart-2-line',
  'ri-folder-chart-fill',
  'ri-folder-chart-line',
  'ri-folder-download-fill',
  'ri-folder-download-line',
  'ri-folder-fill',
  'ri-folder-forbid-fill',
  'ri-folder-forbid-line',
  'ri-folder-history-fill',
  'ri-folder-history-line',
  'ri-folder-info-fill',
  'ri-folder-info-line',
  'ri-folder-keyhole-fill',
  'ri-folder-keyhole-line',
  'ri-folder-line',
  'ri-folder-lock-fill',
  'ri-folder-lock-line',
  'ri-folder-music-fill',
  'ri-folder-music-line',
  'ri-folder-open-fill',
  'ri-folder-open-line',
  'ri-folder-received-fill',
  'ri-folder-received-line',
  'ri-folder-reduce-fill',
  'ri-folder-reduce-line',
  'ri-folder-settings-fill',
  'ri-folder-settings-line',
  'ri-folder-shared-fill',
  'ri-folder-shared-line',
  'ri-folder-shield-2-fill',
  'ri-folder-shield-2-line',
  'ri-folder-shield-fill',
  'ri-folder-shield-line',
  'ri-folder-transfer-fill',
  'ri-folder-transfer-line',
  'ri-folder-unknow-fill',
  'ri-folder-unknow-line',
  'ri-folder-upload-fill',
  'ri-folder-upload-line',
  'ri-folder-user-fill',
  'ri-folder-user-line',
  'ri-folder-warning-fill',
  'ri-folder-warning-line',
  'ri-folder-zip-fill',
  'ri-folder-zip-line',
  'ri-folders-fill',
  'ri-folders-line',
  'ri-font-color',
  'ri-font-size-2',
  'ri-font-size',
  'ri-football-fill',
  'ri-football-line',
  'ri-footprint-fill',
  'ri-footprint-line',
  'ri-forbid-2-fill',
  'ri-forbid-2-line',
  'ri-forbid-fill',
  'ri-forbid-line',
  'ri-format-clear',
  'ri-fridge-fill',
  'ri-fridge-line',
  'ri-fullscreen-exit-fill',
  'ri-fullscreen-exit-line',
  'ri-fullscreen-fill',
  'ri-fullscreen-line',
  'ri-function-fill',
  'ri-function-line',
  'ri-functions',
  'ri-funds-box-fill',
  'ri-funds-box-line',
  'ri-funds-fill',
  'ri-funds-line',
  'ri-gallery-fill',
  'ri-gallery-line',
  'ri-gallery-upload-fill',
  'ri-gallery-upload-line',
  'ri-game-fill',
  'ri-game-line',
  'ri-gamepad-fill',
  'ri-gamepad-line',
  'ri-gas-station-fill',
  'ri-gas-station-line',
  'ri-gatsby-fill',
  'ri-gatsby-line',
  'ri-genderless-fill',
  'ri-genderless-line',
  'ri-ghost-2-fill',
  'ri-ghost-2-line',
  'ri-ghost-fill',
  'ri-ghost-line',
  'ri-ghost-smile-fill',
  'ri-ghost-smile-line',
  'ri-gift-2-fill',
  'ri-gift-2-line',
  'ri-gift-fill',
  'ri-gift-line',
  'ri-git-branch-fill',
  'ri-git-branch-line',
  'ri-git-commit-fill',
  'ri-git-commit-line',
  'ri-git-merge-fill',
  'ri-git-merge-line',
  'ri-git-pull-request-fill',
  'ri-git-pull-request-line',
  'ri-git-repository-commits-fill',
  'ri-git-repository-commits-line',
  'ri-git-repository-fill',
  'ri-git-repository-line',
  'ri-git-repository-private-fill',
  'ri-git-repository-private-line',
  'ri-github-fill',
  'ri-github-line',
  'ri-gitlab-fill',
  'ri-gitlab-line',
  'ri-global-fill',
  'ri-global-line',
  'ri-globe-fill',
  'ri-globe-line',
  'ri-goblet-fill',
  'ri-goblet-line',
  'ri-google-fill',
  'ri-google-line',
  'ri-google-play-fill',
  'ri-google-play-line',
  'ri-government-fill',
  'ri-government-line',
  'ri-gps-fill',
  'ri-gps-line',
  'ri-gradienter-fill',
  'ri-gradienter-line',
  'ri-grid-fill',
  'ri-grid-line',
  'ri-group-2-fill',
  'ri-group-2-line',
  'ri-group-fill',
  'ri-group-line',
  'ri-guide-fill',
  'ri-guide-line',
  'ri-h-1',
  'ri-h-2',
  'ri-h-3',
  'ri-h-4',
  'ri-h-5',
  'ri-h-6',
  'ri-hail-fill',
  'ri-hail-line',
  'ri-hammer-fill',
  'ri-hammer-line',
  'ri-hand-coin-fill',
  'ri-hand-coin-line',
  'ri-hand-heart-fill',
  'ri-hand-heart-line',
  'ri-hand-sanitizer-fill',
  'ri-hand-sanitizer-line',
  'ri-handbag-fill',
  'ri-handbag-line',
  'ri-hard-drive-2-fill',
  'ri-hard-drive-2-line',
  'ri-hard-drive-fill',
  'ri-hard-drive-line',
  'ri-hashtag',
  'ri-haze-2-fill',
  'ri-haze-2-line',
  'ri-haze-fill',
  'ri-haze-line',
  'ri-hd-fill',
  'ri-hd-line',
  'ri-heading',
  'ri-headphone-fill',
  'ri-headphone-line',
  'ri-health-book-fill',
  'ri-health-book-line',
  'ri-heart-2-fill',
  'ri-heart-2-line',
  'ri-heart-3-fill',
  'ri-heart-3-line',
  'ri-heart-add-fill',
  'ri-heart-add-line',
  'ri-heart-fill',
  'ri-heart-line',
  'ri-heart-pulse-fill',
  'ri-heart-pulse-line',
  'ri-hearts-fill',
  'ri-hearts-line',
  'ri-heavy-showers-fill',
  'ri-heavy-showers-line',
  'ri-history-fill',
  'ri-history-line',
  'ri-home-2-fill',
  'ri-home-2-line',
  'ri-home-3-fill',
  'ri-home-3-line',
  'ri-home-4-fill',
  'ri-home-4-line',
  'ri-home-5-fill',
  'ri-home-5-line',
  'ri-home-6-fill',
  'ri-home-6-line',
  'ri-home-7-fill',
  'ri-home-7-line',
  'ri-home-8-fill',
  'ri-home-8-line',
  'ri-home-fill',
  'ri-home-gear-fill',
  'ri-home-gear-line',
  'ri-home-heart-fill',
  'ri-home-heart-line',
  'ri-home-line',
  'ri-home-smile-2-fill',
  'ri-home-smile-2-line',
  'ri-home-smile-fill',
  'ri-home-smile-line',
  'ri-home-wifi-fill',
  'ri-home-wifi-line',
  'ri-honor-of-kings-fill',
  'ri-honor-of-kings-line',
  'ri-honour-fill',
  'ri-honour-line',
  'ri-hospital-fill',
  'ri-hospital-line',
  'ri-hotel-bed-fill',
  'ri-hotel-bed-line',
  'ri-hotel-fill',
  'ri-hotel-line',
  'ri-hotspot-fill',
  'ri-hotspot-line',
  'ri-hq-fill',
  'ri-hq-line',
  'ri-html5-fill',
  'ri-html5-line',
  'ri-ie-fill',
  'ri-ie-line',
  'ri-image-2-fill',
  'ri-image-2-line',
  'ri-image-add-fill',
  'ri-image-add-line',
  'ri-image-edit-fill',
  'ri-image-edit-line',
  'ri-image-fill',
  'ri-image-line',
  'ri-inbox-archive-fill',
  'ri-inbox-archive-line',
  'ri-inbox-fill',
  'ri-inbox-line',
  'ri-inbox-unarchive-fill',
  'ri-inbox-unarchive-line',
  'ri-increase-decrease-fill',
  'ri-increase-decrease-line',
  'ri-indent-decrease',
  'ri-indent-increase',
  'ri-indeterminate-circle-fill',
  'ri-indeterminate-circle-line',
  'ri-information-fill',
  'ri-information-line',
  'ri-infrared-thermometer-fill',
  'ri-infrared-thermometer-line',
  'ri-ink-bottle-fill',
  'ri-ink-bottle-line',
  'ri-input-cursor-move',
  'ri-input-method-fill',
  'ri-input-method-line',
  'ri-insert-column-left',
  'ri-insert-column-right',
  'ri-insert-row-bottom',
  'ri-insert-row-top',
  'ri-instagram-fill',
  'ri-instagram-line',
  'ri-install-fill',
  'ri-install-line',
  'ri-invision-fill',
  'ri-invision-line',
  'ri-italic',
  'ri-kakao-talk-fill',
  'ri-kakao-talk-line',
  'ri-key-2-fill',
  'ri-key-2-line',
  'ri-key-fill',
  'ri-key-line',
  'ri-keyboard-box-fill',
  'ri-keyboard-box-line',
  'ri-keyboard-fill',
  'ri-keyboard-line',
  'ri-keynote-fill',
  'ri-keynote-line',
  'ri-knife-blood-fill',
  'ri-knife-blood-line',
  'ri-knife-fill',
  'ri-knife-line',
  'ri-landscape-fill',
  'ri-landscape-line',
  'ri-layout-2-fill',
  'ri-layout-2-line',
  'ri-layout-3-fill',
  'ri-layout-3-line',
  'ri-layout-4-fill',
  'ri-layout-4-line',
  'ri-layout-5-fill',
  'ri-layout-5-line',
  'ri-layout-6-fill',
  'ri-layout-6-line',
  'ri-layout-bottom-2-fill',
  'ri-layout-bottom-2-line',
  'ri-layout-bottom-fill',
  'ri-layout-bottom-line',
  'ri-layout-column-fill',
  'ri-layout-column-line',
  'ri-layout-fill',
  'ri-layout-grid-fill',
  'ri-layout-grid-line',
  'ri-layout-left-2-fill',
  'ri-layout-left-2-line',
  'ri-layout-left-fill',
  'ri-layout-left-line',
  'ri-layout-line',
  'ri-layout-masonry-fill',
  'ri-layout-masonry-line',
  'ri-layout-right-2-fill',
  'ri-layout-right-2-line',
  'ri-layout-right-fill',
  'ri-layout-right-line',
  'ri-layout-row-fill',
  'ri-layout-row-line',
  'ri-layout-top-2-fill',
  'ri-layout-top-2-line',
  'ri-layout-top-fill',
  'ri-layout-top-line',
  'ri-leaf-fill',
  'ri-leaf-line',
  'ri-lifebuoy-fill',
  'ri-lifebuoy-line',
  'ri-lightbulb-fill',
  'ri-lightbulb-flash-fill',
  'ri-lightbulb-flash-line',
  'ri-lightbulb-line',
  'ri-line-chart-fill',
  'ri-line-chart-line',
  'ri-line-fill',
  'ri-line-height',
  'ri-line-line',
  'ri-link-m',
  'ri-link-unlink-m',
  'ri-link-unlink',
  'ri-link',
  'ri-linkedin-box-fill',
  'ri-linkedin-box-line',
  'ri-linkedin-fill',
  'ri-linkedin-line',
  'ri-links-fill',
  'ri-links-line',
  'ri-list-check-2',
  'ri-list-check',
  'ri-list-ordered',
  'ri-list-settings-fill',
  'ri-list-settings-line',
  'ri-list-unordered',
  'ri-live-fill',
  'ri-live-line',
  'ri-loader-2-fill',
  'ri-loader-2-line',
  'ri-loader-3-fill',
  'ri-loader-3-line',
  'ri-loader-4-fill',
  'ri-loader-4-line',
  'ri-loader-5-fill',
  'ri-loader-5-line',
  'ri-loader-fill',
  'ri-loader-line',
  'ri-lock-2-fill',
  'ri-lock-2-line',
  'ri-lock-fill',
  'ri-lock-line',
  'ri-lock-password-fill',
  'ri-lock-password-line',
  'ri-lock-unlock-fill',
  'ri-lock-unlock-line',
  'ri-login-box-fill',
  'ri-login-box-line',
  'ri-login-circle-fill',
  'ri-login-circle-line',
  'ri-logout-box-fill',
  'ri-logout-box-line',
  'ri-logout-box-r-fill',
  'ri-logout-box-r-line',
  'ri-logout-circle-fill',
  'ri-logout-circle-line',
  'ri-logout-circle-r-fill',
  'ri-logout-circle-r-line',
  'ri-luggage-cart-fill',
  'ri-luggage-cart-line',
  'ri-luggage-deposit-fill',
  'ri-luggage-deposit-line',
  'ri-lungs-fill',
  'ri-lungs-line',
  'ri-mac-fill',
  'ri-mac-line',
  'ri-macbook-fill',
  'ri-macbook-line',
  'ri-magic-fill',
  'ri-magic-line',
  'ri-mail-add-fill',
  'ri-mail-add-line',
  'ri-mail-check-fill',
  'ri-mail-check-line',
  'ri-mail-close-fill',
  'ri-mail-close-line',
  'ri-mail-download-fill',
  'ri-mail-download-line',
  'ri-mail-fill',
  'ri-mail-forbid-fill',
  'ri-mail-forbid-line',
  'ri-mail-line',
  'ri-mail-lock-fill',
  'ri-mail-lock-line',
  'ri-mail-open-fill',
  'ri-mail-open-line',
  'ri-mail-send-fill',
  'ri-mail-send-line',
  'ri-mail-settings-fill',
  'ri-mail-settings-line',
  'ri-mail-star-fill',
  'ri-mail-star-line',
  'ri-mail-unread-fill',
  'ri-mail-unread-line',
  'ri-mail-volume-fill',
  'ri-mail-volume-line',
  'ri-map-2-fill',
  'ri-map-2-line',
  'ri-map-fill',
  'ri-map-line',
  'ri-map-pin-2-fill',
  'ri-map-pin-2-line',
  'ri-map-pin-3-fill',
  'ri-map-pin-3-line',
  'ri-map-pin-4-fill',
  'ri-map-pin-4-line',
  'ri-map-pin-5-fill',
  'ri-map-pin-5-line',
  'ri-map-pin-add-fill',
  'ri-map-pin-add-line',
  'ri-map-pin-fill',
  'ri-map-pin-line',
  'ri-map-pin-range-fill',
  'ri-map-pin-range-line',
  'ri-map-pin-time-fill',
  'ri-map-pin-time-line',
  'ri-map-pin-user-fill',
  'ri-map-pin-user-line',
  'ri-mark-pen-fill',
  'ri-mark-pen-line',
  'ri-markdown-fill',
  'ri-markdown-line',
  'ri-markup-fill',
  'ri-markup-line',
  'ri-mastercard-fill',
  'ri-mastercard-line',
  'ri-mastodon-fill',
  'ri-mastodon-line',
  'ri-medal-2-fill',
  'ri-medal-2-line',
  'ri-medal-fill',
  'ri-medal-line',
  'ri-medicine-bottle-fill',
  'ri-medicine-bottle-line',
  'ri-medium-fill',
  'ri-medium-line',
  'ri-men-fill',
  'ri-men-line',
  'ri-mental-health-fill',
  'ri-mental-health-line',
  'ri-menu-2-fill',
  'ri-menu-2-line',
  'ri-menu-3-fill',
  'ri-menu-3-line',
  'ri-menu-4-fill',
  'ri-menu-4-line',
  'ri-menu-5-fill',
  'ri-menu-5-line',
  'ri-menu-add-fill',
  'ri-menu-add-line',
  'ri-menu-fill',
  'ri-menu-fold-fill',
  'ri-menu-fold-line',
  'ri-menu-line',
  'ri-menu-unfold-fill',
  'ri-menu-unfold-line',
  'ri-merge-cells-horizontal',
  'ri-merge-cells-vertical',
  'ri-message-2-fill',
  'ri-message-2-line',
  'ri-message-3-fill',
  'ri-message-3-line',
  'ri-message-fill',
  'ri-message-line',
  'ri-messenger-fill',
  'ri-messenger-line',
  'ri-meteor-fill',
  'ri-meteor-line',
  'ri-mic-2-fill',
  'ri-mic-2-line',
  'ri-mic-fill',
  'ri-mic-line',
  'ri-mic-off-fill',
  'ri-mic-off-line',
  'ri-mickey-fill',
  'ri-mickey-line',
  'ri-microscope-fill',
  'ri-microscope-line',
  'ri-microsoft-fill',
  'ri-microsoft-line',
  'ri-mind-map',
  'ri-mini-program-fill',
  'ri-mini-program-line',
  'ri-mist-fill',
  'ri-mist-line',
  'ri-money-cny-box-fill',
  'ri-money-cny-box-line',
  'ri-money-cny-circle-fill',
  'ri-money-cny-circle-line',
  'ri-money-dollar-box-fill',
  'ri-money-dollar-box-line',
  'ri-money-dollar-circle-fill',
  'ri-money-dollar-circle-line',
  'ri-money-euro-box-fill',
  'ri-money-euro-box-line',
  'ri-money-euro-circle-fill',
  'ri-money-euro-circle-line',
  'ri-money-pound-box-fill',
  'ri-money-pound-box-line',
  'ri-money-pound-circle-fill',
  'ri-money-pound-circle-line',
  'ri-moon-clear-fill',
  'ri-moon-clear-line',
  'ri-moon-cloudy-fill',
  'ri-moon-cloudy-line',
  'ri-moon-fill',
  'ri-moon-foggy-fill',
  'ri-moon-foggy-line',
  'ri-moon-line',
  'ri-more-2-fill',
  'ri-more-2-line',
  'ri-more-fill',
  'ri-more-line',
  'ri-motorbike-fill',
  'ri-motorbike-line',
  'ri-mouse-fill',
  'ri-mouse-line',
  'ri-movie-2-fill',
  'ri-movie-2-line',
  'ri-movie-fill',
  'ri-movie-line',
  'ri-music-2-fill',
  'ri-music-2-line',
  'ri-music-fill',
  'ri-music-line',
  'ri-mv-fill',
  'ri-mv-line',
  'ri-navigation-fill',
  'ri-navigation-line',
  'ri-netease-cloud-music-fill',
  'ri-netease-cloud-music-line',
  'ri-netflix-fill',
  'ri-netflix-line',
  'ri-newspaper-fill',
  'ri-newspaper-line',
  'ri-node-tree',
  'ri-notification-2-fill',
  'ri-notification-2-line',
  'ri-notification-3-fill',
  'ri-notification-3-line',
  'ri-notification-4-fill',
  'ri-notification-4-line',
  'ri-notification-badge-fill',
  'ri-notification-badge-line',
  'ri-notification-fill',
  'ri-notification-line',
  'ri-notification-off-fill',
  'ri-notification-off-line',
  'ri-npmjs-fill',
  'ri-npmjs-line',
  'ri-number-0',
  'ri-number-1',
  'ri-number-2',
  'ri-number-3',
  'ri-number-4',
  'ri-number-5',
  'ri-number-6',
  'ri-number-7',
  'ri-number-8',
  'ri-number-9',
  'ri-numbers-fill',
  'ri-numbers-line',
  'ri-nurse-fill',
  'ri-nurse-line',
  'ri-oil-fill',
  'ri-oil-line',
  'ri-omega',
  'ri-open-arm-fill',
  'ri-open-arm-line',
  'ri-open-source-fill',
  'ri-open-source-line',
  'ri-opera-fill',
  'ri-opera-line',
  'ri-order-play-fill',
  'ri-order-play-line',
  'ri-organization-chart',
  'ri-outlet-2-fill',
  'ri-outlet-2-line',
  'ri-outlet-fill',
  'ri-outlet-line',
  'ri-page-separator',
  'ri-pages-fill',
  'ri-pages-line',
  'ri-paint-brush-fill',
  'ri-paint-brush-line',
  'ri-paint-fill',
  'ri-paint-line',
  'ri-palette-fill',
  'ri-palette-line',
  'ri-pantone-fill',
  'ri-pantone-line',
  'ri-paragraph',
  'ri-parent-fill',
  'ri-parent-line',
  'ri-parentheses-fill',
  'ri-parentheses-line',
  'ri-parking-box-fill',
  'ri-parking-box-line',
  'ri-parking-fill',
  'ri-parking-line',
  'ri-passport-fill',
  'ri-passport-line',
  'ri-patreon-fill',
  'ri-patreon-line',
  'ri-pause-circle-fill',
  'ri-pause-circle-line',
  'ri-pause-fill',
  'ri-pause-line',
  'ri-pause-mini-fill',
  'ri-pause-mini-line',
  'ri-paypal-fill',
  'ri-paypal-line',
  'ri-pen-nib-fill',
  'ri-pen-nib-line',
  'ri-pencil-fill',
  'ri-pencil-line',
  'ri-pencil-ruler-2-fill',
  'ri-pencil-ruler-2-line',
  'ri-pencil-ruler-fill',
  'ri-pencil-ruler-line',
  'ri-percent-fill',
  'ri-percent-line',
  'ri-phone-camera-fill',
  'ri-phone-camera-line',
  'ri-phone-fill',
  'ri-phone-find-fill',
  'ri-phone-find-line',
  'ri-phone-line',
  'ri-phone-lock-fill',
  'ri-phone-lock-line',
  'ri-picture-in-picture-2-fill',
  'ri-picture-in-picture-2-line',
  'ri-picture-in-picture-exit-fill',
  'ri-picture-in-picture-exit-line',
  'ri-picture-in-picture-fill',
  'ri-picture-in-picture-line',
  'ri-pie-chart-2-fill',
  'ri-pie-chart-2-line',
  'ri-pie-chart-box-fill',
  'ri-pie-chart-box-line',
  'ri-pie-chart-fill',
  'ri-pie-chart-line',
  'ri-pin-distance-fill',
  'ri-pin-distance-line',
  'ri-ping-pong-fill',
  'ri-ping-pong-line',
  'ri-pinterest-fill',
  'ri-pinterest-line',
  'ri-pinyin-input',
  'ri-pixelfed-fill',
  'ri-pixelfed-line',
  'ri-plane-fill',
  'ri-plane-line',
  'ri-plant-fill',
  'ri-plant-line',
  'ri-play-circle-fill',
  'ri-play-circle-line',
  'ri-play-fill',
  'ri-play-line',
  'ri-play-list-2-fill',
  'ri-play-list-2-line',
  'ri-play-list-add-fill',
  'ri-play-list-add-line',
  'ri-play-list-fill',
  'ri-play-list-line',
  'ri-play-mini-fill',
  'ri-play-mini-line',
  'ri-playstation-fill',
  'ri-playstation-line',
  'ri-plug-2-fill',
  'ri-plug-2-line',
  'ri-plug-fill',
  'ri-plug-line',
  'ri-polaroid-2-fill',
  'ri-polaroid-2-line',
  'ri-polaroid-fill',
  'ri-polaroid-line',
  'ri-police-car-fill',
  'ri-police-car-line',
  'ri-price-tag-2-fill',
  'ri-price-tag-2-line',
  'ri-price-tag-3-fill',
  'ri-price-tag-3-line',
  'ri-price-tag-fill',
  'ri-price-tag-line',
  'ri-printer-cloud-fill',
  'ri-printer-cloud-line',
  'ri-printer-fill',
  'ri-printer-line',
  'ri-product-hunt-fill',
  'ri-product-hunt-line',
  'ri-profile-fill',
  'ri-profile-line',
  'ri-projector-2-fill',
  'ri-projector-2-line',
  'ri-projector-fill',
  'ri-projector-line',
  'ri-psychotherapy-fill',
  'ri-psychotherapy-line',
  'ri-pulse-fill',
  'ri-pulse-line',
  'ri-pushpin-2-fill',
  'ri-pushpin-2-line',
  'ri-pushpin-fill',
  'ri-pushpin-line',
  'ri-qq-fill',
  'ri-qq-line',
  'ri-qr-code-fill',
  'ri-qr-code-line',
  'ri-qr-scan-2-fill',
  'ri-qr-scan-2-line',
  'ri-qr-scan-fill',
  'ri-qr-scan-line',
  'ri-question-answer-fill',
  'ri-question-answer-line',
  'ri-question-fill',
  'ri-question-line',
  'ri-question-mark',
  'ri-questionnaire-fill',
  'ri-questionnaire-line',
  'ri-quill-pen-fill',
  'ri-quill-pen-line',
  'ri-radar-fill',
  'ri-radar-line',
  'ri-radio-2-fill',
  'ri-radio-2-line',
  'ri-radio-button-fill',
  'ri-radio-button-line',
  'ri-radio-fill',
  'ri-radio-line',
  'ri-rainbow-fill',
  'ri-rainbow-line',
  'ri-rainy-fill',
  'ri-rainy-line',
  'ri-reactjs-fill',
  'ri-reactjs-line',
  'ri-record-circle-fill',
  'ri-record-circle-line',
  'ri-record-mail-fill',
  'ri-record-mail-line',
  'ri-recycle-fill',
  'ri-recycle-line',
  'ri-red-packet-fill',
  'ri-red-packet-line',
  'ri-reddit-fill',
  'ri-reddit-line',
  'ri-refresh-fill',
  'ri-refresh-line',
  'ri-refund-2-fill',
  'ri-refund-2-line',
  'ri-refund-fill',
  'ri-refund-line',
  'ri-registered-fill',
  'ri-registered-line',
  'ri-remixicon-fill',
  'ri-remixicon-line',
  'ri-remote-control-2-fill',
  'ri-remote-control-2-line',
  'ri-remote-control-fill',
  'ri-remote-control-line',
  'ri-repeat-2-fill',
  'ri-repeat-2-line',
  'ri-repeat-fill',
  'ri-repeat-line',
  'ri-repeat-one-fill',
  'ri-repeat-one-line',
  'ri-reply-all-fill',
  'ri-reply-all-line',
  'ri-reply-fill',
  'ri-reply-line',
  'ri-reserved-fill',
  'ri-reserved-line',
  'ri-rest-time-fill',
  'ri-rest-time-line',
  'ri-restart-fill',
  'ri-restart-line',
  'ri-restaurant-2-fill',
  'ri-restaurant-2-line',
  'ri-restaurant-fill',
  'ri-restaurant-line',
  'ri-rewind-fill',
  'ri-rewind-line',
  'ri-rewind-mini-fill',
  'ri-rewind-mini-line',
  'ri-rhythm-fill',
  'ri-rhythm-line',
  'ri-riding-fill',
  'ri-riding-line',
  'ri-road-map-fill',
  'ri-road-map-line',
  'ri-roadster-fill',
  'ri-roadster-line',
  'ri-robot-fill',
  'ri-robot-line',
  'ri-rocket-2-fill',
  'ri-rocket-2-line',
  'ri-rocket-fill',
  'ri-rocket-line',
  'ri-rotate-lock-fill',
  'ri-rotate-lock-line',
  'ri-rounded-corner',
  'ri-route-fill',
  'ri-route-line',
  'ri-router-fill',
  'ri-router-line',
  'ri-rss-fill',
  'ri-rss-line',
  'ri-ruler-2-fill',
  'ri-ruler-2-line',
  'ri-ruler-fill',
  'ri-ruler-line',
  'ri-run-fill',
  'ri-run-line',
  'ri-safari-fill',
  'ri-safari-line',
  'ri-safe-2-fill',
  'ri-safe-2-line',
  'ri-safe-fill',
  'ri-safe-line',
  'ri-sailboat-fill',
  'ri-sailboat-line',
  'ri-save-2-fill',
  'ri-save-2-line',
  'ri-save-3-fill',
  'ri-save-3-line',
  'ri-save-fill',
  'ri-save-line',
  'ri-scales-2-fill',
  'ri-scales-2-line',
  'ri-scales-3-fill',
  'ri-scales-3-line',
  'ri-scales-fill',
  'ri-scales-line',
  'ri-scan-2-fill',
  'ri-scan-2-line',
  'ri-scan-fill',
  'ri-scan-line',
  'ri-scissors-2-fill',
  'ri-scissors-2-line',
  'ri-scissors-cut-fill',
  'ri-scissors-cut-line',
  'ri-scissors-fill',
  'ri-scissors-line',
  'ri-screenshot-2-fill',
  'ri-screenshot-2-line',
  'ri-screenshot-fill',
  'ri-screenshot-line',
  'ri-sd-card-fill',
  'ri-sd-card-line',
  'ri-sd-card-mini-fill',
  'ri-sd-card-mini-line',
  'ri-search-2-fill',
  'ri-search-2-line',
  'ri-search-eye-fill',
  'ri-search-eye-line',
  'ri-search-fill',
  'ri-search-line',
  'ri-secure-payment-fill',
  'ri-secure-payment-line',
  'ri-seedling-fill',
  'ri-seedling-line',
  'ri-send-backward',
  'ri-send-plane-2-fill',
  'ri-send-plane-2-line',
  'ri-send-plane-fill',
  'ri-send-plane-line',
  'ri-send-to-back',
  'ri-sensor-fill',
  'ri-sensor-line',
  'ri-separator',
  'ri-server-fill',
  'ri-server-line',
  'ri-service-fill',
  'ri-service-line',
  'ri-settings-2-fill',
  'ri-settings-2-line',
  'ri-settings-3-fill',
  'ri-settings-3-line',
  'ri-settings-4-fill',
  'ri-settings-4-line',
  'ri-settings-5-fill',
  'ri-settings-5-line',
  'ri-settings-6-fill',
  'ri-settings-6-line',
  'ri-settings-fill',
  'ri-settings-line',
  'ri-shape-2-fill',
  'ri-shape-2-line',
  'ri-shape-fill',
  'ri-shape-line',
  'ri-share-box-fill',
  'ri-share-box-line',
  'ri-share-circle-fill',
  'ri-share-circle-line',
  'ri-share-fill',
  'ri-share-forward-2-fill',
  'ri-share-forward-2-line',
  'ri-share-forward-box-fill',
  'ri-share-forward-box-line',
  'ri-share-forward-fill',
  'ri-share-forward-line',
  'ri-share-line',
  'ri-shield-check-fill',
  'ri-shield-check-line',
  'ri-shield-cross-fill',
  'ri-shield-cross-line',
  'ri-shield-fill',
  'ri-shield-flash-fill',
  'ri-shield-flash-line',
  'ri-shield-keyhole-fill',
  'ri-shield-keyhole-line',
  'ri-shield-line',
  'ri-shield-star-fill',
  'ri-shield-star-line',
  'ri-shield-user-fill',
  'ri-shield-user-line',
  'ri-ship-2-fill',
  'ri-ship-2-line',
  'ri-ship-fill',
  'ri-ship-line',
  'ri-shirt-fill',
  'ri-shirt-line',
  'ri-shopping-bag-2-fill',
  'ri-shopping-bag-2-line',
  'ri-shopping-bag-3-fill',
  'ri-shopping-bag-3-line',
  'ri-shopping-bag-fill',
  'ri-shopping-bag-line',
  'ri-shopping-basket-2-fill',
  'ri-shopping-basket-2-line',
  'ri-shopping-basket-fill',
  'ri-shopping-basket-line',
  'ri-shopping-cart-2-fill',
  'ri-shopping-cart-2-line',
  'ri-shopping-cart-fill',
  'ri-shopping-cart-line',
  'ri-showers-fill',
  'ri-showers-line',
  'ri-shuffle-fill',
  'ri-shuffle-line',
  'ri-shut-down-fill',
  'ri-shut-down-line',
  'ri-side-bar-fill',
  'ri-side-bar-line',
  'ri-signal-tower-fill',
  'ri-signal-tower-line',
  'ri-signal-wifi-1-fill',
  'ri-signal-wifi-1-line',
  'ri-signal-wifi-2-fill',
  'ri-signal-wifi-2-line',
  'ri-signal-wifi-3-fill',
  'ri-signal-wifi-3-line',
  'ri-signal-wifi-error-fill',
  'ri-signal-wifi-error-line',
  'ri-signal-wifi-fill',
  'ri-signal-wifi-line',
  'ri-signal-wifi-off-fill',
  'ri-signal-wifi-off-line',
  'ri-sim-card-2-fill',
  'ri-sim-card-2-line',
  'ri-sim-card-fill',
  'ri-sim-card-line',
  'ri-single-quotes-l',
  'ri-single-quotes-r',
  'ri-sip-fill',
  'ri-sip-line',
  'ri-skip-back-fill',
  'ri-skip-back-line',
  'ri-skip-back-mini-fill',
  'ri-skip-back-mini-line',
  'ri-skip-forward-fill',
  'ri-skip-forward-line',
  'ri-skip-forward-mini-fill',
  'ri-skip-forward-mini-line',
  'ri-skull-2-fill',
  'ri-skull-2-line',
  'ri-skull-fill',
  'ri-skull-line',
  'ri-skype-fill',
  'ri-skype-line',
  'ri-slack-fill',
  'ri-slack-line',
  'ri-slice-fill',
  'ri-slice-line',
  'ri-slideshow-2-fill',
  'ri-slideshow-2-line',
  'ri-slideshow-3-fill',
  'ri-slideshow-3-line',
  'ri-slideshow-4-fill',
  'ri-slideshow-4-line',
  'ri-slideshow-fill',
  'ri-slideshow-line',
  'ri-smartphone-fill',
  'ri-smartphone-line',
  'ri-snapchat-fill',
  'ri-snapchat-line',
  'ri-snowy-fill',
  'ri-snowy-line',
  'ri-sort-asc',
  'ri-sort-desc',
  'ri-sound-module-fill',
  'ri-sound-module-line',
  'ri-soundcloud-fill',
  'ri-soundcloud-line',
  'ri-space-ship-fill',
  'ri-space-ship-line',
  'ri-space',
  'ri-spam-2-fill',
  'ri-spam-2-line',
  'ri-spam-3-fill',
  'ri-spam-3-line',
  'ri-spam-fill',
  'ri-spam-line',
  'ri-speaker-2-fill',
  'ri-speaker-2-line',
  'ri-speaker-3-fill',
  'ri-speaker-3-line',
  'ri-speaker-fill',
  'ri-speaker-line',
  'ri-spectrum-fill',
  'ri-spectrum-line',
  'ri-speed-fill',
  'ri-speed-line',
  'ri-speed-mini-fill',
  'ri-speed-mini-line',
  'ri-split-cells-horizontal',
  'ri-split-cells-vertical',
  'ri-spotify-fill',
  'ri-spotify-line',
  'ri-spy-fill',
  'ri-spy-line',
  'ri-stack-fill',
  'ri-stack-line',
  'ri-stack-overflow-fill',
  'ri-stack-overflow-line',
  'ri-stackshare-fill',
  'ri-stackshare-line',
  'ri-star-fill',
  'ri-star-half-fill',
  'ri-star-half-line',
  'ri-star-half-s-fill',
  'ri-star-half-s-line',
  'ri-star-line',
  'ri-star-s-fill',
  'ri-star-s-line',
  'ri-star-smile-fill',
  'ri-star-smile-line',
  'ri-steam-fill',
  'ri-steam-line',
  'ri-steering-2-fill',
  'ri-steering-2-line',
  'ri-steering-fill',
  'ri-steering-line',
  'ri-stethoscope-fill',
  'ri-stethoscope-line',
  'ri-sticky-note-2-fill',
  'ri-sticky-note-2-line',
  'ri-sticky-note-fill',
  'ri-sticky-note-line',
  'ri-stock-fill',
  'ri-stock-line',
  'ri-stop-circle-fill',
  'ri-stop-circle-line',
  'ri-stop-fill',
  'ri-stop-line',
  'ri-stop-mini-fill',
  'ri-stop-mini-line',
  'ri-store-2-fill',
  'ri-store-2-line',
  'ri-store-3-fill',
  'ri-store-3-line',
  'ri-store-fill',
  'ri-store-line',
  'ri-strikethrough-2',
  'ri-strikethrough',
  'ri-subscript-2',
  'ri-subscript',
  'ri-subtract-fill',
  'ri-subtract-line',
  'ri-subway-fill',
  'ri-subway-line',
  'ri-subway-wifi-fill',
  'ri-subway-wifi-line',
  'ri-suitcase-2-fill',
  'ri-suitcase-2-line',
  'ri-suitcase-3-fill',
  'ri-suitcase-3-line',
  'ri-suitcase-fill',
  'ri-suitcase-line',
  'ri-sun-cloudy-fill',
  'ri-sun-cloudy-line',
  'ri-sun-fill',
  'ri-sun-foggy-fill',
  'ri-sun-foggy-line',
  'ri-sun-line',
  'ri-superscript-2',
  'ri-superscript',
  'ri-surgical-mask-fill',
  'ri-surgical-mask-line',
  'ri-surround-sound-fill',
  'ri-surround-sound-line',
  'ri-survey-fill',
  'ri-survey-line',
  'ri-swap-box-fill',
  'ri-swap-box-line',
  'ri-swap-fill',
  'ri-swap-line',
  'ri-switch-fill',
  'ri-switch-line',
  'ri-sword-fill',
  'ri-sword-line',
  'ri-syringe-fill',
  'ri-syringe-line',
  'ri-t-box-fill',
  'ri-t-box-line',
  'ri-t-shirt-2-fill',
  'ri-t-shirt-2-line',
  'ri-t-shirt-air-fill',
  'ri-t-shirt-air-line',
  'ri-t-shirt-fill',
  'ri-t-shirt-line',
  'ri-table-2',
  'ri-table-alt-fill',
  'ri-table-alt-line',
  'ri-table-fill',
  'ri-table-line',
  'ri-tablet-fill',
  'ri-tablet-line',
  'ri-takeaway-fill',
  'ri-takeaway-line',
  'ri-taobao-fill',
  'ri-taobao-line',
  'ri-tape-fill',
  'ri-tape-line',
  'ri-task-fill',
  'ri-task-line',
  'ri-taxi-fill',
  'ri-taxi-line',
  'ri-taxi-wifi-fill',
  'ri-taxi-wifi-line',
  'ri-team-fill',
  'ri-team-line',
  'ri-telegram-fill',
  'ri-telegram-line',
  'ri-temp-cold-fill',
  'ri-temp-cold-line',
  'ri-temp-hot-fill',
  'ri-temp-hot-line',
  'ri-terminal-box-fill',
  'ri-terminal-box-line',
  'ri-terminal-fill',
  'ri-terminal-line',
  'ri-terminal-window-fill',
  'ri-terminal-window-line',
  'ri-test-tube-fill',
  'ri-test-tube-line',
  'ri-text-direction-l',
  'ri-text-direction-r',
  'ri-text-spacing',
  'ri-text-wrap',
  'ri-text',
  'ri-thermometer-fill',
  'ri-thermometer-line',
  'ri-thumb-down-fill',
  'ri-thumb-down-line',
  'ri-thumb-up-fill',
  'ri-thumb-up-line',
  'ri-thunderstorms-fill',
  'ri-thunderstorms-line',
  'ri-ticket-2-fill',
  'ri-ticket-2-line',
  'ri-ticket-fill',
  'ri-ticket-line',
  'ri-time-fill',
  'ri-time-line',
  'ri-timer-2-fill',
  'ri-timer-2-line',
  'ri-timer-fill',
  'ri-timer-flash-fill',
  'ri-timer-flash-line',
  'ri-timer-line',
  'ri-todo-fill',
  'ri-todo-line',
  'ri-toggle-fill',
  'ri-toggle-line',
  'ri-tools-fill',
  'ri-tools-line',
  'ri-tornado-fill',
  'ri-tornado-line',
  'ri-trademark-fill',
  'ri-trademark-line',
  'ri-traffic-light-fill',
  'ri-traffic-light-line',
  'ri-train-fill',
  'ri-train-line',
  'ri-train-wifi-fill',
  'ri-train-wifi-line',
  'ri-translate-2',
  'ri-translate',
  'ri-travesti-fill',
  'ri-travesti-line',
  'ri-treasure-map-fill',
  'ri-treasure-map-line',
  'ri-trello-fill',
  'ri-trello-line',
  'ri-trophy-fill',
  'ri-trophy-line',
  'ri-truck-fill',
  'ri-truck-line',
  'ri-tumblr-fill',
  'ri-tumblr-line',
  'ri-tv-2-fill',
  'ri-tv-2-line',
  'ri-tv-fill',
  'ri-tv-line',
  'ri-twitch-fill',
  'ri-twitch-line',
  'ri-twitter-fill',
  'ri-twitter-line',
  'ri-typhoon-fill',
  'ri-typhoon-line',
  'ri-u-disk-fill',
  'ri-u-disk-line',
  'ri-ubuntu-fill',
  'ri-ubuntu-line',
  'ri-umbrella-fill',
  'ri-umbrella-line',
  'ri-underline',
  'ri-uninstall-fill',
  'ri-uninstall-line',
  'ri-unsplash-fill',
  'ri-unsplash-line',
  'ri-upload-2-fill',
  'ri-upload-2-line',
  'ri-upload-cloud-2-fill',
  'ri-upload-cloud-2-line',
  'ri-upload-cloud-fill',
  'ri-upload-cloud-line',
  'ri-upload-fill',
  'ri-upload-line',
  'ri-usb-fill',
  'ri-usb-line',
  'ri-user-2-fill',
  'ri-user-2-line',
  'ri-user-3-fill',
  'ri-user-3-line',
  'ri-user-4-fill',
  'ri-user-4-line',
  'ri-user-5-fill',
  'ri-user-5-line',
  'ri-user-6-fill',
  'ri-user-6-line',
  'ri-user-add-fill',
  'ri-user-add-line',
  'ri-user-fill',
  'ri-user-follow-fill',
  'ri-user-follow-line',
  'ri-user-heart-fill',
  'ri-user-heart-line',
  'ri-user-line',
  'ri-user-location-fill',
  'ri-user-location-line',
  'ri-user-received-2-fill',
  'ri-user-received-2-line',
  'ri-user-received-fill',
  'ri-user-received-line',
  'ri-user-search-fill',
  'ri-user-search-line',
  'ri-user-settings-fill',
  'ri-user-settings-line',
  'ri-user-shared-2-fill',
  'ri-user-shared-2-line',
  'ri-user-shared-fill',
  'ri-user-shared-line',
  'ri-user-smile-fill',
  'ri-user-smile-line',
  'ri-user-star-fill',
  'ri-user-star-line',
  'ri-user-unfollow-fill',
  'ri-user-unfollow-line',
  'ri-user-voice-fill',
  'ri-user-voice-line',
  'ri-video-add-fill',
  'ri-video-add-line',
  'ri-video-chat-fill',
  'ri-video-chat-line',
  'ri-video-download-fill',
  'ri-video-download-line',
  'ri-video-fill',
  'ri-video-line',
  'ri-video-upload-fill',
  'ri-video-upload-line',
  'ri-vidicon-2-fill',
  'ri-vidicon-2-line',
  'ri-vidicon-fill',
  'ri-vidicon-line',
  'ri-vimeo-fill',
  'ri-vimeo-line',
  'ri-vip-crown-2-fill',
  'ri-vip-crown-2-line',
  'ri-vip-crown-fill',
  'ri-vip-crown-line',
  'ri-vip-diamond-fill',
  'ri-vip-diamond-line',
  'ri-vip-fill',
  'ri-vip-line',
  'ri-virus-fill',
  'ri-virus-line',
  'ri-visa-fill',
  'ri-visa-line',
  'ri-voice-recognition-fill',
  'ri-voice-recognition-line',
  'ri-voiceprint-fill',
  'ri-voiceprint-line',
  'ri-volume-down-fill',
  'ri-volume-down-line',
  'ri-volume-mute-fill',
  'ri-volume-mute-line',
  'ri-volume-off-vibrate-fill',
  'ri-volume-off-vibrate-line',
  'ri-volume-up-fill',
  'ri-volume-up-line',
  'ri-volume-vibrate-fill',
  'ri-volume-vibrate-line',
  'ri-vuejs-fill',
  'ri-vuejs-line',
  'ri-walk-fill',
  'ri-walk-line',
  'ri-wallet-2-fill',
  'ri-wallet-2-line',
  'ri-wallet-3-fill',
  'ri-wallet-3-line',
  'ri-wallet-fill',
  'ri-wallet-line',
  'ri-water-flash-fill',
  'ri-water-flash-line',
  'ri-webcam-fill',
  'ri-webcam-line',
  'ri-wechat-2-fill',
  'ri-wechat-2-line',
  'ri-wechat-fill',
  'ri-wechat-line',
  'ri-wechat-pay-fill',
  'ri-wechat-pay-line',
  'ri-weibo-fill',
  'ri-weibo-line',
  'ri-whatsapp-fill',
  'ri-whatsapp-line',
  'ri-wheelchair-fill',
  'ri-wheelchair-line',
  'ri-wifi-fill',
  'ri-wifi-line',
  'ri-wifi-off-fill',
  'ri-wifi-off-line',
  'ri-window-2-fill',
  'ri-window-2-line',
  'ri-window-fill',
  'ri-window-line',
  'ri-windows-fill',
  'ri-windows-line',
  'ri-windy-fill',
  'ri-windy-line',
  'ri-wireless-charging-fill',
  'ri-wireless-charging-line',
  'ri-women-fill',
  'ri-women-line',
  'ri-wubi-input',
  'ri-xbox-fill',
  'ri-xbox-line',
  'ri-xing-fill',
  'ri-xing-line',
  'ri-youtube-fill',
  'ri-youtube-line',
  'ri-zcool-fill',
  'ri-zcool-line',
  'ri-zhihu-fill',
  'ri-zhihu-line',
  'ri-zoom-in-fill',
  'ri-zoom-in-line',
  'ri-zoom-out-fill',
  'ri-zoom-out-line',
  'ri-zzz-fill',
  'ri-zzz-line',
] as const;
